import { finalize } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { User } from '@sportinsight/src/app/sub-projects/shared/definitions';
import {
  PaymentService,
  SignUpProduct,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent implements OnInit, OnChanges {
  @Input() public siteId: number;
  @Input() public prospectiveMemberId: number;
  @Input() public selectedMembership: number;
  @Input() public displayErrors: boolean;

  public formStage: number = 0;
  public loading = false;
  public form: FormGroup;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private initForm(): void {
    this.form = this.fb.group({
      sortCode: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountName: ['', [Validators.required]],
    });
  }

  public checkFormValid(): boolean {
    return true;
  }
}
