import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, passwordRegExp } from '@shared/definitions';
import { AlertService } from '@shared/services/alert.service';
import {
  ForgottenPasswordParams,
  ForgottenPasswordService,
  ResetPasswordTokenCheckParams,
} from '@shared/services/forgotten-password.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public displayErrors = false;
  public form: FormGroup;
  public alert: Alert = { type: null, text: '' };
  public useCase: string;
  public currentPasswordWeak = false;
  public loading = true;
  public tokenExpired = false;
  private token: string;
  public emailSent: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly forgottenPasswordService: ForgottenPasswordService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    this.loading = true;
    this.checkIfTokenExpired();

    // Check if the password is: (being set) OR (reset due to a request OR a current weak password).
    this.useCase = this.activatedRoute.snapshot.url.find(
      (URLSegment) => URLSegment.path === 'reset-password'
    )
      ? 'reset'
      : 'new'; // Set or reset

    this.currentPasswordWeak = this.activatedRoute.snapshot.url.find(
      (URLSegment) => URLSegment.path === 'too-weak'
    )
      ? true
      : false; // Too weak

    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(passwordRegExp)]],
      passwordConfirmation: ['', [Validators.required, this.confirmPasswordValidator.bind(this)]],
    });
  }

  public requestResetPasswordEmail(): void {
    this.router.navigateByUrl('/reset-password-request');
  }

  private checkIfTokenExpired() {
    const params: ResetPasswordTokenCheckParams = {
      token: this.token,
    };

    this.forgottenPasswordService
      .checkIfTokenExpired(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.tokenExpired = false;
        },
        error: (error: HttpErrorResponse) => {
          this.tokenExpired = true;
        },
      });
  }

  private confirmPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.form) return null;
    return control.value === this.form.value.password ? null : { consistent: true };
  }

  public onSubmit(): void {
    this.displayErrors = true;

    if (this.form.valid) {
      this.resetPassword();
    }
  }

  private resetPassword(): void {
    this.loading = true;

    const params: ForgottenPasswordParams = {
      password: this.form.value.password,
      token: this.token,
    };

    if (this.useCase === 'new') params.newLogin = true;

    this.forgottenPasswordService
      .forgottenResetPassword(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate(['login'], { relativeTo: this.activatedRoute.parent });
          this.alertService.emitSuccessAlert('Password successfully reset. Please login');
          localStorage.clear();
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.emitErrorAlert(error.error?.message || 'Reset failed');
        },
      });
  }

  public passwordStrength(use: string): string {
    switch (use) {
      case 'class':
        if (this.form.get('password').value.length < 8) return 'too-short';
        if (this.form.get('password').invalid) return 'too-weak';
        return 'strong';
      case 'text':
        if (this.form.get('password').value.length < 8) return 'Too short';
        if (this.form.get('password').invalid) return 'Too weak';
        return 'Strong';
    }
  }

  get passwordLength() {
    return this.form.get('password').value.length;
  }
  get passwordHasUppercase() {
    let password = this.form.get('password').value;
    return password !== password.toLowerCase();
  }
  get passwordHasLowercase() {
    let password = this.form.get('password').value;
    return password !== password.toUpperCase();
  }
  get passwordHasNumber() {
    return /\d/.test(this.form.get('password').value);
  }
}
