import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { DashboardStateService } from '@club-admin-services/dashboard-state.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() public readonly closeModalEmitter = new EventEmitter();
  @Input() public readonly loading: boolean = false;

  public constructor(private readonly dashboardState: DashboardStateService) { }

  public ngOnInit(): void {
    this.dashboardState.freezeModal(true);
  }

  public ngOnDestroy(): void {
    this.dashboardState.freezeModal(false);
  }

  public closeModal(): void {
    if (!this.loading) {
      this.closeModalEmitter.emit();
    }
  }
}
