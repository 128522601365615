import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-in-app',
  templateUrl: './open-in-app.component.html',
  styleUrls: ['./open-in-app.component.scss'],
})
export class OpenInAppComponent implements OnInit {
  constructor(private readonly router: Router) {}

  public userHasApp: boolean = false;
  public displayPrompt: boolean = false;
  public appLink: string;

  ngOnInit(): void {
    this.doesUserHasApp();

    this.setAppLink();

    this.displayPrompt = localStorage.getItem('displayPrompt') !== 'false';
  }

  public doesUserHasApp(): void {
    this.userHasApp = localStorage.getItem('userHasApp') === 'HAS_APP';
  }

  public closePrompt(): void {
    localStorage.setItem('displayPrompt', 'false');
    this.displayPrompt = false;
  }

  public downloadApp(): void {}

  public openApp(): void {}

  public setAppLink(): void {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      this.appLink = 'https://apps.apple.com/gb/app/sportinsight-club/id1520173397';
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
      this.appLink = 'https://apps.apple.com/gb/app/sportinsight-club/id1520173397';
    } else {
      this.appLink = 'https://apps.apple.com/gb/app/sportinsight-club/id1520173397';
    }
  }
}
