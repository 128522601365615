import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Site } from '@shared/definitions';
import { AlertService } from '@shared/services/alert.service';
import { UserService } from '@shared/services/user.service';
import { AuthService } from '@sportinsight-services/auth.service';

@Component({
  selector: 'app-sportinsight-homepage',
  templateUrl: './sportinsight-homepage.component.html',
  styleUrls: ['./sportinsight-homepage.component.scss'],
})
export class SportinsightHomepageComponent implements OnInit {
  @Input() public readonly sites: Site[];

  @Output() private readonly selectedSite = new EventEmitter<Site>();

  public twoFAEnabled: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.twoFAEnabled = localStorage.getItem('twoFAEnabled') === 'true';
  }

  public selectSite(site: Site): void {
    this.selectedSite.emit(site);
  }

  public manageTwoFA(): void {
    if (this.twoFAEnabled) {
      this.authService.disableTwoFA().subscribe({
        next: () => {
          localStorage.setItem('twoFAEnabled', 'false');
          this.twoFAEnabled = false;
          this.alertService.emitSuccessAlert('Two-factor authentication disabled');
        },
        error: (error) => this.alertService.emitErrorAlert(error.error?.message),
      });
    } else {
      this.router.navigateByUrl('setup-two-factor-authentication');
    }
  }

  public logout(): void {
    this.authService.logout();
  }
}
