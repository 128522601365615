import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly alertEmitter = new BehaviorSubject<Alert>(new Alert(2, ''));

  public alert = this.alertEmitter.asObservable();
  public timer = null;

  /**
   * Creates a new success alert and begins the emit process
   *
   * @param string message - content of alert
   *
   * @return void
   */
  public emitSuccessAlert(message: string, timer: number = null): void {
    const alert = new Alert(1, message);
    this.emitAlert(alert, timer);
  }

  /**
   * Creates a new error alert and begins the emit process
   *
   * @param string message - content of alert
   *
   * @return void
   */
  public emitErrorAlert(message: string, timer: number = null): void {
    const alert = new Alert(2, message);
    this.emitAlert(alert, timer);
  }

  /**
   * Creates a new error alert and begins the emit process
   *
   * @param string message - content of alert
   *
   * @return void
   */
  public emitErrorNavAlert(message: string, url: string, timer: number = null): void {
    const alert = new Alert(2, message, url);
    this.emitAlert(alert, timer);
  }

  /**
   * Emits a new alert using a behaviour subject
   *
   * @param Alert alert
   *
   * @return void
   */
  private emitAlert(alert: Alert, bannerShowTime: number = null): void {
    alert.display = true;
    this.alertEmitter.next(alert);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      alert.display = false;
      this.alertEmitter.next(alert);
    }, bannerShowTime ? bannerShowTime : 5000);
  }

  public clearAlert(): void {
    const alert = new Alert(2, '');
    clearTimeout(this.timer);
    this.alertEmitter.next(alert);
  }
}

/**
 * Stores information about the alert
 */
export class Alert {
  public message: string;
  public type: number;
  public url?: string;
  public display = false;

  constructor(type: number, message: string, url?: string) {
    this.message = message;
    this.type = type;
    this.url = url ?? null;
  }
}
