import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ActivityIconComponent } from '@shared/components/activity-icon/activity-icon.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { AvatarImageComponent } from '@shared/components/avatar-image/avatar-image.component';
import { ChartComponent } from '@shared/components/chart/chart.component';
import { DatepickerComponent } from '@shared/components/datepicker/datepicker.component';
import { EntityIconComponent } from '@shared/components/entity-icon/entity-icon.component';
import { FacilityIconComponent } from '@shared/components/facility-icon/facility-icon.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { InteractionIconComponent } from '@shared/components/interaction-icon/interaction-icon.component';
import { MultiselectDropdownComponent } from '@shared/components/multiselect-dropdown/multiselect-dropdown.component';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { SubTooltipComponent } from '@shared/components/tooltip/sub-tooltip/sub-tooltip.component';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { UrlPipe } from '@shared/pipes/url.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClickOutsideModule } from 'ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { BookingsTooltipComponent } from './components/bookings-tooltip/bookings-tooltip.component';
import { SelectorComponent } from './components/form-fields/selector/selector.component';
import { NewLoginSetupComponent } from './components/login/new-login-setup/new-login-setup.component';
import { TextInputComponent } from './components/form-fields/text-input/text-input.component';
import { PasswordValidatorComponent } from './components/login/password-validator/password-validator.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { BoxLeaguesHandshakeComponent } from './components/box-leagues-handshake/box-leagues-handshake.component';
import { MemberMessagesComponent } from './components/member-messages/member-messages.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextTooltipComponent } from './components/text-tooltip/text-tooltip.component';
import { ActivityToggleComponent } from './components/activity-toggle/activity-toggle.component';
import { AbilitySliderComponent } from './components/ability-slider/ability-slider.component';
import { AbilityLevelSelector } from './components/ability-level-selector/ability-level-selector.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NameAvatarModalComponent } from './components/name-avatar-modal/name-avatar-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotesModalComponent } from './components/modal/notes-modal/notes-modal.component';
import { ConfirmationModalComponent } from './components/modal/confirmation-modal/confirmation-modal.component';
import { AddLinkedMemberModalComponent } from './components/modal/add-linked-member-modal/add-linked-member-modal.component';
import { PasswordResetModal } from './components/modal/passwordResetModal/password-reset-modal.component';
import { ActivityParticipationComponent } from './components/activity-participation/activity-participation.component';
import { BookingRulesModalComponent } from './components/booking-rules-modal/booking-rules-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PaymentComponent } from './components/payment/payment.component';
import { ChoosePaymentMethodComponent } from '../../components/signup/payments/choose-payment-method/choose-payment-method.component';
import { GoCardlessRedirectComponent } from '../../components/signup/payments/go-cardless-redirect/go-cardless-redirect.component';
import { SignUpProgressTrackerComponent } from '../../components/signup/progress-tracker/signup-progress-tracker.component';
import { SignUpMembershipOptionsComponent } from '../../components/signup/membership-options/signup-membership-options.component';
import { SignUpAboutYouComponent } from '../../components/signup/aboutyou-form/signup-aboutyou.component';
import { SignUpSuccessComponent } from '../../components/signup/signup-success/signup-success.component';
import { ConfirmDirectDebitComponent } from '../../components/signup/payments/confirm-direct-debit/confirm-direct-debit.component';
import { PaymentOptionCardComponent } from '../../components/signup/payments/payment-option-card/payment-option-card.component';
import { PaymentsComponent } from '../../components/signup/payments/payments.component';
import { CustomGoCardlessFormComponent } from '../../components/signup/payments/custom-go-cardless-form/custom-go-cardless-form.component';
import { BillingDetailsComponent } from '../../components/signup/payments/custom-go-cardless-form/personal-details/billing-details.component';
import { BankDetailsComponent } from '../../components/signup/payments/custom-go-cardless-form/bank-details/bank-details.component';
import { ConfirmDetailsComponent } from '../../components/signup/payments/custom-go-cardless-form/confirm-details/confirm-details.component';
import { UsefulLinksComponent } from './components/useful-links/useful-links.component';
import { DisplayLinkUsersComponent } from './components/display-linked-users/display-linked-users.component';
import { EditLinkedUsersComponent } from './components/edit-linked-users/edit-linked-users.component';
import { CodeInputModule } from 'angular-code-input';
import { PinChangeModal } from './components/modal/pinChangeModal/pin-change-modal.component';
import { SliderToggleComponent } from '../../components/signup/slider-toggle/slider-toggle.component';
import { SquashLevelDisplay } from './components/squash-level-display/squash-level-display.component';
import { MembershipPaymentsModalComponent } from './components/membership-payments-modal/membership-payments-modal.component';
import { CurrencyFormatPipe } from '@shared/pipes/currency-format.pipe';
import { PaymentNoteModalComponent } from './components/modal/payment-note-modal/payment-note-modal.component';
@NgModule({
  declarations: [
    AlertComponent,
    MultiselectDropdownComponent,
    ChartComponent,
    DatepickerComponent,
    FacilityIconComponent,
    ActivityIconComponent,
    UrlPipe,
    TooltipComponent,
    SubTooltipComponent,
    InteractionIconComponent,
    AvatarImageComponent,
    EntityIconComponent,
    TruncatePipe,
    FooterComponent,
    OrdinalDatePipe,
    ContextMenuComponent,
    BookingsTooltipComponent,
    SelectorComponent,
    NewLoginSetupComponent,
    TextInputComponent,
    PasswordValidatorComponent,
    ImageCarouselComponent,
    BoxLeaguesHandshakeComponent,
    MemberMessagesComponent,
    TextTooltipComponent,
    ActivityToggleComponent,
    AbilitySliderComponent,
    AbilityLevelSelector,
    NameAvatarModalComponent,
    ModalComponent,
    ConfirmationModalComponent,
    AddLinkedMemberModalComponent,
    PasswordResetModal,
    ActivityParticipationComponent,
    BookingRulesModalComponent,
    PaymentComponent,
    ChoosePaymentMethodComponent,
    GoCardlessRedirectComponent,
    SignUpProgressTrackerComponent,
    SignUpMembershipOptionsComponent,
    SignUpAboutYouComponent,
    SignUpSuccessComponent,
    ConfirmDirectDebitComponent,
    PaymentOptionCardComponent,
    PaymentsComponent,
    CustomGoCardlessFormComponent,
    BillingDetailsComponent,
    BankDetailsComponent,
    ConfirmDetailsComponent,
    UsefulLinksComponent,
    DisplayLinkUsersComponent,
    EditLinkedUsersComponent,
    PinChangeModal,
    SliderToggleComponent,
    SquashLevelDisplay,
    MembershipPaymentsModalComponent,
    CurrencyFormatPipe,
    NotesModalComponent,
    PaymentNoteModalComponent
  ],
  exports: [
    AlertComponent,
    MultiselectDropdownComponent,
    ChartComponent,
    DatepickerComponent,
    FacilityIconComponent,
    ActivityIconComponent,
    UrlPipe,
    TooltipComponent,
    SubTooltipComponent,
    InteractionIconComponent,
    AvatarImageComponent,
    EntityIconComponent,
    NgScrollbarModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    ClickOutsideModule,
    NgxChartsModule,
    InfiniteScrollModule,
    TruncatePipe,
    FooterComponent,
    OrdinalDatePipe,
    ContextMenuComponent,
    MomentDateModule,
    BookingsTooltipComponent,
    SelectorComponent,
    ImageCarouselComponent,
    TextInputComponent,
    BoxLeaguesHandshakeComponent,
    MemberMessagesComponent,
    DragDropModule,
    TextTooltipComponent,
    ActivityToggleComponent,
    AbilitySliderComponent,
    AbilityLevelSelector,
    NameAvatarModalComponent,
    ModalComponent,
    ConfirmationModalComponent,
    AddLinkedMemberModalComponent,
    PasswordResetModal,
    ActivityParticipationComponent,
    BookingRulesModalComponent,
    PaymentComponent,
    ChoosePaymentMethodComponent,
    GoCardlessRedirectComponent,
    SignUpProgressTrackerComponent,
    SignUpMembershipOptionsComponent,
    SignUpAboutYouComponent,
    SignUpSuccessComponent,
    ConfirmDirectDebitComponent,
    PaymentOptionCardComponent,
    PaymentsComponent,
    CustomGoCardlessFormComponent,
    BillingDetailsComponent,
    BankDetailsComponent,
    ConfirmDetailsComponent,
    UsefulLinksComponent,
    DisplayLinkUsersComponent,
    EditLinkedUsersComponent,
    PinChangeModal,
    SquashLevelDisplay,
    MembershipPaymentsModalComponent,
    CurrencyFormatPipe,
    NotesModalComponent,
    PaymentNoteModalComponent
  ],
  imports: [
    DragDropModule,
    CommonModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    RouterModule,
    NgxChartsModule,
    ClickOutsideModule,
    MomentDateModule,
    NgScrollbarModule.withConfig({
      appearance: 'standard',
      trackClass: 'scrollbar-track',
      thumbClass: 'scrollbar-thumb',
      minThumbSize: 40,
    }),
    InfiniteScrollModule,
    NgxSliderModule,
    AngularEditorModule,
    CodeInputModule.forRoot({
      codeLength: 4,
      isCharsCode: false,
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en-GB' }],
})
export class SharedModule { }
