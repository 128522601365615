import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  APIResponse,
  CoachingVenue,
  GenericAPIResponse,
  Role,
  Site,
  UnitaryAuthority,
  User,
  UserQualification,
  VoidAPIResponse,
} from '@shared/definitions';
import { environment } from '@shared/environment';
import { DateHelper } from '@shared/helpers/date-helper';
import { HttpParamsService } from '@shared/services/http-params.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RolesAndStatus, Status } from './entity.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly loggedInUserSubject = new BehaviorSubject<User>(null);
  // private readonly currentSiteSubject = new BehaviorSubject<Site>(null);
  private readonly triggerUserRolesFormOutputSubject = new Subject<void>();

  public loggedInUserObservable = this.loggedInUserSubject.asObservable();
  // public currentSite = this.currentSiteSubject.asObservable();
  public triggerUserRolesFormOutput = this.triggerUserRolesFormOutputSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly httpParamsService: HttpParamsService
  ) { }

  public storeAvatarUrl(params): Observable<GenericAPIResponse<void>> {
    const url = `${environment.apiURL}/user/store-avatar-url`;
    return this.http.post<GenericAPIResponse<void>>(url, {
      ...params,
    });
  }

  public removeAvatarUrl(userId): Observable<GenericAPIResponse<any>> {
    const url = `${environment.apiURL}/user/${userId}/avatar-url`;
    return this.http.delete<GenericAPIResponse<any>>(url);
  }

  public getAvatarUrl(userId): Observable<GenericAPIResponse<any>> {
    const url = `${environment.apiURL}/user/${userId}/avatar-url`;
    return this.http.get<GenericAPIResponse<any>>(url);
  }

  public resendAccountSetupEmail(userId: number): Observable<void> {
    const url = `${environment.apiURL}/admin/user/resend-setup-email/${userId}`;
    return this.http.get<void>(url);
  }

  public resendVerificationEmail(userId: number): Observable<void> {
    const url = `${environment.apiURL}/admin/user/resend-verification-email/${userId}`;
    return this.http.get<void>(url);
  }

  public setLoggedInUser(user: User): void {
    this.loggedInUserSubject.next(user);
  }

  public get loggedInUser(): User {
    return this.loggedInUserSubject.getValue();
  }

  public setTriggerUserRolesFormOutput(): void {
    this.triggerUserRolesFormOutputSubject.next();
  }

  // !! DO NOT USE THIS USE loggedInUser() INSTEAD !!
  public getLoggedInUser(): Observable<GenericAPIResponse<CurrentUserResponse>> {
    const url = `${environment.apiURL}/user/current`;
    return this.http.get<GenericAPIResponse<CurrentUserResponse>>(url);
  }

  // public setCurrentSite(site: Site): void {
  //   this.currentSiteSubject.next(site);
  // }

  public getCurrentSite(): Observable<GenericAPIResponse<CurrentSiteResponse>> {
    const url = `${environment.apiURL}/user/site`;
    return this.http.get<GenericAPIResponse<CurrentSiteResponse>>(url);
  }

  public userSearch(searchParams: UserSearchParams): Observable<UserSearchResponse> {
    const url = `${environment.apiURL}/user/search`;
    searchParams.isPrimary = searchParams.isPrimary ?? 0;
    const params = this.httpParamsService.getHttpParams(searchParams);
    return this.http.get<UserSearchResponse>(url, { params });
  }

  public getUserSelectedRoles(
    userId: number,
    membersOnly: boolean = false
  ): Observable<GenericAPIResponse<UserSelectedRolesResponse>> {
    const url = `${environment.apiURL}/user/selected-roles`;
    const params = this.httpParamsService.getHttpParams({ userId, membersOnly });

    return this.http.get<GenericAPIResponse<UserSelectedRolesResponse>>(url, { params });
  }

  /**
   * symmetrical
   *
   * @param number entityId
   * @param string entityType - organisations projects or events
   * @param string submitMode - createNew, linkExisting or editExisting
   * @param Contact contactForm - contains all data relating to the contact
   * @param string coachingVenueSubmitMode - createNew, linkExisting or null
   * @param CoachingVenue coachingVenueForm - optional, not necessary if coachingVenueSubmitMode
   *                                          is null
   *
   * @return message if successful
   */
  public submitContactForm(
    entityId: number,
    entityType: string,
    submitMode: string,
    contactForm: User,
    coachingVenueSubmitMode: string,
    coachingVenueForm?: CoachingVenue
  ): Observable<VoidAPIResponse> {
    const load = {
      entityId,
      entityType,
      contactForm,
      coachingVenueForm,
      coachingVenueSubmitMode,
    };

    let url: string;

    switch (submitMode) {
      case 'createNew':
        url = `${environment.apiURL}/user/create-and-link-contact`;
        break;
      case 'linkExisting':
        url = `${environment.apiURL}/user/link-existing-contact-entity`;
        break;
      case 'editExisting':
        url = `${environment.apiURL}/user/edit-existing-contact`;
        break;
    }

    return this.http.post<VoidAPIResponse>(url, load);
  }

  /**
   * @param number userId
   * @param Role[] rolesForm - all the role information with unitaryAuthorities
   *
   * @return message if successful
   */
  public updateUserRoles(userId: number, rolesForm: Role[]): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/user/update-roles`;
    return this.http.post<VoidAPIResponse>(url, { userId, rolesForm });
  }

  /**
   * @param number entityId - id of entity being linked to
   * @param string entityType - type of entity being linked to
   * @param string submitMode - createNew or linkExisting
   * @param Contact coachForm - contains all data relating to the contact
   *
   * @return message if successful
   */
  public submitCoachForm(
    entityId: number,
    entityType: string,
    submitMode: string,
    coachForm: User
  ): Observable<VoidAPIResponse> {
    const load = { entityId, entityType, coachForm };
    let url: string;

    switch (submitMode) {
      case 'createNew': {
        url = `${environment.apiURL}/user/create-and-link-coach`;
        break;
      }
      case 'linkExisting': {
        url = `${environment.apiURL}/user/link-existing-coach`;
        break;
      }
    }

    return this.http.post<VoidAPIResponse>(url, load);
  }

  public createUser(
    personForm: User,
    userRoles: UserRole[] | Role[],
    coachingVenueSubmitMode: string,
    coachingVenueForm?: CoachingVenue
  ): Observable<GenericAPIResponse<CreateUserResponse>> {
    const url = `${environment.apiURL}/user/create`;
    const load = {
      personForm,
      userRoles,
      coachingVenueSubmitMode,
      coachingVenueForm,
    };
    return this.http.post<GenericAPIResponse<CreateUserResponse>>(url, load);
  }

  public switchFavourite(entityId: number, entityType: string): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/user/switch-favourite`;
    return this.http.post<VoidAPIResponse>(url, { entityId, entityType });
  }

  public updateDetails(
    userId: number,
    detailsForm: any
  ): Observable<GenericAPIResponse<{ existingUserId: number }>> {
    const url = `${environment.apiURL}/user/update-details`;
    return this.http.post<GenericAPIResponse<{ existingUserId: number }>>(url, {
      userId,
      detailsForm,
    });
  }

  public getUserQualifications(
    userId: number,
    startRow: number,
    endRow: number
  ): Observable<GenericAPIResponse<GetUserQualificationsResponse>> {
    const url = `${environment.apiURL}/user/qualifications`;
    const params = this.httpParamsService.getHttpParams({ userId, startRow, endRow });
    return this.http.get<GenericAPIResponse<GetUserQualificationsResponse>>(url, { params });
  }

  public submitQualificationForm(
    userId: number,
    submitMode: 'createNew' | 'editExisting',
    form: UserQualification
  ): Observable<VoidAPIResponse> {
    const load = {
      qualificationForm: {
        ...form,
        dateObtained: DateHelper.objectToDB(form.dateObtained),
        dateExpires: DateHelper.objectToDB(form.dateExpires),
      },
      userId,
    };

    let url: string;

    switch (submitMode) {
      case 'createNew': {
        url = `${environment.apiURL}/user/create-qualification`;
        break;
      }
      case 'editExisting': {
        url = `${environment.apiURL}/user/edit-qualification`;
        break;
      }
    }

    return this.http.post<VoidAPIResponse>(url, load);
  }

  public deleteUserQualification(userQualificationId: number): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/user/qualification`;
    const params = this.httpParamsService.getHttpParams({ userQualificationId });
    return this.http.delete<VoidAPIResponse>(url, { params });
  }

  public getUserAddresses(
    userId: number,
    addressTypeId = 2
  ): Observable<GenericAPIResponse<GetUserAddressesResponse>> {
    const url = `${environment.apiURL}/user/addresses`;
    const params = this.httpParamsService.getHttpParams({ userId, addressTypeId });
    return this.http.get<GenericAPIResponse<GetUserAddressesResponse>>(url, { params });
  }

  public setKeyRelationship(
    userId: number,
    entityId: number,
    entityType: string
  ): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/user/key-relationship`;
    const load = { userId, entityId, entityType };
    return this.http.post<VoidAPIResponse>(url, load);
  }

  public createAdminUser(params: CreateAdminUserParams): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/user/admin/create`;
    return this.http.post<VoidAPIResponse>(url, { newMemberDetails: params });
  }

  public getAdminUserById(id: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/user/admin/${id}`;
    return this.http.get<VoidAPIResponse>(url);
  }

  public editAdminUser(
    params: EditAdminUserParams,
    userId: number
  ): Observable<GenericAPIResponse<EditAdminResponse>> {
    const url = `${environment.apiURL}/user/admin/${userId}`;
    return this.http.put<GenericAPIResponse<EditAdminResponse>>(url, params);
  }

  public getUserDetailsById(
    userId: number,
    contactType?: string
  ): Observable<GenericAPIResponse<UserEntityDetails>> {
    const url = `${environment.apiURL}/user/detailsById`;

    const params = this.httpParamsService.getHttpParams({ userId, contactType });

    return this.http.get<GenericAPIResponse<UserEntityDetails>>(url, { params });
  }

  public getMemberLinkedGuests(userId: number): Observable<GenericAPIResponse<UserEntityDetails>> {
    const url = `${environment.apiURL}/user/member-linked-guests`;

    const params = this.httpParamsService.getHttpParams({ userId });

    return this.http.get<GenericAPIResponse<UserEntityDetails>>(url, { params });
  }

  public getUserByIdHiddenOption(
    userId: number
  ): Observable<GenericAPIResponse<UserEntityDetails>> {
    const url = `${environment.apiURL}/user/detailsByIdHidden`;

    const params = this.httpParamsService.getHttpParams({ userId });

    return this.http.get<GenericAPIResponse<UserEntityDetails>>(url, { params });
  }

  public updateUserDetails(userId: number, valuesToUpdate: object): Observable<APIResponse> {
    const url = `${environment.apiURL}/user/details`;
    return this.http.post<APIResponse>(url, { userId, ...valuesToUpdate });
  }

  public getMembershipLabelForUser(user): string {
    if (this.userIsGuest(user)) return null;

    if (user.active_memberships?.length) {
      return user.active_memberships[0]?.product.name;
    }

    return user.membershipLabel || user.licenceProductName;
  }

  public userIsGuest(user: User): boolean {
    if (!user.userId) return true;

    if (user?.role) {
      return user.role === 'GUEST';
    }

    if (user?.user_roles) {
      return (
        user.user_roles.some((e) => e.roleTag === 'GUEST') &&
        !user.user_roles.some((e) => e.roleTag === 'MEMBER')
      );
    }

    return (
      user?.type?.toUpperCase().includes('GUEST') && !user?.type?.toUpperCase().includes('MEMBER')
    );
  }

  public isLinkedUserGuest(user): boolean {
    if (!user.userId) return true;
    return (
      user?.role.toUpperCase().includes('GUEST') && !user?.role.toUpperCase().includes('MEMBER')
    );
  }

  public addLinkedGuestProfile(params: AddGuestProfileParams): Observable<void> {
    const url = `${environment.apiURL}/user/add-linked-guest-profile`;
    return this.http.post<void>(url, params);
  }

  public removeProfileLink(params: RemoveGuestProfileParams): Observable<void> {
    const url = `${environment.apiURL}/user/remove-linked-guest`;
    return this.http.post<void>(url, params);
  }

  public getUserRoles(userId: number): Observable<UserRole[]> {
    const url = `${environment.apiURL}/user/${userId}/user-roles`;
    return this.http.get<UserRole[]>(url);
  }

  public upsertUserRoles(userId: number, userRoles: UserRole[]): Observable<void> {
    const url = `${environment.apiURL}/user/${userId}/upsert-user-roles`;
    return this.http.post<void>(url, { userRoles });
  }

  public upsertUserStatus(userId: number, userStatus: Status): Observable<APIResponse> {
    const url = `${environment.apiURL}/user/${userId}/upsert-user-status`;
    return this.http.post<APIResponse>(url, { userStatus });
  }

  public stringifyRoles(rolesAndStatus: RolesAndStatus[]): string {
    const rolesArray = rolesAndStatus.map((role) => role.roleLabel);
    // if member exists => include status in string
    if (rolesArray.find((role) => role === 'Member')) {
      const memberIndex = rolesArray.indexOf('Member');
      const memberStatus = rolesAndStatus[memberIndex]?.statusLabel;
      rolesArray[memberIndex] += ` (${memberStatus})`;
      rolesArray.splice(0, 0, rolesArray.splice(memberIndex, 1)[0]);
    } else if (rolesArray.find((role) => role === 'Admin')) {
      const adminIndex = rolesArray.indexOf('Admin');
      const adminStatus = rolesAndStatus[adminIndex]?.statusLabel;

      rolesArray[adminIndex] += ` (${adminStatus})`;
      rolesArray.splice(0, 0, rolesArray.splice(adminIndex, 1)[0]);
    }
    return rolesArray.join(', ');
  }

  public loginAsUser(
    userId: number
  ): Observable<
    GenericAPIResponse<{ token: string; returnToken: string; availableSites: Site[] }>
  > {
    const url = `${environment.apiURL}/admin/user/login-as-user/${userId}`;
    return this.http.post<
      GenericAPIResponse<{ token: string; returnToken: string; availableSites: Site[] }>
    >(url, {});
  }

  // Check all permissions to see if a parent one exists for input permission e.g. admin.member is a parent of admin.member.edit
  public hasPermission(inputPermission: string): boolean {
    return this.loggedInUser?.permissions?.length
      ? this.loggedInUser.permissions.some((permission) => inputPermission.startsWith(permission))
      : false;
  }

  // Check input permission to see if is a parent for any existing permission e.g. admin.member.edit has parent admin.member
  public permissionHas(inputPermission: string): boolean {
    return this.loggedInUser?.permissions?.length
      ? this.loggedInUser.permissions.some((permission) => permission.startsWith(inputPermission))
      : false;
  }

  public hasFacilityTypePermission(
    inputPermissionPrefix: string,
    facilityGroupTag: string
  ): boolean {
    const inputPermission = `${inputPermissionPrefix}.${facilityGroupTag}`;

    return this.loggedInUser.permissions.some((permission) =>
      inputPermission.startsWith(permission)
    );
  }

  public returnToAccount(returnToken: string): void {
    const url = `${environment.apiURL}/admin/return-to-account`;

    this.http
      .post<GenericAPIResponse<{ token: string; availableSites: Site[] }>>(url, {
        returnToken,
      })
      .subscribe({
        next: (response) => {
          // Clear local storage and navigate back to previous site
          const previousUrl = localStorage.getItem('previousUrl');
          const previousSiteId = localStorage.getItem('previousSiteId');

          localStorage.clear();
          localStorage.setItem('JWT', response.data.token);
          localStorage.setItem('availableSites', JSON.stringify(response.data.availableSites));
          localStorage.setItem('siteId', previousSiteId);
          window.location.href = previousUrl;
        },
      });
  }
}

export type EditAdminUserParams = {
  firstName: string;
  lastName: string;
  email: string;
  linkLogin?: boolean;
};

export type UserSearchParams = {
  roleSelected?: string;
  entityId?: number;
  entityType?: string;
  searchTerm?: string;
  sports?: string;
  orgTypes?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortByDir?: 'asc' | 'desc';
  relationshipRole?: string;
  localities?: string;
  excludeByEntityId?: boolean;
  startRow?: number;
  endRow?: number;
  isPrimary?: number;
  onlyAdminUsers?: boolean;
  onlyMembers?: boolean;
  statuses?: string;
  includeArchivedRelationships?: boolean;
  productIds?: string;
  paymentTypeTags?: string;
  onlyGuestsLinkedToMemberId?: number | null;
};

export type CurrentUserResponse = {
  user: User;
  token: string;
};

export type CurrentSiteResponse = {
  currentSite: Site;
};

export type UserSelectedRolesResponse = {
  roleTags: string[];
  roleUnitaryAuthorities: RoleUnitaryAuthority[];
};

export type RoleUnitaryAuthority = {
  roleTag: string;
  unitaryAuthorities: UnitaryAuthority[];
};

export type UserRole = {
  roleId: number;
  roleTag: string;
  roleLabel: string;
  roleTypeTag: string;
  roleStatus?: RoleStatus;
  userRoleId?: number;
};

export type RoleStatus = {
  statusId: number;
  statusTag: string;
  label: string;
  canLogin: number;
};

export type CreateUserResponse = {
  userId: number;
  existingUser: User;
};

export type GetUserQualificationsResponse = {
  qualifications: UserQualification[];
};

export type GetUserAddressesResponse = {
  addresses: Address[];
};

export type CreateAdminUserParams = {
  firstName: string;
  lastName: string;
  email: string;
};

export type UserSearchResponse = GenericAPIResponse<{ search: User[]; primaryContact: User }>;

export type UserEntityDetails = {
  userId: number;
  fullName: string;
  joinDate: Date;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  primaryEmail: string;
  secondaryEmail: string;
  dateOfBirth: Date | 'cleared';
  primarySportId: number;
  age: number;
  primaryAddress: UserAddress;
  secondaryAddress: UserAddress;
  carRegistration: string[];
  emergencyContactName: string;
  emergencyContactNumber: string;
  secondaryEmergencyContactNumber: string;
  gender: string;
  cardInfo: CardInfo[];
  title: string;
  healthDetails: string;
  sportParticipationIds: number[];
  upcomingBookingNotifications: number;
  membershipSourceId: number;
  commsPrefIds: number[];
  mediaUsageTypeIds: number[];
  interestIds: number[];
  referredByName: string;
  referredByUserId: number;
  modifiedByName: string;
  modifiedByUserId: number;
  dateModified: Date;
  workEmail?: string;
  displayDetails: boolean;
  searchable: boolean;
  productId: number;
  productName: string;
  productTag: string;
  loginStatus?: number;
  sendSquashLevels?: number;
  squashPlayerId?: number;
  squashLevel?: number;
  referringFriend?: string;
};

export type Card = {
  id: number;
  cardNumber: string;
  type: CardType;
};

export type CardType = {
  cardTypeDescription: string;
  cardTypeId: number;
  cardTypeLabel: string;
  cardTypeTag: string;
  siteId: number;
};

export type UserAddress = {
  addressId: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  city: string;
  country: string;
  postcode: string;
  county: string;
};

export type CardInfo = {
  type: string;
  active: Card[];
  deactivated: Card[];
};

export type AddGuestProfileParams = {
  firstname: string;
  lastname: string;
  email: string;
  userId?: number;
};

export type RemoveGuestProfileParams = {
  userId?: number;
  linkedUserId: number;
};

export type BasicUserDetails = {
  firstName: string;
  lastName: string;
  email: string;
};

export type EditAdminResponse = {
  unlinkedLoginId?: number;
};
