import { PaymentOptionCardComponent } from './../payment-option-card/payment-option-card.component';
import { finalize } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
} from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { User } from '@sportinsight/src/app/sub-projects/shared/definitions';
import {
  PaymentService,
  SignUpProduct,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SignupService, PaymentTitles } from '@sportinsight/src/app/services/signup.service';

@Component({
  selector: 'app-choose-payment-method',
  templateUrl: './choose-payment-method.component.html',
  styleUrls: ['./choose-payment-method.component.scss'],
})
export class ChoosePaymentMethodComponent implements OnInit, OnChanges {
  @Input() public siteId: number;
  @Input() public prospectiveMemberId: number;
  @Input() public selectedMembership: number;
  @Input() public paymentMethodsHidden: boolean = false;
  @Output() private readonly hidePaymentMethodEmitter = new EventEmitter<boolean>();
  @Input() selectedMonthly: boolean;

  @ViewChild(PaymentOptionCardComponent) paymentOptionCardComponent: PaymentOptionCardComponent;

  public selectedPaymentMethod: string;
  public product: SignUpProduct = { yearlyPrice: 0, monthlyPrice: 0 };
  public goCardlessUrl: string;
  public loading: boolean = false;
  public ddPeriod: string = 'YEARLY';
  public PAYMENT_TITLES = PaymentTitles;
  public paymentPeriod: string = 'YEARLY';
  public priceId: number;

  public user: User;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.paymentService.getProduct(this.selectedMembership, this.siteId.toString()).subscribe({
      next: (response) => {
        const prices = {
          yearlyPrice: response.data.yearlyPrice,
          monthlyPrice: response.data.monthlyPrice,
          dueToday: response.data.dueToday,
          prorataPrice: response.data.prorataPrice,
          joiningFee: response.data.joiningFee,
          membershipExpiryDate: response.data.membershipExpiryDate,
          monthlyPriceId: response.data.monthlyPriceId,
          yearlyPriceId: response.data.yearlyPriceId,
        };

        this.product = prices;
        this.priceId = this.product.yearlyPriceId ? this.product.yearlyPriceId : this.product.monthlyPriceId;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  public setPaymentMethod(title: string): void {
    this.selectedPaymentMethod = title;
    localStorage.setItem('paymentType', this.selectedPaymentMethod);
    this.loading = true;
    switch (title) {

      case 'By Direct Debit':
        this.ddPeriod = this.paymentOptionCardComponent.period.value;
        this.onDirectDebit();
        break;
      case 'By Card':
        this.ddPeriod = 'FULL';
        this.onCard();
        break;
      case 'By BACS':
        this.ddPeriod = 'FULL';
        this.onBACS();
        break;
      default:
        break;
    }
  }

  public onDirectDebit(): any {
    if (this.ddPeriod === 'MONTHLY') {
      this.priceId = this.product.monthlyPriceId;
    }
    this.paymentService
      .initDebitMandate({
        userId: this.prospectiveMemberId,
        productId: this.selectedMembership,
        siteId: this.siteId,
        period: this.ddPeriod,
        priceId: this.priceId,

      })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.goCardlessUrl = response.data.redirect_url;
          this.paymentService.setGoCardlessUrl(this.goCardlessUrl);
          this.hidePaymentMethodEmitter.emit(true);
        },
      });
  }

  public onCard(): any {
    const site = this.router.url.split('/')[1];
    this.loading = false;
    this.hidePaymentMethodEmitter.emit(true);
    this.paymentService.setGoCardlessUrl(
      `${this.document.location.origin}/${site}/member/signup?finalize=success&method=3`
    );
  }

  public onBACS(): any {
    const site = this.router.url.split('/')[1];
    this.loading = false;
    this.hidePaymentMethodEmitter.emit(true);
    window.location.href = `${this.document.location.origin}/${site}/member/signup?finalize=success&method=1`;
  }
}
