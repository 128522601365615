import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupService, PaymentTitles } from '@sportinsight/src/app/services/signup.service';
import {
  BACSDetails,
  PaymentService,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { finalize } from 'rxjs';
import { DateHelper } from '@shared/helpers/date-helper';

@Component({
  selector: 'app-payment-option-card',
  templateUrl: './payment-option-card.component.html',
  styleUrls: ['./payment-option-card.component.scss'],
})
export class PaymentOptionCardComponent implements OnInit {
  @Input() title: PaymentTitles;
  @Input() monthlyPrice: number;
  @Input() yearlyPrice: number;
  @Input() prorataPrice: number;
  @Input() joiningFee: number;
  @Input() dueToday: number;
  @Input() membershipExpiration;
  @Input() selectedMonthly: boolean = true;

  @Output() selectPaymentMethod = new EventEmitter<string>();
  @Output() selectPaymentPeriod = new EventEmitter<string>();

  public period = new FormControl('YEARLY');
  public siteId: number;
  public bankingDetails: BACSDetails;
  public membershipRenewelMonth: string;
  public membershipRenewelDate: string;
  public nextMonthlyPaymentDateShort: string;
  public nextMonthlyPaymentDate: string;

  constructor(
    private paymentService: PaymentService,
    private signupService: SignupService,
    public readonly router: Router
  ) { }

  ngOnInit(): void {
    if (this.selectedMonthly) {
      this.period.setValue('MONTHLY')
    } else { this.period.setValue('MONTHLY'); }

    let currentDate = new Date(); // create a new Date object with the current date
    let monthlyPaymentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0); // get the last day of the next month
    this.nextMonthlyPaymentDate = DateHelper.getFormattedDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0).toString()); // get the last day of the next month
    this.membershipRenewelMonth = DateHelper.getFormattedDate(this.membershipExpiration);
    this.membershipRenewelDate = `${DateHelper.getFormattedDate(new Date(this.membershipExpiration).toString())} ${new Date(this.membershipExpiration).getFullYear().toString()}`;
    this.nextMonthlyPaymentDateShort = 1 + '/' + (monthlyPaymentMonth.getMonth() + 1) + '/' + monthlyPaymentMonth.getFullYear();

    this.signupService
      .getSiteId(this.router.url)
      .pipe(finalize(() => { }))
      .subscribe({
        next: (response) => {
          this.siteId = response.data.siteId;
          this.getBankingDetails(this.siteId);
        },
      });
  }

  public footerText(title): string {
    let footerText: string = '';
    switch (title) {
      case 'By Direct Debit':
        footerText = '(Powered by GoCardless & Stripe)';
        break;
      case 'By Card':
        footerText = '(Powered by Stripe)';
        break;
      default:
        footerText = '';
        break;
    }
    return footerText;
  }

  public priceSubtext(title): string {
    let text: string = '';
    let currentDate = new Date(); // create a new Date object with the current date
    let nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0); // get the last day of the next month
    let yearlyRenewlDate = new Date(this.membershipExpiration);
    yearlyRenewlDate.setDate(yearlyRenewlDate.getDate() - 1);
    switch (title) {
      case 'By Direct Debit':
        if (this.joiningFee) {
          text = `Initial payment covers until ${this.period.value === 'MONTHLY'
            ? DateHelper.getFormattedDate(nextMonth.toString()) + ' ' + nextMonth.getFullYear()
            : DateHelper.getFormattedDate(yearlyRenewlDate.toString()) + ' ' + yearlyRenewlDate.getFullYear()}.`;
        } else {
          text = `Initial payment covers until ${this.period.value === 'MONTHLY'
            ? DateHelper.getFormattedDate(nextMonth.toString()) + ' ' + nextMonth.getFullYear()
            : DateHelper.getFormattedDate(yearlyRenewlDate.toString()) + ' ' + yearlyRenewlDate.getFullYear()}.`;
        }
        break;

      default:
        if (this.joiningFee) {
          text = `Payment covers your membership up until ${DateHelper.getFormattedDate(yearlyRenewlDate.toString()) + ' ' + yearlyRenewlDate.getFullYear()} and includes a £${this.joiningFee} joining fee.`;
        } else {
          text = `Payment covers your membership up until ${DateHelper.getFormattedDate(yearlyRenewlDate.toString()) + ' ' + yearlyRenewlDate.getFullYear()}.`;
        }
        break;
    }

    return text;
  }

  public selectPayment(title: string): void {
    this.selectPaymentPeriod.emit(this.period.value);
    this.selectPaymentMethod.emit(title);
  }

  public getPrice(): number {
    if (this.period.value === 'YEARLY') {
      return this.prorataPrice + this.joiningFee;
    }
    return this.monthlyPrice + this.joiningFee;
  }

  public getBankingDetails(siteId): void {
    this.paymentService.getBankingDetails(siteId).subscribe({
      next: (response) => {
        this.bankingDetails = response.data;
      },
    });
  }
}
