import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
@Component({
  selector: 'app-signup-progress-tracker',
  templateUrl: './signup-progress-tracker.component.html',
  styleUrls: ['./signup-progress-tracker.component.scss'],
})
export class SignUpProgressTrackerComponent implements OnInit {
  public loading = false;
  @Input() public signupStage: string = 'membership-options';
  @Input() public paymentComplete: boolean = false;
  @Output() private readonly stageEmitter = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  public onStageIndicatorClick(e: number): void {
    if (!this.paymentComplete) {
      this.stageEmitter.emit(e);
    }
  }
}
