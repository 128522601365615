import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { UserService } from '@sportinsight/src/app/sub-projects/shared/services/user.service';

@Component({
  selector: 'app-go-cardless-redirect',
  templateUrl: './go-cardless-redirect.component.html',
  styleUrls: ['./go-cardless-redirect.component.scss'],
})
export class GoCardlessRedirectComponent implements OnInit {
  public redirectUrl: string;
  public loading: boolean = false;
  public method: number;
  public error: boolean = false;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.method = parseInt(params.method);
    });

    this.redirectUrl = this.paymentService.getGoCardlessUrl();
    // after a short time, redirect user to next payment step
    if (this.redirectUrl) {
      setTimeout(() => {
        window.location.href = `${this.redirectUrl}`;
      }, 3000);
    } else {
      this.error = true;
    }
  }
}
