import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Site } from '@shared/definitions';
import { AlertService } from '@shared/services/alert.service';
import { finalize } from 'rxjs';
import {
  AttemptLoginParams,
  AttemptLoginResponse,
  AuthService,
  LoginProperties,
} from '@sportinsight-services/auth.service';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
})
export class TwoFactorAuthenticationComponent implements OnInit {
  @Input() private readonly loginCredentials: AttemptLoginParams;

  @Output() private readonly loggedInEmitter = new EventEmitter<{
    loginProperties: LoginProperties;
    loginCredentials: AttemptLoginParams;
  }>();
  @Output() private readonly activatedEmitter = new EventEmitter<string[]>();
  @Output() private readonly isLoadingEmitter = new EventEmitter<boolean>();

  public twoFACodeInputControl = new FormControl('', Validators.required);
  public lostDevicePrompt = false;
  private selectedSiteId: number = 0;

  constructor(
    private readonly authService: AuthService,
    private readonly alertService: AlertService
  ) { }

  ngOnInit(): void { }

  public onSubmitTwoFACode(): void {
    if (this.loginCredentials) {
      this.login();
    } else {
      this.activateTwoFA(this.twoFACodeInputControl.value);
    }
  }

  private login(): void {
    if (this.twoFACodeInputControl.invalid) {
      return;
    }

    this.setLoading(true);

    if (localStorage.getItem('siteId')) {
      this.selectedSiteId = +localStorage.getItem('siteId');
    }

    const loginParams: AttemptLoginParams = {
      ...this.loginCredentials,
      twoFACode: this.twoFACodeInputControl.value,
      siteId: this.selectedSiteId && this.selectedSiteId !== 0 ? this.selectedSiteId : null,
      rememberMe: this.loginCredentials.rememberMe ?? false,
    };
    this.authService
      .loginWithTwoFA(loginParams)
      .subscribe({
        next: (response: AttemptLoginResponse) => {
          // Start jwt refresh so token doesn't run out while site in use
          this.authService.startRefreshJWTJob();
          this.emitLoggedIn(response.data.loginProperties);
        },
        error: (error) => {
          this.alertService.emitErrorAlert(error.error?.message);
          this.setLoading(false);
        },
      });
  }

  public activateTwoFA(twoFACode: string): void {
    this.setLoading(true);

    this.authService
      .activateTwoFA(twoFACode)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: (response) => {
          this.activatedEmitter.emit(JSON.parse(response.recoveryCodesJson));
        },
        error: (error) => {
          this.alertService.emitErrorAlert(error.error?.message);
        },
      });
  }

  private emitLoggedIn(loginProperties: LoginProperties): void {
    this.loggedInEmitter.emit({ loginProperties, loginCredentials: this.loginCredentials });
  }

  private setLoading(isLoading: boolean): void {
    this.isLoadingEmitter.emit(isLoading);
  }
}
