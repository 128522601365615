import { MemberOptions } from '../../../services/signup.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupService } from '../../../services/signup.service';
import { finalize } from 'rxjs';
@Component({
  selector: 'app-signup-membership-options',
  templateUrl: './signup-membership-options.component.html',
  styleUrls: ['./signup-membership-options.component.scss'],
})
export class SignUpMembershipOptionsComponent implements OnInit {
  public loading = false;
  public membershipOptions: MemberOptions[];
  public termToggle = false;

  @Input() public signupStage: number;
  @Input() public selectedMembership: number = 1;
  @Input() public displayErrors: boolean;
  @Input() public siteId: number;

  @Output() private readonly selectedMembershipEmitter = new EventEmitter<number>();
  @Output() private readonly stageEmitter = new EventEmitter<number>();
  @Output() private readonly selectedTermEmitter = new EventEmitter<boolean>();

  constructor(
    public readonly router: Router,
    private readonly signupService: SignupService,
    private readonly activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getMembershipOptions();
    localStorage.removeItem('selectedMembership');
    this.selectedMembershipEmitter.emit(0);
    this.selectedTermEmitter.emit(true);
  }

  public getDescriptors(descriptors) {
    return descriptors ? descriptors.split(',') : []
  }

  private getMembershipOptions(): void {
    this.loading = true;

    this.signupService
      .getMembershipOptions(this.siteId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.membershipOptions = response.data.paymentOptions;
        },
      });
  }

  public getPaymentOptionInfo(option, term) {
    return option.paymentOptions.find((element) => element.paymentTermId === term) ?? null
  }

  public toggleTerm(term: boolean): void {
    this.termToggle = term;
    localStorage.removeItem('selectedMembership');
    this.selectedMembershipEmitter.emit(0);
    this.selectedTermEmitter.emit(term);
  }

  // select membership option
  public selectOption(id): void {
    // if deselecting
    if (id === this.selectedMembership) {
      localStorage.removeItem('selectedMembership');
      this.selectedMembershipEmitter.emit(0);
    } else {
      localStorage.setItem('selectedMembership', id);
      this.selectedMembershipEmitter.emit(id);
    }
  }

  public nextStage(): void {
    if (this.selectedMembership) {
      this.stageEmitter.emit(2);
    }
  }
}
