<div class="container">
  <ng-template #errorMessage>
    <div class="loader-container">
      <h1>Payment already processed</h1>
      <p>
        Payment has already been processed for this membership, please check your email or contact
        admin for support.
      </p>
    </div>
  </ng-template>
  <div class="loader-container" *ngIf="!error; else errorMessage">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <h1>Verifying Direct Debit</h1>
  </div>
</div>
