<app-alert [loginScreen]="true"></app-alert>
<header>
  <div class="logo">
    <a href="#" class="logo-container">
      <img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>
</header>

<main class="login-container">
  <section id="sportinsightHomepage" class="login-form">
    <app-login-template>
      <div heading>Setup login</div>
      <div content>
        <ng-template #loginSpinner>
          <div class="content-container">
            <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
            <span class="light-tag">Loading ...</span>
          </div>
        </ng-template>
        <div *ngIf="!loading; else loginSpinner" class="setup-login-container">
          <ng-template #setupLogin>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <fieldset>
                <div class="form-group password-strength-container">
                  <label>Email:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    [class.error]="displayErrors && form.get('email').invalid"
                  />
                  <div class="clear"></div>
                  <span class="error-msg on" *ngIf="displayErrors && form.get('email').invalid">
                    Please enter a valid email address.
                  </span>
                </div>
                <div class="clear"></div>
                <div class="form-group password-strength-container">
                  <label>First name:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                    [class.error]="displayErrors && form.get('firstName').invalid"
                  />
                  <div class="clear"></div>
                  <span class="error-msg on" *ngIf="displayErrors && form.get('firstName').invalid">
                    A first name is required
                  </span>
                </div>
                <div class="clear"></div>
                <div class="form-group password-strength-container">
                  <label>Last name:</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                    [class.error]="displayErrors && form.get('lastName').invalid"
                  />
                  <div class="clear"></div>
                  <span class="error-msg on" *ngIf="displayErrors && form.get('lastName').invalid">
                    A last name is required.
                  </span>
                </div>
                <div class="clear"></div>
                <div class="form-group password-strength-container password-input">
                  <label>Password:</label>
                  <input
                    type="password"
                    class="form-control"
                    autocomplete="new-password"
                    formControlName="password"
                    [class.error]="displayErrors && form.get('password').invalid"
                  />
                  <span *ngIf="passwordLength > 0" class="password-strength-text">
                    Password strength:
                    <span class="password-strength-rating" [class]="passwordStrength('class')">
                      {{ passwordStrength("text") }}
                    </span>
                  </span>

                  <div class="clear"></div>

                  <span class="error-msg on" *ngIf="displayErrors && form.get('password').invalid">
                    Please enter a valid password.
                  </span>
                </div>

                <ul class="password-validators">
                  <li class="inactive" [ngClass]="{ active: passwordLength >= 8 }">
                    - At least 8 characters long.
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasLowercase }">
                    - One or more lower case letters (a-z).
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasUppercase }">
                    - One or more upper case letters (A-Z).
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasNumber }">
                    - One or more numbers (0-9).
                  </li>
                </ul>
                <div class="clear"></div>
                <div class="form-group">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    class="form-control"
                    autocomplete="new-password"
                    formControlName="passwordConfirmation"
                    [class.error]="displayErrors && form.get('passwordConfirmation').invalid"
                  />

                  <div class="clear"></div>
                  <span
                    class="error-msg on"
                    *ngIf="displayErrors && form.get('passwordConfirmation').invalid"
                  >
                    These passwords do not match - please try again
                  </span>
                </div>
                <div class="clear"></div>

                <div class="form-group privacy-form-group">
                  <label>Privacy Policy</label>

                  <div class="privacy-container">
                    <input
                      type="checkbox"
                      formControlName="privacyConfirmation"
                      [class.error]="displayErrors && form.get('privacyConfirmation').invalid"
                    />

                    <p>
                      Please confirm you have read and accepted our
                      <a href="https://www.getsportinsight.com/privacy" target="_blank"
                        >privacy policy</a
                      >
                    </p>
                  </div>

                  <div class="clear"></div>
                  <span
                    class="error-msg on"
                    *ngIf="displayErrors && form.get('privacyConfirmation').invalid"
                  >
                    Please confirm you have read and accepted our privacy policy
                  </span>
                </div>

                <button class="btn setup-account-button" type="submit">Setup account</button>
              </fieldset>
            </form>
          </ng-template>
          <div *ngIf="verifyEmailPrompt; else setupLogin">
            <div class="verify-email-prompt-container">
              An email has been sent to you so you can now verify your account.
              <br />
              <br />
              - Click <a href="login">here</a> to login once verified.
            </div>
          </div>
        </div>
      </div>
    </app-login-template>
  </section>
  <a href="https://www.getsportinsight.com/cookies" class="cookie-policy">Cookie Policy</a>
</main>
