<div class="card-wrapper">
  <div class="signup-container" *ngIf="loading">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
  </div>

  <form id="payment-form" [ngClass]="{ hidden: loading }">
    <div class="form-row">
      <div id="payment-element">
        <!-- a Stripe Element will be inserted here. -->
      </div>
      <!-- Used to display form errors -->
      <div id="payment-errors" role="alert"></div>
    </div>
    <div class="payment-component">
      <button class="btn" (click)="pay($event)">Submit</button>
    </div>
  </form>
</div>
