import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      Sentry.captureException(error.originalError || error);
      (console.error || console.log).call(console, error.stack || error);
    }
  }
}
