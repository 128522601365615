import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert, AlertService } from '@shared/services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() public readonly loginScreen: boolean = false;
  @Input() public readonly signupScreen: boolean = false;

  public alert: Alert = null;

  constructor(private readonly alertService: AlertService, private readonly router: Router) {}

  ngOnInit(): void {
    this.alertService.alert.subscribe({
      next: (alert) => (this.alert = alert),
    });
  }

  public navToUrl(): void {
    if (this.alert.url) {
      this.router.navigateByUrl(this.alert.url);
    }
  }
}
