import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpParamsService {
  /**
   * Creates a HttpParams object from an array of key-value pairs
   *
   * @param any params
   *
   * @return HttpParams
   */
  public getHttpParams(params: object): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params).forEach((key: any) => {
      // Don't append param if value is null, undefined or blank
      if (![null, undefined].includes(params[key])) {
        httpParams = httpParams.append(key, params[key]);
      }
    });

    return httpParams;
  }
}
