<div class="login-template-container">
  <div class="heading-container black">
    <img src="assets/images/icons/icon-pad-white.svg" alt="Login" />
    <h2><ng-content select="[heading]"></ng-content></h2>
    <div class="clear"></div>
  </div>
  <ng-template #loginSpinner>
    <div class="content-container">
      <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
      <span class="light-tag">Loading ...</span>
    </div>
  </ng-template>
  <div class="content-container" *ngIf="!loading; else loginSpinner">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
