import { Component, OnInit, Inject } from '@angular/core';
import { PaymentService } from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-confirm-direct-debit',
  templateUrl: './confirm-direct-debit.component.html',
  styleUrls: ['./confirm-direct-debit.component.scss'],
})
export class ConfirmDirectDebitComponent implements OnInit {
  public mandate: string;
  public error: boolean = false;
  public siteId: number;
  public urlTag: string;
  public period: string;
  public responseTag:string;

  constructor(
    private readonly paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly alertService: AlertService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.responseTag = localStorage.getItem('responseTag');
    this.route.params.subscribe({
      next: (params) => {
        this.siteId = parseInt(params?.siteId);
        this.urlTag = this.router.url.split('/')[1];
      },
    });

    this.route.queryParams.subscribe((params) => {
      // tslint:disable-next-line:no-string-literal
      this.mandate = params['redirect_flow_id'];
      this.period = params['period'];
    });

    if (!this.siteId || !this.period) {
      this.alertService.emitErrorAlert('Could not find club details');
    }

    if (this.mandate) {
      this.paymentService.completeDebitMandate(this.mandate, this.siteId, this.period, this.responseTag).subscribe({
        next: (response) => {
          window.location.href = `${this.document.location.origin}/${this.urlTag}/member/signup?finalize=success&method=2`;
        },
        error: (error) => {
          this.error = true;
        },
      });
    }
  }
}
