import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@shared/environment';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import '@stripe/stripe-js';

Sentry.init({
  dsn: 'https://80fea48c29944c06a2855b86a0cb0268@o4504293988368384.ingest.sentry.io/4504294006849537',
  environment: environment.name,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.name === 'production' ? 0.5 : 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
