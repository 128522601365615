<div class="datepicker-component-container">
  <div class="datepicker-input-container">
    <input
      type="text"
      class="search-input"
      [placeholder]="placeholder"
      autocomplete="off"
      matInput
      [formControl]="datePickerControl"
      [matDatepicker]="picker"
      [class.error]="datepickerError"
      [class.warning]="datepickerWarning"
      [matDatepickerFilter]="dateFilter"
      [readonly]="!allowTyping"
      [min]="minDate"
      [max]="maxDate"
      (click)="!allowTyping && picker.open()"
    />
    <img
      *ngIf="this.canClear"
      (click)="clearDate()"
      class="clear-date-button"
      src="assets/images/icons/icon-close-blue.svg"
      alt="Clear date"
    />
  </div>
  <mat-datepicker-toggle (click)="picker.open()" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [disabled]="datePickerControl.disabled"></mat-datepicker>
</div>
