import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from '@sportinsight-services/auth.service';
import { emailRegExp, passwordRegExp } from '@shared/definitions';
import { AlertService } from '@shared/services/alert.service';
import { BasicUserDetails } from '@shared/services/user.service';

@Component({
  selector: 'app-setup-login',
  templateUrl: './setup-login.component.html',
  styleUrls: ['./setup-login.component.scss'],
})
export class SetupLoginComponent implements OnInit {
  public displayErrors = false;
  public form: FormGroup;
  public currentPasswordWeak = false;
  public loading = false;
  public verifyEmailPrompt = false;

  private token: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('token')) {
      this.token = this.route.snapshot.paramMap.get('token');
      this.getBasicUserDetails();
    }

    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegExp)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(passwordRegExp)]],
      passwordConfirmation: ['', [Validators.required, this.confirmPasswordValidator.bind(this)]],
      privacyConfirmation: ['', [Validators.required, this.confirmPrivacyValidator.bind(this)]],
    });
  }

  private updateFormWithBasicDetails(basicUserDetails: BasicUserDetails): void {
    this.form.get('email').patchValue(basicUserDetails.email);
    this.form.get('firstName').patchValue(basicUserDetails.firstName);
    this.form.get('lastName').patchValue(basicUserDetails.lastName);
  }

  private confirmPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.form) return null;
    return control.value === this.form.value.password ? null : { consistent: true };
  }

  private confirmPrivacyValidator(checkbox) {
    if (!this.form) return null;
    return !checkbox.value;
  }

  public onSubmit(): void {
    this.displayErrors = true;

    if (this.form.valid) {
      this.setupLogin();
    }
  }

  private getBasicUserDetails(): void {
    this.loading = true;

    this.authService
      .getBasicUserDetails(this.token)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          this.updateFormWithBasicDetails(response.data);
        },
      });
  }

  private setupLogin(): void {
    this.loading = true;

    const params: SetupLoginParams = {
      email: this.form.value.email,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      password: this.form.value.password,
      token: this.token,
    };

    this.authService
      .setupLogin(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.alertService.emitSuccessAlert('Login setup successful');
          localStorage.clear();
          this.verifyEmailPrompt = true;
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.emitErrorAlert(error.error?.message || 'Reset failed');
        },
      });
  }

  public passwordStrength(use: string): string {
    switch (use) {
      case 'class':
        if (this.form.get('password').value.length < 8) return 'too-short';
        if (this.form.get('password').invalid) return 'too-weak';
        return 'strong';
      case 'text':
        if (this.form.get('password').value.length < 8) return 'Too short';
        if (this.form.get('password').invalid) return 'Too weak';
        return 'Strong';
    }
  }

  get passwordLength() {
    return this.form.get('password').value.length;
  }
  get passwordHasUppercase() {
    let password = this.form.get('password').value;
    return password !== password.toLowerCase();
  }
  get passwordHasLowercase() {
    let password = this.form.get('password').value;
    return password !== password.toUpperCase();
  }
  get passwordHasNumber() {
    return /\d/.test(this.form.get('password').value);
  }
}

export type SetupLoginParams = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  token: string;
};
