import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivityData,
  BookingType,
  Facility,
  GenericAPIResponse,
  InteractionType,
  OrganisationType,
  Role,
  Site,
  UnitaryAuthority,
  User,
} from '@shared/definitions';
import { environment } from '@shared/environment';
import { Card, CardType, UserRole } from '@shared/services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewsType } from './news.service';
import { DocumentType } from '@shared/services/documents.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardStateService {
  private readonly standardUsersSubject = new BehaviorSubject<User[]>([]);
  private readonly organisationTypesListSubject = new BehaviorSubject<OrganisationType[]>([]);
  private readonly userRoleListSubject = new BehaviorSubject<Roles>({
    organisations: [],
    events: [],
    projects: [],
  });
  private readonly interactionTypeListSubject = new BehaviorSubject<InteractionType[]>([]);
  private readonly unitaryAuthorityListSubject = new BehaviorSubject<UnitaryAuthority[]>([]);
  private readonly activityListSubject = new BehaviorSubject<ActivityData[]>([]);
  private readonly qualificationListSubject = new BehaviorSubject<ActivityData[]>([]);
  private readonly facilityListSubject = new BehaviorSubject<Facility[]>([]);
  private readonly modalFreezeSubject = new BehaviorSubject<boolean>(false);
  private readonly titlesSubject = new BehaviorSubject<string[]>([]);
  private readonly gendersSubject = new BehaviorSubject<string[]>([]);
  private readonly membershipSourceSubject = new BehaviorSubject<MembershipSource[]>([]);
  private readonly commsPrefsSubject = new BehaviorSubject<CommsPrefType[]>([]);
  private readonly interestsSubject = new BehaviorSubject<Interest[]>([]);
  private readonly mediaUsageTypesSubject = new BehaviorSubject<MediaUsageType[]>([]);
  private readonly membershipProductsSubject = new BehaviorSubject<Product[]>([]);
  private readonly paymentTypesSubject = new BehaviorSubject<PaymentType[]>([]);
  private readonly paymentTermsSubject = new BehaviorSubject<PaymentTerm[]>([]);
  private readonly clubFacilityTypeSubject = new BehaviorSubject<ClubFacilityType[]>([]);
  private readonly clubFacilitiesSubject = new BehaviorSubject<ClubFacility[]>([]);
  private readonly bookingTypeSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly bookingSubTypeSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly selectableBookingTypeSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly parentBookingTypesWithSubtypesSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly configLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly currentSiteSubject = new BehaviorSubject<Site>(null);
  private readonly newsTypesSubject = new BehaviorSubject<NewsType[]>([]);
  private readonly documentTypesSubject = new BehaviorSubject<DocumentType[]>([]);
  private readonly cardTypeSubject = new BehaviorSubject<CardType[]>([]);
  private readonly licenceGroupListSubject = new BehaviorSubject<LicenceGroup[]>([]);
  private readonly userStatusesSubject = new BehaviorSubject<UserStatus[]>([]);
  private readonly userRolesSubject = new BehaviorSubject<UserRole[]>([]);
  private readonly defaultBookingTypesSubject = new BehaviorSubject<DefaultBookingType[]>([]);
  private readonly squashLevelPlayerURLSubject = new BehaviorSubject<string>('');

  public standardUsers = this.standardUsersSubject.asObservable();
  public userRoleList = this.userRoleListSubject.asObservable();
  public organisationTypesList = this.organisationTypesListSubject.asObservable();
  public interactionTypeList = this.interactionTypeListSubject.asObservable();
  public unitaryAuthorityList = this.unitaryAuthorityListSubject.asObservable();
  public activityList = this.activityListSubject.asObservable();
  public qualificationList = this.qualificationListSubject.asObservable();
  public facilityList = this.facilityListSubject.asObservable();
  public modalFreeze = this.modalFreezeSubject.asObservable();
  public titlesList = this.titlesSubject.asObservable();
  public genderList = this.gendersSubject.asObservable();
  public membershipSourceList = this.membershipSourceSubject.asObservable();
  public commsPrefList = this.commsPrefsSubject.asObservable();
  public interestList = this.interestsSubject.asObservable();
  public mediaUsageTypeList = this.mediaUsageTypesSubject.asObservable();
  public membershipProductList = this.membershipProductsSubject.asObservable();
  public paymentTypeList = this.paymentTypesSubject.asObservable();
  public paymentTermList = this.paymentTermsSubject.asObservable();
  public clubFacilityTypeList = this.clubFacilityTypeSubject.asObservable();
  public clubFacilitiesList = this.clubFacilitiesSubject.asObservable();
  public bookingTypeList = this.bookingTypeSubject.asObservable();
  public bookingSubTypeList = this.bookingSubTypeSubject.asObservable();
  public selectableBookingTypeList = this.selectableBookingTypeSubject.asObservable();
  public parentBookingTypesWithSubtypesList =
    this.parentBookingTypesWithSubtypesSubject.asObservable();
  public cardTypeList = this.cardTypeSubject.asObservable();
  public configLoaded = this.configLoadedSubject.asObservable();
  public currentSite = this.currentSiteSubject.asObservable();
  public newsTypeList = this.newsTypesSubject.asObservable();
  public documentTypeList = this.documentTypesSubject.asObservable();
  public licenceGroupList = this.licenceGroupListSubject.asObservable();
  public userStatuses = this.userStatusesSubject.asObservable();
  public userRoles = this.userRolesSubject.asObservable();
  public defaultBookingTypes = this.defaultBookingTypesSubject.asObservable();
  public squashLevelPlayerURL = this.squashLevelPlayerURLSubject.asObservable();

  constructor(private readonly http: HttpClient) { }

  public setSquashLevelPlayerURL(squashPlayerURL: string): void {
    this.squashLevelPlayerURLSubject.next(squashPlayerURL);
  }

  public setStandardUsers(users: User[]): void {
    this.standardUsersSubject.next(users);
  }

  public setRolesList(roles: Roles): void {
    this.userRoleListSubject.next(roles);
  }

  public setOrganisationTypesList(types: OrganisationType[]): void {
    this.organisationTypesListSubject.next(types);
  }

  public setInteractionTypeList(types: InteractionType[]): void {
    this.interactionTypeListSubject.next(types);
  }

  public setUnitaryAuthorityList(list: UnitaryAuthority[]): void {
    this.unitaryAuthorityListSubject.next(list);
  }

  public setActivityList(list: ActivityData[]): void {
    this.activityListSubject.next(list);
  }

  public setQualificationList(list: ActivityData[]): void {
    this.qualificationListSubject.next(list);
  }

  public setFacilityList(list: Facility[]): void {
    this.facilityListSubject.next(list);
  }

  public setTitlesList(list: string[]): void {
    this.titlesSubject.next(list);
  }

  public setGenderList(list: string[]): void {
    this.gendersSubject.next(list);
  }

  public setMembershipSourceList(list: MembershipSource[]): void {
    this.membershipSourceSubject.next(list);
  }

  public setCommsPrefList(list: CommsPrefType[]): void {
    this.commsPrefsSubject.next(list);
  }

  public setInterestList(list: Interest[]): void {
    this.interestsSubject.next(list);
  }

  public setMediaUsageTypeList(list: MediaUsageType[]): void {
    this.mediaUsageTypesSubject.next(list);
  }

  public setMembershipProductList(list: Product[]): void {
    this.membershipProductsSubject.next(list);
  }

  public setPaymentTypeList(list: PaymentType[]): void {
    const noPaymentType: PaymentType = {
      id: null,
      name: 'N/A',
      tag: 'NA',
    };
    list.push(noPaymentType);
    this.paymentTypesSubject.next(list);
  }

  public setPaymentTermList(list: PaymentTerm[]): void {
    this.paymentTermsSubject.next(list);
  }

  public setClubFacilityTypeList(list: ClubFacilityType[]): void {
    this.clubFacilityTypeSubject.next(list);
  }

  public setClubFacilitiesList(list: ClubFacility[]): void {
    this.clubFacilitiesSubject.next(list);
  }

  public setBookingTypeList(list: BookingType[]): void {
    this.bookingTypeSubject.next(list);
  }

  public setSelectableBookingTypeList(list: BookingType[]): void {
    this.selectableBookingTypeSubject.next(list);
  }

  public setParentBookingTypesWithSubtypesList(list: BookingType[]): void {
    this.parentBookingTypesWithSubtypesSubject.next(list);
  }

  public setCardTypeList(list: CardType[]): void {
    this.cardTypeSubject.next(list);
  }

  public setBookingSubTypeList(list: BookingType[]): void {
    this.bookingSubTypeSubject.next(list);
  }

  public setLicenceGroupList(list: LicenceGroup[]): void {
    this.licenceGroupListSubject.next(list);
  }

  public setCurrentSite(site: Site): void {
    this.currentSiteSubject.next(site);
  }

  public freezeModal(frozen: boolean): void {
    this.modalFreezeSubject.next(frozen);
  }

  public getDashboardConfig(): Observable<GenericAPIResponse<ConfigResponse>> {
    const url = `${environment.apiURL}/admin/dashboard-state/config`;
    return this.http.get<GenericAPIResponse<ConfigResponse>>(url);
  }

  public setConfigLoaded(val: boolean): void {
    this.configLoadedSubject.next(val);
  }

  public setNewsTypeList(newsTypes: NewsType[]): void {
    this.newsTypesSubject.next(newsTypes);
  }

  public setDocumentTypeList(documentTypes: DocumentType[]): void {
    this.documentTypesSubject.next(documentTypes);
  }

  public setUserStatuses(userStatuses: UserStatus[]): void {
    this.userStatusesSubject.next(userStatuses);
  }

  public setUserRoles(userRoles: UserRole[]): void {
    this.userRolesSubject.next(userRoles);
  }

  public setDefaultBookingTypes(defaultBookingTypes: DefaultBookingType[]): void {
    this.defaultBookingTypesSubject.next(defaultBookingTypes);
  }
}

export type Roles = {
  organisations: Role[];
  events: Role[];
  projects: Role[];
};

export type ConfigResponse = {
  standardUsers: User[];
  roles: Roles;
  organisationTypes: OrganisationType[];
  interactionTypes: InteractionType[];
  unitaryAuthorities: UnitaryAuthority[];
  activities: ActivityData[];
  qualifications: ActivityData[];
  facilities: Facility[];
  titles: string[];
  genders: string[];
  membershipSources: MembershipSource[];
  commsPrefTypes: CommsPrefType[];
  interests: Interest[];
  mediaUsageTypes: MediaUsageType[];
  membershipProducts: Product[];
  paymentTypes: PaymentType[];
  paymentTerms: PaymentTerm[];
  clubFacilityTypes: ClubFacilityType[];
  clubFacilities: ClubFacility[];
  bookingTypes: BookingType[];
  selectableBookingTypes: BookingType[];
  parentBookingTypesWithSubtypes: BookingType[];
  bookingSubTypes: BookingType[];
  site: Site;
  newsTypes: NewsType[];
  documentTypes: DocumentType[];
  memberCardTypes: CardType[];
  licenceGroups: LicenceGroup[];
  userStatuses: UserStatus[];
  userRoles: UserRole[];
  defaultBookingTypes: DefaultBookingType[];
  squashLevelPlayerURL: string;
};

export type MembershipSource = {
  membershipSourceId: number;
  membershipSourceLabel: string;
  membershipSourceTag: string;
};

export type CommsPrefType = {
  communicationTypeId: number;
  communicationTypeTag: string;
  communicationTypeLabel: string;
};

export type Interest = {
  interestId: number;
  interestLabel: string;
};

export type MediaUsageType = {
  mediaUsageTypeId: number;
  mediaUsageTypeTag: string;
  mediaUsageTypeLabel: string;
};

export type Product = {
  maxLinkedMembers: number;
  name: string;
  productId: number;
  productTag: string;
  productTypeId: number;
  siteId: number;
  standardPrice: number;
  statusId: number;
  canLogin: number;
  voucherEligible?: number;
};

export type PaymentType = {
  id: number;
  name: string;
  tag: string;
};

export type PaymentTerm = {
  id: number;
  name: string;
  tag: string;
  siteId: number;
};

export type ClubFacility = {
  id: number;
  typeId: number;
  label: string;
  siteId: number;
};

export type ClubFacilityType = {
  groupLabel: string;
  groupTag: string;
  id: number;
  typeTag: string;
};

export type LicenceGroup = {
  groupId: string;
  groupName: string;
};

export type UserStatus = {
  statusId: number;
  statusTag: string;
  label: string;
  canLogin: number;
};

export type DefaultBookingType = {
  id: number;
  facilityTypeId: number;
  bookingTypeId: number;
  siteId: number;
  facilityType: ClubFacilityType;
  bookingType: BookingType;
};
