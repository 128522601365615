import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericAPIResponse } from '@shared/definitions';
import { environment } from '@shared/environment';
import { HttpParamsService } from '@shared/services/http-params.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly httpSkip: HttpClient;
  public goCardlessRedirectUrl: string;
  constructor(
    private readonly http: HttpClient,
    private readonly httpParamsService: HttpParamsService,
    private readonly handler: HttpBackend
  ) {
    // httpSkip provides a HttpClient instance that skips http interceptors - doesn't try to append JWT
    this.httpSkip = new HttpClient(handler);
  }

  public createPayment(userId: number, productId: number, siteId: string, period: string, paymentMethod: number, responseTag: string):
   Observable<GenericAPIResponse<any>> {
    const url = `${environment.apiURL}/payments/stripe/initial-reg-payment`;
    const headers: { siteId?: string } = {
      siteId
    };

    return this.httpSkip.post<GenericAPIResponse<any>>(url, { userId, productId, period, paymentMethod, responseTag }, {headers});
  }

  public getProduct(productId, siteId: string): Observable<GenericAPIResponse<SignUpProduct>> {
    const url = `${environment.apiURL}/signup/product/${productId}`;
    const headers: { siteId?: string } = {
      siteId
    };

    return this.httpSkip.get<GenericAPIResponse<SignUpProduct>>(url,{headers});
  }

  public updatePaymentMethod(userId: number): Observable<GenericAPIResponse<any>> {
    const url = `${environment.apiURL}/payments/stripe/update-payment-method`;
    return this.httpSkip.post<GenericAPIResponse<any>>(url, { userId });
  }

  public savePaymentMethod(payload: CardSetupType): Observable<GenericAPIResponse<any>> {
    const url = `${environment.apiURL}/payments/stripe/save-payment-method`;
    return this.httpSkip.post<GenericAPIResponse<any>>(url, payload);
  }

  public initDebitMandate(payload): Observable<GenericAPIResponse<CreateMandate>> {
    const url = `${environment.apiURL}/payments/go-cardless/create-redirect-flow`;
    return this.httpSkip.post<GenericAPIResponse<CreateMandate>>(url, payload);
  }

  public completeDebitMandate(
    mandateId: string,
    siteId: number,
    period: string,
    responseTag: string
  ): Observable<GenericAPIResponse<CompleteMandate>> {
    const url = `${environment.apiURL}/payments/go-cardless/complete-redirect-flow`;
    return this.httpSkip.post<GenericAPIResponse<CompleteMandate>>(url, {
      mandateId,
      siteId,
      period,
    });
  }

  public getBankingDetails(siteId): Observable<GenericAPIResponse<BACSDetails>> {
    const url = `${environment.apiURL}/payments/bacs-details/${siteId}`;
    return this.httpSkip.get<GenericAPIResponse<BACSDetails>>(url);
  }

  public setGoCardlessUrl(value: string): void {
    localStorage.setItem('redirect-url', value);
    this.goCardlessRedirectUrl = value;
  }

  public getGoCardlessUrl(): string {
    localStorage.getItem('redirect-url');
    this.goCardlessRedirectUrl = localStorage.getItem('redirect-url');
    return this.goCardlessRedirectUrl;
  }

  public getStripeKey(siteId): Observable<GenericAPIResponse<BACSDetails>> {
    const url = `${environment.apiURL}/payments/stripe-key/${siteId}`;
    return this.httpSkip.get<GenericAPIResponse<BACSDetails>>(url);
  }
}

export type CardSetupType = {
  userId: number;
  paymentMethod: string;
};

export type SignUpProduct = {
  name?: string;
  yearlyPrice?: number;
  monthlyPrice?: number;
  dueToday?: number;
  prorataPrice?: number;
  joiningFee?: number;
  membershipExpiryDate?:string;
  monthlyPriceId?: number;
  yearlyPriceId?: number;
};

export type CreateMandate = {
  redirect_url: string;
};

export type CompleteMandate = {
  id: string;
};

export type BACSDetails = {
  accountName: string;
  accountNumber: number;
  sortCode: string;
  reference: string;
};
