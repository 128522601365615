<app-alert [loginScreen]="true"></app-alert>
<header>
  <div class="logo">
    <a href="#" class="logo-container">
      <img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>
</header>

<main class="login-container">
  <section id="sportinsightHomepage" class="login-form">
    <app-login-template>
      <div heading>Site selection</div>
      <div content>
        <app-sportinsight-homepage
          [sites]="this.availableSites"
          (selectedSite)="onSiteSelected($event)"
        ></app-sportinsight-homepage>
      </div>
    </app-login-template>
  </section>
</main>
