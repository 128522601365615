import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/AuthGuard';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SportinsightHomepageComponent } from './components/login/sportinsight-homepage/sportinsight-homepage.component';
import { LoginTemplateComponent } from './components/login/login-template/login-template.component';
import { ResetPasswordPromptComponent } from './components/login/reset-password-prompt/reset-password-prompt.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { TokenInterceptorService } from '@sportinsight-services/token-interceptor.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { TwoFactorAuthenticationComponent } from './components/login/two-factor-authentication/two-factor-authentication.component';
import { TwoFactorAuthenticationSetupComponent } from './components/login/two-factor-authentication/two-factor-authentication-setup/two-factor-authentication-setup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { SetupLoginComponent } from './components/setup-login/setup-login.component';
import { SiteGuard } from './guards/SiteGuard';
import { LoginGuard } from './guards/LoginGuard';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { FormStylingComponent } from './components/style-guide/form-styling/form-styling.component';
import { ButtonStylingComponent } from './components/style-guide/button-styling/button-styling.component';
import { TypographyStylingComponent } from './components/style-guide/typography-styling/typography-styling.component';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './services/globalErrorHandler';
import { TestComponent } from './components/test/test.component';
import { MaintenanceInterceptorService } from '@sportinsight-services/maintenance-interceptor.service';
import { Router } from '@angular/router';
import { SignUpComponent } from './components/signup/signup.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import * as Sentry from '@sentry/angular-ivy';
import { AcceptBookingTransferComponent } from './components/accept-booking-transfer/accept-booking-transfer.component';
import { OpenInAppComponent } from './components/open-in-app/open-in-app.component';

export function getToken(): string {
  return localStorage.getItem('JWT');
}

@NgModule({
  declarations: [
    AppComponent,
    SportinsightHomepageComponent,
    LoginTemplateComponent,
    ResetPasswordPromptComponent,
    LoginFormComponent,
    LoginComponent,
    TwoFactorAuthenticationComponent,
    TwoFactorAuthenticationSetupComponent,
    HomepageComponent,
    ResetPasswordComponent,
    SetupLoginComponent,
    StyleGuideComponent,
    FormStylingComponent,
    ButtonStylingComponent,
    TypographyStylingComponent,
    TestComponent,
    SignUpComponent,
    AcceptBookingTransferComponent,
    OpenInAppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    MatInputModule,
    MatExpansionModule,
    NgxQRCodeModule,
    ClipboardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      },
    }),
    NgxGoogleAnalyticsModule.forRoot('G-H0LTEGV4N8'),
    NgxGoogleAnalyticsRouterModule.forRoot({
      include: [],
      exclude: [],
    }),
  ],
  providers: [
    AuthGuard,
    SiteGuard,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptorService,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
