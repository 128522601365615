import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '@shared/definitions';
import {
  AttemptLoginParams,
  AuthService,
  LoginProperties,
} from '@sportinsight-services/auth.service';
import { AlertService } from '@shared/services/alert.service';
import { finalize, Subject } from 'rxjs';
import { SiteService } from '@shared/services/site.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public loading = false;
  public loggedIn = false;
  public availableSites: Site[];
  public loginFunction: LoginFunctions = 'login';
  public loginCredentials: AttemptLoginParams;
  private token: string;
  public fillEmailInput: Subject<string> = new Subject<string>();
  public siteUrlTag: string;
  public returnUrl: string;
  public siteLogoURL: string;

  constructor(
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'homepage';

    if (this.router.url.includes('/reset-password-request')) {
      this.loginFunction = 'resetPasswordPrompt';
    } else if (this.router.url !== '/login') {
      this.authService.checkURLValidity(this.router.url).subscribe({
        next: (response) => {
          if (response.data.siteId) {
            localStorage.setItem('siteId', response.data.siteId.toString());
            this.siteUrlTag = response.data.urlTag;
            this.siteLogoURL = response.data.siteLogoURL;
          } else {
            this.router.navigateByUrl('login');
          }
        },
      });
    }

    if (this.route.snapshot.paramMap.get('token')) {
      this.token = this.route.snapshot.paramMap.get('token');
      this.verifyEmail();
    }

    if (this.route.snapshot.url[0].path === 'homepage') this.loggedIn = true;
    if (this.route.snapshot.url[0].path === 'reset-password') this.loginFunction = 'resetPassword';
  }

  public switchLoginFunction(loginFunction: LoginFunctions): void {
    this.loginFunction = loginFunction;
  }

  public continueToHomepage(): void {
    if (this.returnUrl !== 'homepage') {
      this.router.navigateByUrl(this.returnUrl);
    } else if (this.availableSites.length === 1) {
      // if only one site
      this.onSiteSelected(this.availableSites[0]);
    }else if (this.preSelectedSite()) {
      // if navigating to pre defined site
      const siteId = +localStorage.getItem('siteId');
      this.onSiteSelected(this.availableSites.find((site) => site.siteId === siteId));
    } else {
      this.router.navigateByUrl('homepage');
  }
  }

  private preSelectedSite(): boolean {
    const siteId = +localStorage.getItem('siteId');
    return this.availableSites.some((site) => site.siteId === siteId);
  }

  public login(event: {
    loginProperties: LoginProperties;
    loginCredentials: AttemptLoginParams;
  }): void {
    this.loading = true;
    localStorage.setItem('JWT', event.loginProperties.token);

    this.availableSites = event.loginProperties.sites;
    localStorage.setItem('availableSites', JSON.stringify(event.loginProperties.sites));

    this.continueToHomepage();
  }

  private verifyEmail(): void {
    this.loading = true;

    this.authService
      .verifyEmail(this.token)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          this.alertService.emitSuccessAlert('Email verified');
          this.fillEmailInput.next(response.data.email);
        },
        error: (error) => {
          this.alertService.emitErrorAlert(error.error?.message);
        },
      });
  }

  public toggleTwoFA(loginCredentials: AttemptLoginParams): void {
    this.loginCredentials = loginCredentials;
    this.switchLoginFunction('2FA');
  }

  public onSiteSelected(site: Site): void {
    this.siteService.changeSite(site);
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }
}

type LoginFunctions = 'login' | '2FA' | 'resetPasswordPrompt' | 'resetPassword';
