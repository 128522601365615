import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@shared/environment';
import { AlertService } from '@shared/services/alert.service';
import { UserService } from '@shared/services/user.service';
import { catchError, delay, finalize, Observable, tap, throwError } from 'rxjs';
import { AuthService, BYPASS_TOKEN_CHECK } from './auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private ongoingJWTRefresh: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly alertService: AlertService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.jwtToken;
    const tokenValid = this.authService.isAuthenticated().isAuthenticated;

    /**
     * Below checks that we are actually trying to access the API
     * Uses second index as url will always be in the format
     * http://{url} or https://{url}
     */
    const requestUrl = request.url.split('/')[2];
    const apiUrl = environment.apiURL.split('/')[2];
    const isRequestToAPI = requestUrl === apiUrl;

    if (request.url.includes('maintenance/check')) {
      return next.handle(request);
    } else if (request.context.get(BYPASS_TOKEN_CHECK)) {
      return next.handle(request);
    }

    // return;
    const headers: { Authorization: string; siteId?: string } = {
      Authorization: `Bearer ${token}`,
    };

    const siteId = localStorage.getItem('siteId');

    if (siteId) headers.siteId = siteId;

    if (tokenValid && isRequestToAPI) {
      request = request.clone({ setHeaders: headers });
    } else if (!tokenValid) {
      if (this.ongoingJWTRefresh) {
        window.location.reload();
        return;
      }
      this.router.navigateByUrl('login');
    }

    return next.handle(request).pipe(
      finalize(() => {
        // Refresh JWT if request made and TTL needs updating
        if (this.authService.refreshJWTRequired && !this.ongoingJWTRefresh) {
          this.ongoingJWTRefresh = true;
          this.authService
            .refreshJWT(headers)
            .then(() => setTimeout(() => (this.ongoingJWTRefresh = false), 1000 * 1)); // 1 second delay to reduce race case
        }
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401) {
          // If JWT is being refreshed then refresh window to overcome race case
          if (this.ongoingJWTRefresh) {
            window.location.reload();
            return;
          }

          this.alertService.emitErrorAlert(
            e.error.message ?? 'Unauthenticated - you will be logged out'
          );

          this.authService.logout();
        }

        return throwError(() => new Error(e.error.message));
      })
    );
  }
}
