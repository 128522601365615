<app-alert [loginScreen]="true"></app-alert>
<header>
  <div class="logo">
    <a href="#" class="logo-container">
      <img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>
</header>

<main class="login-container">
  <section id="sportinsightHomepage" class="login-form">
    <!-- If token expired, show resend reset password email button -->
    <ng-template #tokenExpiredNotifier>
      <ng-template #loginSpinner>
        <div class="content-container">
          <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
          <span class="light-tag">Loading ...</span>
        </div>
      </ng-template>
      <div class="login-template-container" *ngIf="!loading; else loginSpinner">
        <div class="heading-container black">
          <img src="assets/images/icons/icon-pad-white.svg" alt="Login" />
          <h2>Token expired</h2>
          <div class="clear"></div>
        </div>
        <ng-template #emailSentMessage>
          <div class="content-container whitebg">
            <p>
              If your email is registered with SportInsight we have sent you a reset password link.
            </p>
          </div>
        </ng-template>
        <div
          *ngIf="!emailSent; else emailSentMessage"
          class="content-container whitebg reset-password-container"
        >
          <p>The reset password link has expired, please request a new link.</p>
          <a [routerLink]="['/reset-password-request']" class="btn black">Reset Password</a>
        </div>
      </div>
    </ng-template>

    <!-- Password reset form -->
    <ng-container *ngIf="!tokenExpired; else tokenExpiredNotifier">
      <ng-template #loginSpinner>
        <div class="content-container">
          <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
          <span class="light-tag">Loading ...</span>
        </div>
      </ng-template>

      <app-login-template *ngIf="!loading; else loginSpinner">
        <div heading>Change your password</div>
        <div content>
          <div class="reset-password-container">
            <span
              *ngIf="alert.type?.length"
              class="alert animated fadeIn"
              [class.success]="alert.type === 'success'"
              [class.error]="alert.type === 'error'"
              >{{ alert.text }}</span
            >
            <p>
              <span *ngIf="currentPasswordWeak"
                ><span class="reset-password-sub-heading"
                  >We've detected that you have a weak password, </span
                >please reset using the following guidelines:</span
              >
              <span *ngIf="!currentPasswordWeak"
                ><span class="reset-password-sub-heading"
                  >Please enter new password details below, </span
                >using the following guidelines:</span
              >
              <br /><br />

              It must be at least 8 characters and contain one lower case letter (a-z), one upper
              case letter (A-Z) and one number (0-9).
            </p>

            <form [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
              <fieldset>
                <div class="form-group password-strength-container password-input">
                  <label>New Password:</label>
                  <input
                    type="password"
                    class="form-control"
                    autocomplete="new-password"
                    formControlName="password"
                    [class.error]="displayErrors && form.get('password').invalid"
                  />
                  <span *ngIf="passwordLength > 0" class="password-strength-text">
                    Password strength:
                    <span class="password-strength-rating" [class]="passwordStrength('class')">
                      {{ passwordStrength("text") }}
                    </span>
                  </span>
                  <div class="clear"></div>
                  <span class="error-msg on" *ngIf="displayErrors && form.get('password').invalid">
                    Please enter a valid password.
                  </span>
                </div>
                <ul class="password-validators">
                  <li class="inactive" [ngClass]="{ active: passwordLength >= 8 }">
                    - At least 8 characters long.
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasLowercase }">
                    - One or more lower case letters (a-z).
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasUppercase }">
                    - One or more upper case letters (A-Z).
                  </li>
                  <li class="inactive" [ngClass]="{ active: passwordHasNumber }">
                    - One or more numbers (0-9).
                  </li>
                </ul>
                <div class="clear"></div>
                <div class="form-group">
                  <label>Confirm New Password:</label>
                  <input
                    type="password"
                    class="form-control"
                    autocomplete="new-password"
                    formControlName="passwordConfirmation"
                    [class.error]="displayErrors && form.get('passwordConfirmation').invalid"
                  />

                  <div class="clear"></div>
                  <span
                    class="error-msg on"
                    *ngIf="displayErrors && form.get('passwordConfirmation').invalid"
                  >
                    These passwords do not match - please try again
                  </span>
                </div>
                <div class="clear"></div>

                <button class="btn" type="submit">
                  {{ useCase === "reset" ? "Reset" : "Confirm" }} Password
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </app-login-template>
    </ng-container>
  </section>
</main>
