import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { AuthService } from '@sportinsight-services/auth.service';
import { Site } from '../sub-projects/shared/definitions';

@Injectable()
export class LoginGuard implements CanActivate {
  private availableSites: Site[];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService
  ) {}

  /**
   * Checks the user can activate this route
   *
   * @param next - next guard (if any)
   * @param state - data passed into route (if any)
   *
   * @return boolean
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const authenticated = this.authService.isAuthenticated();

    // if authenticated (logged in) then redirect else login form. Saved available sites also required as they should be set on loggin.
    if (authenticated.isAuthenticated && localStorage.getItem('availableSites')) {
      // get parent path array. e.g. ['lansdown', 'admin']
      const fullPathFromRoot = route.pathFromRoot.reduce<string[]>(
        (arr, item) => [...arr, ...item.url.map((segment) => segment.path)],
        []
      );

      // redirect to the admin/member homepage, other guards handle other nav/validation
      if (fullPathFromRoot[1] === 'admin') {
        this.router.navigateByUrl(`${fullPathFromRoot[0]}/admin/dashboard`);
      } else {
        this.router.navigateByUrl(`${fullPathFromRoot[0]}/member/home`);
      }
    } else {
      localStorage.clear();
      return true;
    }
  }
}
