import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { AuthService } from '@sportinsight-services/auth.service';
import { Site } from '../sub-projects/shared/definitions';
import { SiteService } from '../sub-projects/shared/services/site.service';
@Injectable()
export class SiteGuard implements CanActivate {
  private availableSites: Site[];
  private baseUrl = '';

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
  ) { }

  /**
   * Checks the user can activate this route
   *
   * @param next - next guard (if any)
   * @param state - data passed into route (if any)
   *
   * @return boolean
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authenticated = this.authService.isAuthenticated();
    const returnUrl = window.location.href;

    // if no selected site then navigate to SI homepage (if logged out '/homepage' guard will logout)
    if (authenticated && !localStorage.getItem('siteId')) {
      this.router.navigateByUrl('homepage');
      return false;
    }

    // get parent path array. e.g. ['lansdown', 'admin']
    const fullPathFromRoot = route.pathFromRoot.reduce<string[]>(
      (arr, item) => [...arr, ...item.url.map((segment) => segment.path)],
      []
    );

    // define parent/base route and save to local storage to be used elsewhere for naigation.
    this.baseUrl = `${fullPathFromRoot[0]}/${fullPathFromRoot[1]}`;
    localStorage.setItem('baseUrl', this.baseUrl);


    // get all available sites for user (set on login)
    this.availableSites = JSON.parse(localStorage.getItem('availableSites'));
    // if the parent path is a valid route to a site the user has access to else nav to SI login, which will handle further nav/guarding
    if (
      this.availableSites &&
      this.availableSites.find((site) => site.urlTag === fullPathFromRoot[0])
    ) {
      // set site being accessed as 'siteId' in local storage
      localStorage.setItem(
        'siteId',
        this.availableSites
          .find(
            (site) =>
              site.urlTag === fullPathFromRoot[0] &&
              site.type.siteTypeTag.toLowerCase() === fullPathFromRoot[1]
          )
          .siteId.toString()
      );

      // if user authenticated (trying to enter valid site, as already checked) pass guard
      if (authenticated.isAuthenticated) {
        return true;
      }
    }
    // if session timed out, e.g. not manual log out, then emit alert
    if (authenticated.sessionTimeout) {
      if (!returnUrl.includes('returnUrl')) {
        this.alertService.emitErrorAlert('Your session has timed out. Please log in again.');
      }
    }
    localStorage.clear();
    this.router.navigate([`${this.baseUrl}/login`], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
