<ng-template #loginSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="container" *ngIf="!loading; else loginSpinner">
  <form *ngIf="form" [formGroup]="form">
    <fieldset class="fieldset">
      <div class="form-group">
        <!-- First Name -->
        <label>First name<span>*</span>:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="firstName"
            placeholder="Your first name"
            [class.error]="displayErrors && form.get('firstName').invalid"
            [maxlength]="50"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('firstName').invalid">
            A first name is required
          </span>
        </div>
      </div>

      <!-- Last Name -->
      <div class="form-group">
        <label>Last name<span>*</span>:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="lastName"
            placeholder="Your last name"
            [class.error]="displayErrors && form.get('lastName').invalid"
            [maxlength]="50"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('lastName').invalid">
            A last name is required.
          </span>
        </div>
      </div>

      <!-- Email -->
      <div class="form-group">
        <label>Email<span>*</span>:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="workEmail"
            placeholder="Email"
            [class.error]="displayErrors && form.get('workEmail').invalid"
            [maxlength]="100"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('workEmail').invalid">
            Please enter a valid email address.
          </span>
        </div>
      </div>

      <!-- Date of Birth -->
      <div class="form-group dateOfBirth">
        <label>Date of Birth<span>*</span>:</label>
        <div class="input-container">
          <app-datepicker
            formControlName="dateOfBirth"
            class="inline-edit"
            [allowTyping]="true"
            [class.error]="displayErrors && form.get('dateOfBirth').invalid"
            [canClear]="this.form.controls['dateOfBirth'].value"
            [datepickerError]="displayErrors && form.get('dateOfBirth').invalid"
            [required]="true"
          ></app-datepicker>
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('dateOfBirth').invalid">
            Please enter a valid date of birth.
          </span>
        </div>
      </div>

      <!-- Contact Number -->
      <div class="form-group">
        <label>Contact no<span>*</span>:</label>
        <div class="input-container">
          <input
            type="tel"
            class="form-control input"
            formControlName="number"
            placeholder="Contact number"
            [class.error]="displayErrors && form.get('number').invalid"
            [maxlength]="20"
            (input)="onInputChange($event)"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('number').invalid">
            A contact number is required.
          </span>
        </div>
      </div>

      <!-- Address -->
      <div class="form-group addressSearch" [ngClass]="{ displayNone: displayFullAddressForm }">
        <label>Address:</label>
        <div class="input-container">
          <!-- Special character in the placeholder will not display in the front end, but it will stop the browser trying to autocomplete this input -->
          <input
            #suggestionsInput
            type="text"
            class="form-control input suggestionsInput"
            formControlName="addressSearch"
            placeholder="Start typing your Addr&zwnj;ess"
            autocomplete="off"
            [class.error]="displayErrors && form.get('addressSearch').invalid"
            (onKey)="hasSelectedAddress = false"
            [maxlength]="200"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('addressSearch').invalid">
            Please select an address.
          </span>
        </div>
      </div>

      <div
        class="suggestionContainer"
        *ngIf="
          ((addressSuggestions || fetchingSuggestions) && displayAddressSuggestions)
        "
        #suggestionsContainer
      >
        <div class="address-suggestions">
          <ul>
            <li
              *ngFor="let suggestion of addressSuggestions"
              (click)="getFullAddressSuggestion(suggestion.id)"
              class="addressSuggesstion"
            >
              {{ suggestion.address }}
            </li>
            <li *ngIf="fetchingSuggestions">
              <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
            </li>
          </ul>
        </div>
      </div>

      <!-- Full address form, only shown when auto lookup not working -->
      <div class="fieldset" [ngClass]="{ displayNone: !displayFullAddressForm }">
        <div class="form-group">
          <label>First Address line:</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="formatted_address_0"
              formControlName="addressLine1"
              placeholder="Address line 1"
              [class.error]="displayErrors && form.get('addressLine1').invalid"
            />
            <div class="clear"></div>
            <span class="error-msg on" *ngIf="displayErrors && form.get('addressLine1').invalid">
              Please enter a valid address.
            </span>
          </div>
        </div>

        <div class="form-group">
          <label>Second Address line:</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="formatted_address_1 line_1"
              formControlName="addressLine2"
              placeholder="Address line 2"
              [class.error]="displayErrors && form.get('addressLine2').invalid"
              [maxlength]="120"
            />
            <div class="clear"></div>
          </div>
        </div>

        <div class="form-group">
          <label>Third Address line:</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="formatted_address_2 line_2"
              formControlName="addressLine3"
              placeholder="Address line 3"
              [maxlength]="120"
            />
            <div class="clear"></div>
          </div>
        </div>

        <div class="form-group">
          <label>Fourth Address line:</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="formatted_address_3 line_3"
              formControlName="addressLine4"
              placeholder="Address line 4"
              [maxlength]="120"
            />
            <div class="clear"></div>
          </div>
        </div>

        <div class="form-group">
          <label>Town</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="town_or_city"
              formControlName="city"
              placeholder="Town/City"
              [class.error]="displayErrors && form.get('city').invalid"
              [maxlength]="100"
            />
            <div class="clear"></div>
            <span class="error-msg on" *ngIf="displayErrors && form.get('city').invalid">
              Please enter a town.
            </span>
          </div>
        </div>

        <div class="form-group">
          <label>Country</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="country"
              formControlName="country"
              placeholder="Country"
              [class.error]="displayErrors && form.get('country').invalid"
              [maxlength]="100"
            />
            <div class="clear"></div>
            <span class="error-msg on" *ngIf="displayErrors && form.get('country').invalid">
              Please enter a country.
            </span>
          </div>
        </div>

        <div class="form-group">
          <label>Postcode</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control input"
              id="postcode"
              formControlName="postcode"
              placeholder="Postcode"
              [class.error]="displayErrors && form.get('postcode').invalid"
              [maxlength]="8"
            />
            <div class="clear"></div>
            <span class="error-msg on" *ngIf="displayErrors && form.get('postcode').invalid">
              Please enter a valid postcode.
            </span>
          </div>
        </div>
      </div>

      <div class="mandatory-label">
        <p >*Mandatory fields</p>
      </div>
    </fieldset>
  </form>
</div>

<app-confirmation-modal
  *ngIf="displayConfirmationModal"
  (closeModalEmitter)="displayConfirmationModal = false"
  (confirmEmitter)="confirmationModalConfirm()"
  [message]="confirmationMessage"
  [title]="confirmationHeader"
  [confirmationDisplaySubmit]="confirmationDisplaySubmit"
  [cancelDisplaySubmit]="cancelDisplaySubmit"
  [cancelOption]="cancelOption"
  [proceedOption]="proceedOption"
></app-confirmation-modal>
