import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';
import { AlertService } from '@shared/services/alert.service';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterContentInit,
  Renderer2
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { emailRegExp, MY_DATE_FORMATS_STANDARD_DAY,phoneNumberRegExp, textOnlyRegExp } from '@shared/definitions';
import { SignupService, ProspectiveUserAddress } from '../../../services/signup.service';
import { finalize, debounceTime } from 'rxjs';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormHelper } from '@shared/helpers/form-helper';
@Component({
  selector: 'app-signup-aboutyou',
  templateUrl: './signup-aboutyou.component.html',
  styleUrls: ['./signup-aboutyou.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS_STANDARD_DAY }],
})
export class SignUpAboutYouComponent implements OnInit, AfterContentInit {
  public loading = false;
  public form: FormGroup;
  public addressSearch;
  public addressSuggestions;
  public fetchingSuggestions: boolean = false;
  public displayAddressSuggestions: boolean = false;
  public userDetails;
  public userAddressString: string;
  public userAddress: ProspectiveUserAddress;
  public hasSelectedAddress: boolean;
  public selectedAddress;
  public addressSearchTerm: string;
  public displayFullAddressForm: boolean = false;
  public displayConfirmationModal: boolean = false;
  public confirmationHeader: string = '';
  public confirmationMessage: string = '';
  public responseTag: string;
  public confirmationDisplaySubmit: boolean = true;
  public cancelDisplaySubmit: boolean = true;
  public cancelOption ="Cancel";
  public proceedOption ="Continue";
  // Different states & outcomes for creating new users
  readonly NEW_USER = 'NEW_USER';
  readonly EXISTING_USER_NO_LOGIN = 'EXISTING_USER_NO_LOGIN';
  readonly EXISTING_LOGIN_NO_USER = 'EXISTING_LOGIN_NO_USER';
  readonly EXISTING_USER_ARCHIVED = 'EXISTING_USER_ARCHIVED';
  readonly EXISTING_USER_DIFFERENT_NAME = 'EXISTING_USER_DIFFERENT_NAME';
  readonly EXISTING_USER_ARCHIVED_NO_LOGIN = 'EXISTING_USER_ARCHIVED_NO_LOGIN';
  readonly EXISTING_PROSPECTIVE_USER = 'EXISTING_PROSPECTIVE_USER';
  readonly EXISTING_USER = 'EXISTING_USER';
  readonly EXISTING_USER_DIFFERENT_NAME_ARCHIVED = 'EXISTING_USER_DIFFERENT_NAME_ARCHIVED';
  readonly EXISTING_USER_FAMILY = "EXISTING_USER_FAMILY";

  @Input() public signupStage: number;
  @Input() public displayErrors: boolean;
  @Input() public siteId: number;
  @Output() private readonly stageEmitter = new EventEmitter<number>();
  @Output() private readonly userCreatedEmitter = new EventEmitter<number>();
  @Input() public siteUrlTag:string;
  @Input() public clubName:string;

  @ViewChild('suggestionsInput') suggestionsInput;

  constructor(
    public readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly signupService: SignupService,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.initForm();
    if (JSON.parse(sessionStorage.getItem('previouslyCreatedUser'))) {
      this.getPreviouslyCreatedMember();
    }
    document.addEventListener('click', this.offClickHandler.bind(this)); // bind on doc
  }

  ngAfterContentInit(): void {
    if (!JSON.parse(sessionStorage.getItem('previouslyCreatedUser'))) {
      this.loading = false;
    }
  }

  public checkFormValid(): boolean {
    return this.form.invalid ? false : true;
  }

  public onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/[^- +()0-9]/g, ''); // Remove non-numeric characters
    this.form.controls['number'].setValue(value)
    this.renderer.setProperty(input, 'value', value); // Update the input value
  }

  private initForm(): void {
    this.form = this.fb.group({
      workEmail: [
        this.userDetails?.workEmail ?? '',
        [Validators.required, Validators.pattern(emailRegExp)],
      ],
      firstName: [this.userDetails?.firstName ?? '', [Validators.required, Validators.pattern(textOnlyRegExp)]],
      lastName: [this.userDetails?.lastName ?? '', [Validators.required, Validators.pattern(textOnlyRegExp)]],
      number: [this.userDetails?.number ?? '', [Validators.required, Validators.pattern(phoneNumberRegExp)]],
      addressSearch: [this.userAddressString ?? '', [this.addressSearchValueValidator()]],
      dateOfBirth: [
        this.userDetails?.dateOfBirth ? new Date(this.userDetails.dateOfBirth) : null,
        Validators.required,
      ],
      addressLine1: [this.userDetails?.addressLine1 ?? ''],
      addressLine2: [this.userDetails?.addressLine2 ?? ''],
      addressLine3: [this.userDetails?.addressLine3 ?? ''],
      addressLine4: [this.userDetails?.addressLine4 ?? ''],
      postcode: [this.userDetails?.postcode ?? ''],
      country: [this.userDetails?.country ?? ''],
      city: [this.userDetails?.city ?? ''],
    });

    // Address looking box updating suggestions on input change
    this.form.get('addressSearch').valueChanges.subscribe(() => {
      if (this.form.get('addressSearch')?.value) {
        this.fetchingSuggestions = true;
        this.displayAddressSuggestions = true;
      }
    });

    this.form
      .get('addressSearch')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(() => {
        if (this.form.get('addressSearch')?.value) {
          this.fetchingSuggestions = false;
          this.autocompleteAddressLookup();
        }
      });
  }

  // When switching to full address input, set the validators for those inputs
  private setFullForm(val) {
    this.displayFullAddressForm = val;
    if (this.displayFullAddressForm) {
      this.form.controls['addressLine1'].setValidators([Validators.required]);
      this.form.controls['postcode'].setValidators([Validators.required]);
      this.form.controls['city'].setValidators([Validators.required]);
      this.form.controls['country'].setValidators([Validators.required]);
      this.form.controls['addressSearch'].clearValidators();
    } else {
      this.form.controls['addressLine1'].clearValidators();
      this.form.controls['postcode'].clearValidators();
      this.form.controls['city'].clearValidators();
      this.form.controls['country'].clearValidators();
      this.form.controls['addressSearch'].setValidators([this.addressSearchValueValidator()]);
    }
    this.form.controls['addressLine1'].updateValueAndValidity();
    this.form.controls['postcode'].updateValueAndValidity();
    this.form.controls['city'].updateValueAndValidity();
    this.form.controls['country'].updateValueAndValidity();
    this.form.controls['addressSearch'].updateValueAndValidity();
  }

  // Checks if a user has selected and auto suggested address option
  addressSearchValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.hasSelectedAddress) {
        return null;
      }
      return { hasSelectedAddress: false };
    };
  }

  // Handles clicking off of the address suggestions, closing their modal
  public offClickHandler(event: any): void {
    if (event.target.classList.contains('suggestionsInput')) {
      this.displayAddressSuggestions = true;
    } else {
      this.displayAddressSuggestions = false;
    }
  }

  // Lookup an address from user input in form, provide suggestions
  public autocompleteAddressLookup(): void {
    // Clean up address input before passing to api
    let addressSearchTerm = this.form.get('addressSearch').value.replace(/\,/g, '');
    if (!addressSearchTerm) {
      this.hasSelectedAddress = false;
      return;
    }
    if (this.addressSearchTerm === addressSearchTerm) {
      return;
    }
    this.hasSelectedAddress = false;
    this.addressSearchTerm = addressSearchTerm;
    this.signupService
      .autocompleteAddressLookup(addressSearchTerm)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          response.suggestions.length === 0 ? this.setFullForm(true) : this.setFullForm(false);
          this.addressSuggestions = response.suggestions;
        },
        error: (error) => {
          this.form.controls['addressLine1'].setValue(addressSearchTerm);
          this.setFullForm(true);
        },
      });
  }

  // After selected an address selection, get full address details and set selected address
  public getFullAddressSuggestion(addressId): void {
    this.signupService
      .getFullAddressSuggestion(addressId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.displayAddressSuggestions = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.selectedAddress = {
            addressLine1: response.line_1,
            addressLine2: response.line_2,
            addressLine3: response.line_3,
            addressLine4: response.line_4,
            postcode: response.postcode,
            city: response.town_or_city,
            country: response.country,
            county: response.country,
          };
          this.hasSelectedAddress = true;
          let displayAddress = `${response.line_1 ?? ''}, ${response.postcode.trim()}, ${
            response.town_or_city
          }`;
          this.form.controls['addressSearch'].setValue(displayAddress);
        },
      });
  }

  public createProspectiveMember(): void {
    this.loading = true;
    const params = {
      ...this.form.value,
      ...this.selectedAddress,
      address: this.selectedAddress,
      siteId: this.siteId,
    };

    sessionStorage.setItem('previouslyCreatedUser', JSON.stringify({...this.form.value}))

    this.signupService
      .createProspectiveUser(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          localStorage.setItem('prevouslyCreatedUserId', response.data.userId.toString());
          this.responseTag = response.data.tag;
          localStorage.setItem('responseTag', response.data.tag);

          switch (this.responseTag) {
            case this.EXISTING_PROSPECTIVE_USER:
              this.userCreatedEmitter.emit(response.data.userId);
              return;
            case this.EXISTING_LOGIN_NO_USER:
              this.userCreatedEmitter.emit(response.data.userId);
              return;
            case this.NEW_USER:
              this.userCreatedEmitter.emit(response.data.userId);
              return;
            case this.EXISTING_USER_ARCHIVED:
              this.userCreatedEmitter.emit(response.data.userId);
              return;
            case this.EXISTING_USER:
              this.confirmationHeader = 'Existing user';
              this.confirmationMessage =
              `Our records indicate that you already have an active membership with ${this.clubName}.  If you have forgotten your password click the reset password button below to be sent a reset link.`
              this.confirmationDisplaySubmit = true;
              this.displayConfirmationModal = true;
              this.cancelDisplaySubmit = false;
              this.proceedOption ="Reset Password";
              break;
            case this.EXISTING_USER_FAMILY:
              this.confirmationHeader = 'Existing user';
              this.confirmationMessage = `Our records indicate that you already have an active membership with ${this.clubName}.  You are unable to setup a Sport Insight account as there is already an account registered with that email address. Please contact ${this.clubName} to get your email changed so you are then able to set up an account.`
              this.confirmationDisplaySubmit = false;
              this.displayConfirmationModal = true;
              this.cancelDisplaySubmit = false;
              break;
            case this.EXISTING_USER_NO_LOGIN:
              this.proceedOption = "Send email";
              this.confirmationHeader = 'Existing user';
              this.confirmationMessage =
                `Our records indicate that you already have an active membership with the ${this.clubName}. Click Send Email below to receive an email with instructions on how to set up a Sport Insight account to access ${this.clubName} members area.`
              this.displayConfirmationModal = true;
              this.confirmationDisplaySubmit = true;
              this.cancelDisplaySubmit = false;
              this.proceedOption ="Send Email";
              break;
            case this.EXISTING_USER_DIFFERENT_NAME:
            case this.EXISTING_USER_DIFFERENT_NAME_ARCHIVED:
              this.confirmationHeader = 'Existing user';
              this.confirmationMessage = `There is already an active member at ${this.clubName} with that email address. You can continue to create a new membership but will be unable to create a Sport Insight login to make bookings.  In order to do so you will need to close this window and change the email address.`
              this.displayConfirmationModal = true;
              this.confirmationDisplaySubmit = true;
              this.cancelDisplaySubmit = false;
              this.proceedOption ="Continue";
              break;
            case this.EXISTING_USER_ARCHIVED:
              this.confirmationHeader = 'Existing user';
              this.confirmationMessage =
              `There is already a ${this.clubName} user with that email address. You can continue to create a new membership but will be unable to create a Sport Insight login to make bookings.  In order to do so you will need to close this window and change the email address.`
              this.confirmationDisplaySubmit = true;
              this.displayConfirmationModal = true;
              this.cancelDisplaySubmit = true;
              return;
            case this.EXISTING_USER_ARCHIVED_NO_LOGIN:
              this.userCreatedEmitter.emit(response.data.userId);
              return;
          }
        },
        error: (error) => this.alertService.emitErrorAlert(error.error?.message),
      });
  }


  // When clicking confirm on confirmation modal
  public confirmationModalConfirm(): void {
    this.loading = true;
    this.displayConfirmationModal = false;
    if(this.responseTag === this.EXISTING_USER_DIFFERENT_NAME || this.responseTag === this.EXISTING_USER_ARCHIVED || this.responseTag === this.EXISTING_USER_DIFFERENT_NAME_ARCHIVED){
      this.userCreatedEmitter.emit(parseInt(localStorage.getItem('prevouslyCreatedUserId')));
      return;
    }
    else if(this.responseTag === this.EXISTING_USER){
      this.router.navigate([`/reset-password-request/${this.form.get('workEmail').value}`]);
      return;
    }
    const params = {
      ...this.form.value,
      ...this.selectedAddress,
      address: this.selectedAddress,
      siteId: this.siteId,
      responseTag:this.responseTag
    };

    this.signupService
      .confirmCreateProspectiveUser(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          if(this.responseTag === this.EXISTING_USER_NO_LOGIN){
            this.alertService.emitSuccessAlert('Setup email successfully sent');
            this.router.navigate(['/login']);
          }

          this.userCreatedEmitter.emit(response.data.userId);
        },
        error: (error) => this.alertService.emitErrorAlert(error.error?.message),
      });
  }

  // Get a previously created user (for when user has gone back in form, or returned to incomplete form)
  public getPreviouslyCreatedMember(): void {
    this.loading = true;
    if (!sessionStorage.getItem('previouslyCreatedUser')) {
      return;
    }

    const previousDetails = JSON.parse(sessionStorage.getItem('previouslyCreatedUser'));

    if(previousDetails.addressLine1){
      this.userAddressString = `${previousDetails.addressLine1}, ${previousDetails.postcode}, ${previousDetails.city}`;
      this.hasSelectedAddress = true;
    }
    this.userDetails = previousDetails;
    this.initForm();
    this.loading = false;
  }

  public submitFormHandler(): void {
    this.loading = true;
    const dirtyValues = FormHelper.getDirtyValues(this.form);

    if (this.form.valid) {
      this.createProspectiveMember();
    }
  }
}
