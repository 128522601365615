import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, AfterContentInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@shared/services/alert.service';
import {
  ForgottenPasswordService,
  SendResetPasswordEmailParams,
} from '@shared/services/forgotten-password.service';
import { emailRegExp } from '@sportinsight/src/app/sub-projects/shared/definitions';
import { finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-prompt',
  templateUrl: './reset-password-prompt.component.html',
  styleUrls: ['./reset-password-prompt.component.scss'],
})
export class ResetPasswordPromptComponent implements OnInit, AfterContentInit {
  @Output() private readonly isLoadingEmitter = new EventEmitter();
  @Output() private readonly returnToLoginEmitter = new EventEmitter();
  @Input() private readonly userId: number;

  public resetPasswordForm: FormGroup;
  public displayError = false;
  public resetEmailInput = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly forgottenPasswordService: ForgottenPasswordService,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.resetEmailInput = this.route.snapshot.paramMap.get('resetemail');
    this.initForm();
  }

  ngAfterContentInit(): void {
    if(this.resetEmailInput) this.sendPasswordResetEmail();
  }

  private initForm(): void {
    this.resetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(emailRegExp)]],
    });
  }

  public sendPasswordResetEmail(): void {
    if(!this.resetEmailInput){
      this.displayError = true;

      if (this.resetPasswordForm.invalid) return;

      this.setIsLoading(true);
    }

    const params: SendResetPasswordEmailParams = {
      loginEmail: this.resetEmailInput ?? this.resetPasswordForm.get('email').value,
    };

    this.forgottenPasswordService
      .sendAdminPasswordResetEmail(params)
      .pipe(finalize(() => this.setIsLoading(false)))
      .subscribe({
        next: () => {
          this.alertService.emitSuccessAlert(
            'If you have a Sport Insight account an email will have been sent to you with instructions on how to reset your password'
          );
          this.returnToLoginForm();
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.emitErrorAlert(
            error.error?.message || 'Failed to send reset password email'
          );
        },
      });
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoadingEmitter.emit(isLoading);
  }

  public returnToLoginForm(): void {
    this.returnToLoginEmitter.emit();
  }
}
