import { SignUpAboutYouComponent } from './aboutyou-form/signup-aboutyou.component';
import { MemberOptions } from './../../services/signup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { SignupService } from '../../services/signup.service';
import { PaymentsComponent } from './payments/payments.component';
import { AlertService } from '@shared/services/alert.service';
import {
  AuthService,
} from '@sportinsight-services/auth.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignUpComponent implements OnInit {
  public signupStage: string;
  public membershipOptions: MemberOptions[];
  public selectedMembership: number = 1;
  public aboutyouFormData;
  public displayErrors = false;
  public nextDisabled = false;
  public loading = true;
  public siteId: number;
  public prospectiveMemberId: number | null;
  public signUpStages = ['membership-options', 'about-you', 'payments'];
  public paramRoute: string;
  public paymentComplete: boolean = false;
  public success = false;
  public siteUrlTag: string;
  public siteLogoURL: string;
  public clubName: string;
  public selectedMonthly: boolean = true;

  @ViewChild(SignUpAboutYouComponent) aboutYouComponent: SignUpAboutYouComponent;
  @ViewChild(PaymentsComponent) paymentsComponent: PaymentsComponent;

  constructor(
    public readonly router: Router,
    private readonly signupService: SignupService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.getSiteId();
    this.route.queryParams.subscribe((params) => {
      if (params?.finalize === 'success') {
        this.success = true;
        this.loading = false;
      }
    });

    if (!this.success) {
      this.selectedMembership = parseInt(localStorage.getItem('selectedMembership'));
      this.prospectiveMemberId = +localStorage.getItem('prospectiveMemberId') ?? null

      this.route.queryParams.subscribe((params) => {
        if (params?.stage) {
          this.paramRoute = params.stage;
        }
      });

      this.signupStage =
        this.paramRoute ?? localStorage.getItem('signupStage') ?? this.signUpStages[0];

      if (!this.selectedMembership) {
        this.signupStage = this.signUpStages[0];
        this.nextDisabled = true;
      }
      this.stageRouting('merge');

      this.route.queryParams.subscribe((params) => {
        if (params?.signup === 'success') {
          this.success = true;
        }
      });
    };
  }

  public stageRouting(method): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { stage: this.signupStage },
      queryParamsHandling: method,
    });
  }

  // Handles the next button for all three sub pages
  public nextStage(): void {
    this.displayErrors = false;
    if (this.signupStage === this.signUpStages[0] && this.selectedMembership) {
      localStorage.setItem('selectedMembership', this.selectedMembership.toString());
      localStorage.setItem('signupStage', this.signUpStages[1]);
      this.signupStage = this.signUpStages[1];
      this.stageRouting('merge');
    } else if (
      this.signupStage === this.signUpStages[1] &&
      this.aboutYouComponent.checkFormValid()
    ) {
      this.aboutYouComponent.submitFormHandler();
    } else {
      this.displayErrors = true;
    }
  }

  public previousStage(): void {
    this.displayErrors = false;

    // If on payment stage and have selected payment method, return to choose payment methos select
    if (this.signupStage === this.signUpStages[2] && this.paymentsComponent.paymentMethodsHidden) {
      this.paymentsComponent.paymentMethodsHidden = false;
    } else if (this.signupStage === this.signUpStages[1]) {
      this.signupStage = this.signUpStages[0];
      localStorage.setItem('signupStage', this.signUpStages[0]);
    } else if (this.signupStage === this.signUpStages[2]) {
      this.signupStage = this.signUpStages[1];
      localStorage.setItem('signupStage', this.signUpStages[1]);
    }
    this.stageRouting('merge');
  }

  public selectStage(stage): void {
    this.displayErrors = false;
    // Commented out for testing for now
    // if (this.paymentComplete) return;

    // If user trying to go to about you form or payment select
    // check if have membership selected
    if ((stage === 1 || stage === 2) && !localStorage.getItem('selectedMembership')) {
      return;
    }

    // If user trying to go to payment select
    // check if have membership selected & has created a user
    if (
      stage === 2 &&
      (!localStorage.getItem('selectedMembership') ||
        !localStorage.getItem('prevouslyCreatedUserId'))
    ) {
      return;
    }

    this.signupStage = this.signUpStages[stage];
    localStorage.setItem('signupStage', this.signUpStages[stage]);
    this.stageRouting('');
  }

  public onProspectiveMemberCreated(memberId): void {
    this.prospectiveMemberId = memberId;
    localStorage.setItem('prospectiveMemberId', memberId.toString());
    localStorage.setItem('signupStage', this.signUpStages[2]);
    this.signupStage = this.signUpStages[2];
    this.stageRouting('');
  }

  public setSelectedMembership(membershipId): void {
    this.selectedMembership = membershipId;
    this.selectedMembership ? (this.nextDisabled = false) : (this.nextDisabled = true);
  }

  public setPaymentCompleted(val): void {
    this.paymentComplete = val;
  }

  public getSiteId(): void {
    this.loading = true;

    this.authService.checkURLValidity(this.router.url)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.siteUrlTag = response.data.urlTag;
            this.siteLogoURL = response.data.siteLogoURL;
            this.clubName = response.data.clubName;
          }
        },
      });

    this.signupService
      .getSiteId(this.router.url)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            if (!response.data.siteId) {
              this.alertService.emitErrorAlert('No club found with that name.');
              this.router.navigate(['/login']);
            }
            else {
              this.siteId = response.data.siteId;
              localStorage.setItem('signupSiteId', this.siteId.toString());
            }
          }

        },
        error: () => {
          this.alertService.emitErrorAlert('No club found with that name.');
          this.router.navigate(['/login']);
        }
      });
  }
}
