<app-alert [loginScreen]="true"></app-alert>
<app-open-in-app></app-open-in-app>
<header>
  <div *ngIf="router.url === '/login'; else brandedHeader" class="logo">
    <a href="#" class="logo-container"><img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>

  <ng-template #brandedHeader>
    <div *ngIf="siteUrlTag" class="logo">
      <div class="logo-container">
        <img src="assets/images/misc/sport-insight-badge.svg" alt="Sport Insight" class="logo-image" />
        <div class="vertical-seperator"></div>
        <img *ngIf="siteLogoURL" [src]="siteLogoURL" [alt]="siteUrlTag" class="logo-image logo-brand" />
      </div>
    </div>
  </ng-template>
</header>


<main class="login-container">
  <ng-container [ngSwitch]="loginFunction">
    <!-- Login Box -->
    <section *ngSwitchCase="'login'" id="loginForm" class="login-form">
      <app-login-template [loading]="loading">
        <div heading>Login</div>
        <div content>
          <app-login-form [fillEmailInputObservable]="fillEmailInput.asObservable()"
            (switchToResetPasswordEmitter)="switchLoginFunction('resetPasswordPrompt')"
            (loggedInEmitter)="login($event)" (has2FAEmitter)="toggleTwoFA($event)"
            (isLoadingEmitter)="setLoading($event)"></app-login-form>
        </div>
      </app-login-template>
    </section>

    <!-- Two factor Authentication -->
    <section *ngSwitchCase="'2FA'" id="twoFactorAuthentication" class="login-form">
      <app-login-template [loading]="loading">
        <div heading>Two factor authentication</div>
        <div content>
          <app-two-factor-authentication [loginCredentials]="loginCredentials" (loggedInEmitter)="login($event)"
            (isLoadingEmitter)="setLoading($event)"></app-two-factor-authentication>
        </div>
      </app-login-template>
    </section>

    <!-- Reset password prompt -->
    <section *ngSwitchCase="'resetPasswordPrompt'" id="resetPasswordPrompt" class="login-form">
      <app-login-template [loading]="loading">
        <div heading>Reset password</div>
        <div content>
          <app-reset-password-prompt (returnToLoginEmitter)="switchLoginFunction('login')"
            (isLoadingEmitter)="setLoading($event)"></app-reset-password-prompt>
        </div>
      </app-login-template>
    </section>

    <!-- Reset password form -->
    <section *ngSwitchCase="'resetPassword'" id="resetPassword" class="login-form">
      <app-login-template [loading]="loading">
        <div heading>Reset password</div>
        <div content>
          <app-reset-password (returnToLoginEmitter)="switchLoginFunction('login')"></app-reset-password>
        </div>
      </app-login-template>
    </section>
  </ng-container>
</main>
