<ng-template #loadingSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="wrapper" *ngIf="!loading; else loadingSpinner">
  <app-choose-payment-method
    *ngIf="paymentStage === 'choose-payment'"
    [siteId]="siteId"
    [prospectiveMemberId]="prospectiveMemberId"
    [selectedMembership]="selectedMembership"
    [paymentMethodsHidden]="paymentMethodsHidden"
    (hidePaymentMethodEmitter)="hidePaymentMethod($event)"
    [selectedMonthly]="selectedMonthly"
    class="full-width"
  ></app-choose-payment-method>

  <app-go-cardless-redirect
    class="full-width"
    *ngIf="paymentStage === 'payment-success'"
  ></app-go-cardless-redirect>

  <app-custom-go-cardless-form
    class="full-width"
    *ngIf="paymentStage === 'direct-debit-setup'"
  ></app-custom-go-cardless-form>
  <!-- <app-card-payment-success></app-card-payment-success> -->

  <app-confirm-direct-debit *ngIf="paymentStage === 'signup-confirming'"></app-confirm-direct-debit>
</div>
