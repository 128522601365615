import { Address } from '../definitions';

export class FormHelper {
  public static getDirtyValues(form: any): object {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  public static createDisplayAddress(address: Address): string {
    return [
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4,
      address.city,
      address.county,
      address.postcode,
    ]
      .filter((value) => value)
      .join(', ');
  }
}
