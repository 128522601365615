import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Site } from '@shared/definitions';
import { SiteService } from '@shared/services/site.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  public availableSites: Site[];

  constructor(private readonly router: Router, private readonly siteService: SiteService) {}

  ngOnInit(): void {
    localStorage.removeItem('siteId');
    this.availableSites = JSON.parse(localStorage.getItem('availableSites'));
  }

  public onSiteSelected(site: Site): void {
    this.siteService.changeSite(site);
  }
}
