import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { AuthService } from '@sportinsight-services/auth.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-two-factor-authentication-setup',
  templateUrl: './two-factor-authentication-setup.component.html',
  styleUrls: ['./two-factor-authentication-setup.component.scss'],
})
export class TwoFactorAuthenticationSetupComponent implements OnInit {
  public displayRecoveryCodes = false;
  public recoveryCodes: string[];
  public qrCodeUrl: string;
  public cannotScanQRPrompt = false;
  public email: string;
  public secretKey: string;
  public first2FAUse = false;
  public display2FASetupForm = false;
  public display2FAForm = false;
  public loading = false;

  constructor(
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.generateSecretKey();
  }

  private generateSecretKey(): void {
    this.loading = true;

    this.authService
      .generateTwoFASecret()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          this.qrCodeUrl = response.qrCodeUrl;
        },
        error: (error) => {
          this.alertService.emitErrorAlert(error.error?.message);
        },
      });
  }

  public setDisplayRecoveryCodes(recoveryCodes: string[]): void {
    this.recoveryCodes = recoveryCodes;
    this.displayRecoveryCodes = true;
    this.display2FAForm = false;
  }

  public continue(): void {
    this.router.navigateByUrl('/homepage');
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }
}
