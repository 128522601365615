<ng-template #loginSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loginSpinner">
  <ng-container *ngIf="!paymentMethodsHidden && (product.monthlyPrice || product.yearlyPrice)">
    <app-payment-option-card
      [title]="PAYMENT_TITLES.DD"
      (selectPaymentMethod)="setPaymentMethod($event)"
      [monthlyPrice]="product.monthlyPrice"
      [yearlyPrice]="product.yearlyPrice"
      [prorataPrice]="product.prorataPrice"
      [joiningFee]="product.joiningFee"
      [dueToday]="product.dueToday"
      [membershipExpiration]="product.membershipExpiryDate"
      (selectPaymentPeriod)="paymentPeriod = $event"
    ></app-payment-option-card>
    <app-payment-option-card
      *ngIf="product.monthlyPrice"
      [title]="PAYMENT_TITLES.BACS"
      (selectPaymentMethod)="setPaymentMethod($event)"
      [monthlyPrice]="product.monthlyPrice"
      [yearlyPrice]="product.yearlyPrice"
      [prorataPrice]="product.prorataPrice"
      [joiningFee]="product.joiningFee"
      [dueToday]="product.dueToday"
      [membershipExpiration]="product.membershipExpiryDate"
    ></app-payment-option-card>
    <app-payment-option-card
      *ngIf="product.monthlyPrice"
      [title]="PAYMENT_TITLES.CARD"
      (selectPaymentMethod)="setPaymentMethod($event)"
      [monthlyPrice]="product.monthlyPrice"
      [yearlyPrice]="product.yearlyPrice"
      [prorataPrice]="product.prorataPrice"
      [joiningFee]="product.joiningFee"
      [dueToday]="product.dueToday"
      [membershipExpiration]="product.membershipExpiryDate"
    ></app-payment-option-card>
  </ng-container>
  <!-- LOAD SELECTED PAYMENT OPTIONS -->

  <ng-container *ngIf="paymentMethodsHidden && selectedPaymentMethod === 'By Direct Debit'">
    <div>
      <h1>Amount Due today: £{{ paymentPeriod === 'YEARLY' ? product.prorataPrice + product.joiningFee : product.dueToday + product.joiningFee }}*</h1>
      <p>*Includes joining fee (£{{ product.joiningFee }})</p>
    </div>
    <app-payment
      [redirectUrl]="goCardlessUrl"
      [userId]="prospectiveMemberId"
      [productId]="selectedMembership"
      [siteId]="siteId"
      [period]="ddPeriod"
      [paymentMethod]="2"
    ></app-payment>
  </ng-container>

  <ng-container *ngIf="paymentMethodsHidden && selectedPaymentMethod === 'By Card'">
    <div>
      <h1>Amount Due today: £{{ product.prorataPrice + product.joiningFee }}*</h1>
      <p>*Includes joining fee (£{{ product.joiningFee }})</p>
    </div>
    <app-payment
      [redirectUrl]=""
      [userId]="prospectiveMemberId"
      [productId]="selectedMembership"
      [siteId]="siteId"
      [period]="'YEARLY'"
      [paymentMethod]="3"
    ></app-payment>
  </ng-container>
</ng-container>
