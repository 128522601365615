<ng-template #loadingSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="wrapper">
  <div class="container" *ngIf="!loading; else loadingSpinner">
    <div class="billing-details edit-card">
      <div class="left">
        <p>Name: {{ billingDetails?.firstName }} {{ billingDetails?.lastName }}</p>
        <p>Email: {{ billingDetails?.email }}</p>
      </div>
      <div class="right">
        <button (click)="setStage(1)" class="btn black">edit</button>
      </div>
    </div>
    <div class="bank-details edit-card">
      <div class="left">
        <p>Account holder name: {{ bankDetails?.accountName }}</p>
        <p>Sort code: {{ bankDetails?.sortCode }}</p>
      </div>
      <div (click)="setStage(2)" class="right">
        <button class="btn black">edit</button>
      </div>
    </div>

    <div class="confirm-check">
      <label class="label-container"
        >One
        <input type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>
