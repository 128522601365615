<app-alert [signupScreen]="true"></app-alert>
<header>
    <div *ngIf="siteUrlTag" class="logo">
      <div class="logo-container">
        <img src="assets/images/misc/sport-insight-badge.svg" alt="Sport Insight" class="logo-image" />
        <div class="vertical-seperator"></div>
        <img *ngIf="siteLogoURL" [src]="siteLogoURL" [alt]="siteUrlTag" class="logo-image logo-brand" />
      </div>
    </div>
</header>

<ng-template #loadingSpinner>
  <div class="signup-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loadingSpinner">
  <main class="signup-container" *ngIf="!success; else successPage">
    <div class="content-container">
      <!-- Tracks user progress between the three signup pages -->
      <app-signup-progress-tracker
        [signupStage]="signupStage"
        (stageEmitter)="selectStage($event)"
        [paymentComplete]="paymentComplete"
      ></app-signup-progress-tracker>

      <div class="container">
        <div class="headerContainer">
          <h1 *ngIf="signupStage === 'membership-options'">Choose your membership</h1>
          <div class="backContainer" (click)="previousStage()">
            <img
              src="/assets/images/icons/icon-arrow-right-blue.svg"
              class="back-icon"
              (click)="previousStage()"
              *ngIf="!paymentComplete && signupStage === 'about-you'"
            />
            <img
              src="/assets/images/icons/icon-arrow-right-blue.svg"
              class="back-icon"
              (click)="previousStage()"
              *ngIf="!paymentComplete && signupStage === 'payments'"
            />
            <p *ngIf="!paymentComplete && (signupStage === 'payments' || signupStage === 'about-you')">Back</p>
          </div>

          <h1 *ngIf="signupStage === 'about-you'">Tell us a bit about you</h1>
          <h1 *ngIf="signupStage === 'payments'">Payment</h1>
        </div>

        <app-signup-membership-options
          [selectedMembership]="selectedMembership"
          *ngIf="signupStage === signUpStages[0]"
          (selectedMembershipEmitter)="setSelectedMembership($event)"
          (selectedTermEmitter)="selectedMonthly = !!$event"
          [signupStage]="1"
          [displayErrors]="displayErrors"
          [siteId]="siteId"
        ></app-signup-membership-options>

        <app-signup-aboutyou
          *ngIf="signupStage === signUpStages[1]"
          [signupStage]="2"
          [displayErrors]="displayErrors"
          [siteId]="siteId"
          (userCreatedEmitter)="onProspectiveMemberCreated($event)"
          [siteUrlTag]="siteUrlTag"
          [clubName]="clubName"
        ></app-signup-aboutyou>

        <app-payments
          *ngIf="signupStage === signUpStages[2]"
          [prospectiveMemberId]="prospectiveMemberId"
          [selectedMembership]="selectedMembership"
          [selectedMonthly]="selectedMonthly"
          (paymentCompleteEmitter)="setPaymentCompleted($event)"
          (stageEmitter)="selectStage($event)"
        ></app-payments>
      </div>

      <div class="button-container">
        <!-- Previous screen -->
        <button
          *ngIf="signupStage !== 'membership-options' && !paymentComplete"
          class="btn back"
          (click)="previousStage()"
          type="button"
        >
          {{signupStage === 'about-you' ? 'Membership' : 'About you' }}
        </button>

        <!-- Next screen -->
        <button
          *ngIf="signupStage !== 'payments' && !paymentComplete"
          class="btn black next"
          (click)="nextStage()"
          [disabled]="nextDisabled"
          [ngClass]="{ disabled: nextDisabled }"
          type="button"
        >
          {{signupStage === 'membership-options' ? 'About you' : 'Payment' }}
        </button>
      </div>
    </div>
  </main>

  <ng-template #successPage>
    <div class="signup-success-container signup-success">
      <div class="signup-success content-container">
          <app-signup-success></app-signup-success>
      </div>
    </div>
  </ng-template>
</ng-container>
