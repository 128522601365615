import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

import {
  ClubCheckinData,
  GenericAPIResponse,
  Site,
  User,
  BookingInfoTooltipConfig,
} from '@shared/definitions';
import { environment } from '@shared/environment';
import { HttpParamsService } from '@shared/services/http-params.service';
import { UserSearchParams, UserSearchResponse } from '@shared/services/user.service';
import { DashboardStateService } from './dashboard-state.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private readonly httpSkip: HttpClient;

  private readonly editBookingOverrideParamsSubject = new BehaviorSubject<EditBookingData>(null);

  public readonly editBookingOverrideParamsObservable =
    this.editBookingOverrideParamsSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly httpParamsService: HttpParamsService,
    private readonly handler: HttpBackend,
    private readonly dashboardState: DashboardStateService
  ) {
    this.httpSkip = new HttpClient(handler);
  }

  public getTimeslotData(data: GetBookingDataParams): Observable<GenericAPIResponse<BookingData>> {
    const url = `${environment.apiURL}/booking/timeslot-data`;

    const params = this.httpParamsService.getHttpParams(data);

    return this.http.get<GenericAPIResponse<BookingData>>(url, { params });
  }

  public getSingleBookingById(id: number): Observable<GenericAPIResponse<Booking>> {
    const url = `${environment.apiURL}/booking/single/${id}`;
    return this.http.get<GenericAPIResponse<Booking>>(url);
  }

  public getRepeatBookingScheduleById(
    id: number
  ): Observable<GenericAPIResponse<RepeatBookingSchedule>> {
    const url = `${environment.apiURL}/admin/booking/repeat/schedule/${id}`;
    return this.http.get<GenericAPIResponse<RepeatBookingSchedule>>(url);
  }

  public getAdminSingleBookingById(
    id: number
  ): Observable<GenericAPIResponse<RepeatBookingSchedule>> {
    const url = `${environment.apiURL}/admin/booking/single/${id}`;
    return this.http.get<GenericAPIResponse<RepeatBookingSchedule>>(url);
  }


  public cancelSingleBooking(bookingId: number, timeslotIds: number[]): Observable<void> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/cancel`;
    return this.http.post<void>(url, { timeslotIds });
  }

  public createSingleBooking(params: CreateSingleBookingParams): Observable<void> {
    params.source = 'MEMBER';
    const url = `${environment.apiURL}/booking/single/create`;
    return this.http.post<void>(url, params);
  }

  public editSingleBooking(bookingId: number, params: EditSingleBookingParams): Observable<void> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/edit`;
    return this.http.post<void>(url, params);
  }

  public addToBooking(bookingId: number, params: AddMemberToBookingParams): Observable<void> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/addMember`;
    return this.http.post<void>(url, params);
  }

  public removeFromBooking(bookingId: number, params: RemoveMemberFromBookingParams): Observable<void> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/removeMember`;
    return this.http.post<void>(url, params);
  }

  public linkedMemberSearch(searchParams: UserSearchParams): Observable<UserSearchResponse> {
    const url = `${environment.apiURL}/booking/linked-member-search`;
    searchParams.isPrimary = searchParams.isPrimary ?? 0;
    const params = this.httpParamsService.getHttpParams(searchParams);
    return this.http.get<UserSearchResponse>(url, { params });
  }

  public createCourtAvailabilityRequest(
    params: CreateCourtAvailabilityRequestParams
  ): Observable<void> {
    const url = `${environment.apiURL}/booking/availability-request`;
    return this.http.post<void>(url, params);
  }

  public getUpcomingActivities(
    activityTypeId: number = null
  ): Observable<GenericAPIResponse<UpcomingActivity[]>> {
    const url = `${environment.apiURL}/booking/upcoming-activities`;
    const params = this.httpParamsService.getHttpParams({ activityTypeId });
    return this.http.get<GenericAPIResponse<UpcomingActivity[]>>(url, { params });
  }

  public deleteCourtAvailabilityRequest(availabilityRequestId: number): Observable<void> {
    const url = `${environment.apiURL}/booking/availability-request/${availabilityRequestId}`;
    return this.http.delete<void>(url);
  }

  public deleteCourtAvailabilityRequestItem(
    params: CourtAvailabilityRequestItemDefinition
  ): Observable<void> {
    const url = `${environment.apiURL}/booking/availability-request-item`;
    return this.http.delete<void>(url, { params });
  }

  public getBookingsForCurrentUser(): Observable<Booking[]> {
    const url = `${environment.apiURL}/booking/bookings-for-current-user`;
    return this.http.get<Booking[]>(url);
  }

  public getTransfersForCurrentUser(): Observable<Booking[]> {
    const url = `${environment.apiURL}/booking/transfers-for-current-user`;
    return this.http.get<Booking[]>(url);
  }

  public set editBookingOverrideParams(value: EditBookingData) {
    this.editBookingOverrideParamsSubject.next(value);
  }

  public getCourtAlertsForUser(): Observable<CourtAvailabilityRequest[]> {
    const url = `${environment.apiURL}/booking/court-alerts-for-current-user`;
    return this.http.get<CourtAvailabilityRequest[]>(url);
  }

  public validateBooking(params: ValidateBookingParams): Observable<void> {
    const url = `${environment.apiURL}/booking/validate`;
    return this.http.post<void>(url, params);
  }

  public updateUserBookingTableLayout(layoutValue): Observable<void> {
    const url = `${environment.apiURL}/user/user-grid-layout`;
    return this.http.put<void>(url, { layoutVaue: layoutValue });
  }

  public getUserBookingTableLayout(): Observable<GenericAPIResponse<number>> {
    const url = `${environment.apiURL}/user/user-grid-layout`;
    return this.http.get<GenericAPIResponse<number>>(url);
  }

  public getUserDistanceToClub(bookingId: number, postData: BookingCheckIn): Observable<any> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/checkin`;
    const params = this.httpParamsService.getHttpParams(postData);
    return this.http.get<GenericAPIResponse<any>>(url, { params });
  }

  public checkinUser(bookingId: number, linkedUsers: string): Observable<any> {
    const url = `${environment.apiURL}/booking/single/${bookingId}/checkin-user`;
    return this.http.get<GenericAPIResponse<any>>(url);
  }

  public transferBooking(params): Observable<GenericAPIResponse<void>> {
    const url = `${environment.apiURL}/booking/transfer`;
    return this.http.post<GenericAPIResponse<void>>(url, params);
  }

  public acceptBookingTransfer(token: string): Observable<GenericAPIResponse<void>> {
    const url = `${environment.apiURL}/booking/accept-booking-transfer`;
    return this.httpSkip.post<GenericAPIResponse<void>>(url, { token });
  }

  public cancelBookingTransfer(bookingId): Observable<GenericAPIResponse<void>> {
    const url = `${environment.apiURL}/booking/cancel-booking-transfer`;
    return this.http.post<GenericAPIResponse<void>>(url, { bookingId });
  }

  public declineBookingTransfer(token: string): Observable<GenericAPIResponse<void>> {
    const url = `${environment.apiURL}/booking/decline-booking-transfer`;
    return this.http.post<GenericAPIResponse<void>>(url, { token });
  }

  /**
   * Retrieves all checkin data from local storage.
   * If there are facility specific overrides, use those
   * @returns void
   */
  public async initCheckinData(facilityId: number = null): Promise<ClubCheckinData> {
    const siteData = await firstValueFrom(this.dashboardState.currentSite);

    let returnData = {
      siteId: siteData.siteId,
      checkInBeforeMins: siteData.checkInBeforeMins,
      checkInAfterMins: siteData.checkInAfterMins,
      checkInEnabled: !!siteData.checkInEnabled,
      checkInMeters: siteData.checkInMeters,
    };

    if (facilityId) {
      this.dashboardState.facilityCheckInOverrides.subscribe({
        next: (facilityOverrides) => {
          if (facilityOverrides) {
            let facilityOverride = facilityOverrides.find((override) => override.facilityTypeId === facilityId);
            if (facilityOverride) {
              return {
                siteId: siteData.siteId,
                checkInBeforeMins: facilityOverride.checkInBeforeMins !== null ? facilityOverride.checkInBeforeMins : siteData.checkInBeforeMins,
                checkInAfterMins: facilityOverride.checkInBeforeMins !== null ? facilityOverride.checkInBeforeMins : siteData.checkInAfterMins,
                checkInEnabled: facilityOverride.checkInEnabled !== null ? !!facilityOverride.checkInEnabled : !!siteData.checkInEnabled,
                checkInMeters: facilityOverride.checkInBeforeMins !== null ? facilityOverride.checkInBeforeMins : siteData.checkInMeters,
              }
            }
          }
        },
      })
    }

    return returnData;
  }

  /**
   * Will check if the current time is within a given range
   * returns true if the user can transfer
   * @param string startTime
   * @param string endTime
   * @returns boolean
   */
  public canUserTransfer(startTime: string, selectedDate: Date): boolean {
    // Get start time minutes and hours
    const [starTimeHours, startTimeMinutes] = startTime.split(':').map(Number);
    const currentTimeObj = new Date();

    const startTimeObj = new Date();
    startTimeObj.setHours(starTimeHours, startTimeMinutes);
    // if there is a booking on a future date and the current time is past the startTime of that booking
    if (currentTimeObj > startTimeObj && currentTimeObj <= selectedDate) {
      return true;
    }
    return currentTimeObj <= startTimeObj;
  }

  public transferWindowOpen(booking, selectedDate = null): boolean {
    let startTime;
    let startDate;

    if (Array.isArray(booking)) {
      startTime = booking[0].startTime;
      startDate = new Date(selectedDate);
    } else {
      startTime = booking.booking_items[0].timeslot.startTime;
      startDate = new Date(booking.date);
    }

    const now = new Date();
    const [hours, minutes, seconds] = startTime.split(':').map(Number);

    // Create a Date object with the specified time

    startDate.setHours(hours);
    startDate.setMinutes(minutes);
    startDate.setSeconds(seconds);

    if (startDate > now) {
      return true;
    } else {
      return false;
    }
  }

  public shouldShowButton(
    buttonType:
      | 'CREATE'
      | 'CANCEL'
      | 'CLEAR'
      | 'UPDATE'
      | 'CLEARNOTIFY'
      | 'CHECK-IN'
      | 'TRANSFER'
      | 'LINKED'
      | 'NOTIFY'
      | 'ACCEPT_TRANSFER',
    selectedTimeslots,
    user: User | null,
    deselectedSlots = [],
    bookingTimeslots = []
  ): boolean {
    if (!selectedTimeslots.length && !deselectedSlots.length && buttonType !== 'ACCEPT_TRANSFER')
      return false;
    switch (buttonType) {
      case 'UPDATE':
        let slotsToCheckUpdate;
        deselectedSlots.length > 0
          ? (slotsToCheckUpdate = deselectedSlots)
          : (slotsToCheckUpdate = selectedTimeslots);
        if (selectedTimeslots.length) {
          return (
            user.canBook === 1 &&
            (this.loggedInUserOwnsBooking(
              slotsToCheckUpdate.find(
                (slot) =>
                  slot.timeslotId ===
                  bookingTimeslots.find((timeslot) => timeslot === slot.timeslotId)
              ),
              user
            ) ||
              this.linkedMembersBooking(
                slotsToCheckUpdate.find(
                  (slot) =>
                    slot.timeslotId ===
                    bookingTimeslots.find((timeslot) => timeslot === slot.timeslotId)
                ),
                user
              ))
          );
        }
        return false;

      case 'LINKED': // If you are a linked member on every selected timeslot
        return selectedTimeslots.every((slot) => this.linkedMembersBooking(slot, user));
      case 'CREATE':
        // All slots selected have no existing booking and haven't already passed
        return (
          user.canBook === 1 &&
          selectedTimeslots.every((slot) => !slot.bookingId && !slot.alreadyPassed)
        );
      case 'CLEAR':
        return true;
      case 'CANCEL':
        // If the logged in user wants to cancel a booking
        let slotsToCheck;
        deselectedSlots.length > 0
          ? (slotsToCheck = deselectedSlots)
          : (slotsToCheck = selectedTimeslots);
        return (
          user.canBook === 1 &&
          this.loggedInUserOwnsBooking(
            slotsToCheck.find(
              (slot) =>
                slot.timeslotId ===
                bookingTimeslots.find((timeslot) => timeslot === slot.timeslotId)
            ),
            user
          )
        );

      case 'NOTIFY':
        return (
          this.onlyBookedSlotsSelected(selectedTimeslots) &&
          selectedTimeslots.every((s) => {
            return !s.alreadyPassed && !this.loggedInUserOwnsBooking(s, user) && !s.hasCourtAlert;
          }) &&
          selectedTimeslots.every((slot) => !this.linkedMembersBooking(slot, user))
        );
      case 'CLEARNOTIFY':
        return (
          this.onlyBookedSlotsSelected(selectedTimeslots) &&
          selectedTimeslots.every((s) => !this.loggedInUserOwnsBooking(s, user) && s.hasCourtAlert)
        );
      case 'CHECK-IN':
        if (deselectedSlots.length > 0) {
          return (
            user.canBook === 1 &&
            (deselectedSlots.every(
              (slot) => this.loggedInUserOwnsBooking(slot, user) && !slot.alreadyPassed
            ) ||
              deselectedSlots.every((slot) => this.linkedMembersBooking(slot, user)))
          );
        }

        return (
          user.canBook === 1 &&
          (selectedTimeslots.some(
            (slot) => this.loggedInUserOwnsBooking(slot, user) && !slot.alreadyPassed
          ) ||
            selectedTimeslots.every((slot) => this.linkedMembersBooking(slot, user)))
        );

      case 'TRANSFER':
        // owns booking
        // can transfer is true
        let slotsToCheckTransfer;
        deselectedSlots.length > 0
          ? (slotsToCheckTransfer = deselectedSlots)
          : (slotsToCheckTransfer = selectedTimeslots);

        return (
          user.canBook === 1 &&
          this.loggedInUserOwnsBooking(
            slotsToCheckTransfer.find(
              (slot) =>
                slot.timeslotId ===
                bookingTimeslots.find((timeslot) => timeslot === slot.timeslotId)
            ),
            user
          )
        );
      case 'ACCEPT_TRANSFER':
        if (!selectedTimeslots.length) return selectedTimeslots?.transferTo === user.userId;

        return user.canBook === 1 && selectedTimeslots[0].transferTo === user.userId;
    }
  }

  public loggedInUserOwnsBooking(row: TimeslotSPData, loggedInUser: User | null): boolean {
    if (!row?.bookingOwnerUserId || !loggedInUser) return false;

    return row?.bookingOwnerUserId === loggedInUser.userId;
  }

  public onlyBookedSlotsSelected(selectedTimeslots: TimeslotSPData[]): boolean {
    return selectedTimeslots.every((slot) => slot.bookingId);
  }

  /**
   * Will check if the loggedInUser is linked to the selectedTimeslot
   * @param TimeslotSPDate row
   * @param User loggedInUser
   * @returns boolean
   */
  public linkedMembersBooking(row: TimeslotSPData, loggedInUser: User): boolean {
    if (row?.linkedMemberIds) {
      // first check if the passed in user owns the booking
      const isLinkedBooking =
        row.linkedMemberIds.split(',').find((id) => +id === +loggedInUser.userId) ?? false;

      if (isLinkedBooking) {
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Will determine weather the remove user button or member search will show
   * depending on weather the user/loggedInUser is linked, owns the booking or booking
   * made in admin
   * @param any row
   * @param boolean isRepeatOrAdminBooking
   * @param boolean userOwnsBooking
   * @param boolean userLinkedToBooking
   * @param User loggedInUser
   * @param User linkedUser
   * @returns boolean
   */
  public showRemovePersonIcon(
    selectedTimeslotBookingId: number = null,
    isRepeatOrAdminBooking: boolean = false,
    userOwnsBooking: boolean = false,
    userLinkedMember: boolean = false,
    loggedInUser: User = null,
    linkedUser = null,
    inputCheck: boolean = false
  ): boolean {
    // If user is looking at their own booking
    if (userOwnsBooking) {
      return linkedUser?.userId === loggedInUser?.userId ? false : true
    }

    // User is linked to a booking
    if (!userOwnsBooking && userLinkedMember && linkedUser && !inputCheck) {
      return linkedUser.userId === loggedInUser.userId ? true : false;
    }

    // we are checking if we should show the input box
    if (!userOwnsBooking && userLinkedMember && !linkedUser && inputCheck) {
      return true;
    }

    // if the booking was created in admin or its a repeat booking
    if (isRepeatOrAdminBooking) {
      return false;
    }

    // Looking at someone elses booking
    if (!userLinkedMember && !userOwnsBooking) {
      // Looking at an empty timeslot (no one has booked that slot)
      if (selectedTimeslotBookingId === null) {
        return inputCheck ? false : linkedUser?.userId === loggedInUser.userId ? false : true;
      }
      return false;
    }
    return false;
  }
}

export interface BookingCheckIn {
  userId: number;
  latitude: number;
  longitude: number;
}

export type ValidateBookingParams = {
  date: string;
  timeslotIds: number[];
};

export type CreateSingleBookingParams = {
  typeId: number;
  date: string;
  label: string;
  notes: string;
  globalCalendar: boolean;
  timeslotIds: number[];
  memberUserIds: number[];
  guests: User[];
  selectedMemberUserId: number | null;
  rebooking: boolean;
  source?: string;
};

export type GetBookingDataParams = {
  facilityType: string;
  date: string;
  orientation: 'VERTICAL' | 'HORIZONTAL';
  repeatMode: boolean;
  startDate: string;
  endDate: string;
  dayOfWeek: number;
  bookingIdToIgnore: number;
  bookingTypeToIgnore: 'SINGLE' | 'REPEAT';
  timeslotIdsToIgnore: number[];
};

export type BookingData = {
  headings: BookingFacilityHeader[];
  tableData: TimeslotSPData[];
  maxTotalLength: number;
};

export type BookingFacilityHeader = {
  facilityId: number;
  label: string;
  properties: FacilityProperty[];
};

export type FacilityProperty = {
  name: string;
  value: any;
};

export type TimeslotSPData = {
  bookingId: number | null;
  bookingLabel: string | null;
  bookingTypeId: number | null;
  bookingUserId: number | null;
  isRepeatBooking: boolean;
  dayOfWeek: number;
  endTime: string;
  facilityId: number;
  linkedMemberIds: string;
  length: number;
  startTime: string;
  timeslotId: number;
  startRow: number;
  col: number;
  multipleBookingIds: string;
  multipleBookings: any[];
  repeatBookingScheduleId: number | null;
  bookingOwnerUserId?: number | null;
  alreadyPassed: 1 | 0;
  hasCourtAlert: 1 | 0;
  members: string;
  bookingNotes: string;
  tooltipConfig: BookingInfoTooltipConfig;
  isAdminBooking?: boolean;
  userCheckedIn: boolean;
  canUserCheckin?: boolean;
  checkInTimestamp: string;
  transferTo: number;
  transferToken: string;
  canTransfer?: boolean;
  sourceUserName: string;
  transferToFirstName: string;
  transferToLastName: string;
  availableToCheckIn: number;
};

export type BookingTooltipMembersInfo = {
  role: string;
  user: string;
  linked: { role: string; productTag: string; linkedMember: string }[];
};

export type Booking = {
  id: number;
  globalCalendar: boolean;
  label: string;
  typeId: number;
  userId: number;
  source: 'ADMIN' | 'MEMBER';
  sourceUserId: number;
  booking_items?: BookingItem[];
  linked_members?: User[];
  timeslotsToSelect?: TimeslotSPData[];
  startDate?: string;
  endDate?: string;
  biweekly: boolean;
  linkedMember?: User;
  notes: string;
  facilityBookings?: FacilityBooking[];
  user?: User;
  date: string;
  bookingData: UpcomingBookingData;
  checkedIn: boolean;
  transferTo: number;
  transferToFirstName?: string;
  transferToLastName?: string;
  transferToken: string;
  timeslots: number[];
  canTransfer?: boolean;
  sourceUserName: string;
  transferType?: string;
  checkInEnabled: number;
  facilityTypeId: number;
  membershipLabel: string;
  checkInAvailable: boolean;
};

export type LinkedMemberInfo = {
  bookingId: number;
  checkedIn: number;
  userId: number;
};

export type UpcomingBookingData = {
  id: number;
  facilityTypeId: number;
  timeslots: number[];
  date: string;
  userId: number;
  facilityBookings: FacilityBooking[];
  tooltipConfig: BookingInfoTooltipConfig;
  linked_members: [];
};

export type BookingItem = {
  id: number;
  bookingId?: number;
  repeatBookingId?: number;
  timeslotId: number;
  timeslot?: Timeslot;
};

export type EditSingleBookingParams = {
  timeslotIds: number[];
  memberUserIds?: number[];
  guests?: User[];
  date: string;
  changingPlayersOnly?: boolean;
};

export type AddMemberToBookingParams = {
  timeslotIds: number[];
  date: string;
  memberUserId: number;
  guest?: any;
  ownerUserId: number;
}

export type RemoveMemberFromBookingParams = {
  timeslotIds: number[];
  date: string;
  memberUserId: number;
  guest?: any;
  ownerUserId: number;
}

export type Timeslot = {
  id: number;
  facilityId: number;
  dayOfWeek: number;
  startTime: string;
  length: number;
  endTime: string;
  dateCreated: string;
  dateModified: string;
  canUserCheckin?: boolean;
  facility?: {
    id: number;
    typeId: number;
    label: string;
    siteId: number;
    orderIndex: number;
  };
};

export type EditBookingData = {
  id: number;
  isRepeat: boolean;
  timeslotIdsToIgnore?: number[];
  scheduleId?: number;
  date?: Date;
  isOwner?: boolean;
  groupTag?: string;
  skipClear?: boolean;
  repeatScheduleId?: number | null
};

export type CreateCourtAvailabilityRequestParams = {
  timeslotIds: number[];
  date: string;
};

export type RepeatBookingSchedule = {
  repeatBookingScheduleId: number;
  repeatBookingId: number;
  startDate: string;
  endDate: string;
  biWeekly: boolean;
  notes: string;
  dateCreated: string;
  dateModified: string;
  timeslotsToSelect?: TimeslotSPData[];
  label: string;
  booking?: {
    id: number;
    typeId: number;
  };
  globalCalendar: boolean;
  nextDate: string;
  facilityId: number;
  maxAbility: number;
  minAbility: number;
};

export type RepeatBookingItem = {
  id: number;
  repeatBookingScheduleId: number;
  timeslotId: number;
  dateCreated: string;
  dateModified: string;
  nextDate: string;
  schedule?: RepeatBookingSchedule;
  timeslot?: Timeslot;
};

export type CourtAvailabilityRequest = {
  id: number;
  userId: number;
  date: string;
  request_items?: CourtAvailabilityRequestItem[];
  facilityBookings?: FacilityBooking[];
  available: boolean;
  facilityGroupTag: string;
};

export type CourtAvailabilityRequestItem = {
  id: number;
  courtAvailabilityRequestId: number;
  timeslotId: number;
  timeslot?: Timeslot;
  available: boolean;
};

export type FacilityBooking = {
  startTime: string;
  endTime: string;
};

export type CourtAvailabilityRequestItemDefinition = {
  date: string;
  timeslotIds: string;
};

export type UpcomingActivity = {
  activityType: string;
  facility: string;
  label: string;
  date: string;
  startTime: string;
  endTime: string;
  notes: string;
  maxAbility?: null | number;
  minAbility?: null | number;
};
