import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-slider-toggle',
  templateUrl: './slider-toggle.component.html',
  styleUrls: ['./slider-toggle.component.scss'],
})

export class SliderToggleComponent {
  public toggleState = false;

  @Output() private readonly toggleStateEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  public toggleStateEmit(): void {
    this.toggleState = !this.toggleState;
    this.toggleStateEmitter.emit(this.toggleState);
  }
}
