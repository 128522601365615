<div class="wrapper" [ngClass]="{ noMargin: title === 'By Card' }">
  <div class="subWrapper">
    <div class="left">
      <div class="title">
        <h1>{{ title }}</h1>
      </div>

      <div class="descriptors">
        <div class="descriptor">
          <span class="descriptorBullet"></span>
          <p class="priceSubtext">{{ priceSubtext(title) }}</p>
        </div>

        <div class="descriptor">
          <span *ngIf="period.value === 'MONTHLY' && title === 'By Direct Debit'" class="descriptorBullet"></span>
          <p *ngIf="period.value === 'MONTHLY' && title === 'By Direct Debit'" class="priceSubtext">
            Monthly direct debit payment will be taken on the 1st of each month with the next payment on {{nextMonthlyPaymentDate}}.
          </p>
          <span *ngIf="period.value === 'YEARLY' && title === 'By Direct Debit'" class="descriptorBullet"></span>
          <p *ngIf="period.value === 'YEARLY' && title === 'By Direct Debit'" class="priceSubtext">
            Annual direct debit payment will be taken on {{membershipRenewelMonth}} each year for the upcoming annual membership fee with the next payment on {{membershipRenewelDate}}.
          </p>
        </div>

        <div class="descriptor" *ngIf="title === 'By BACS'">
          <span class="descriptorBullet"></span>
          <div class="bacs">
            <div *ngIf="bankingDetails; else spinner">
              <p>Make a payment from your bank to:</p>
              <table>
                <tr>
                  <td><p class="bold">Account Name: </p></td>
                  <td class="margin-left"><p>{{ bankingDetails.accountName }}</p></td>
                </tr>
                <tr>
                  <td><p class="bold">Account Number: </p></td>
                  <td class="margin-left"><p>{{ bankingDetails.accountNumber }}</p></td>
                </tr>
                <tr>
                  <td><p class="bold">Sort Code: </p></td>
                  <td class="margin-left"><p>{{ bankingDetails.sortCode }}</p></td>
                </tr>
                <tr>
                  <td><p class="bold">Reference: </p></td>
                  <td class="margin-left"><p>{{ bankingDetails.reference }}</p></td>
                </tr>
              </table>
            </div>
            <ng-template #spinner>
              <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="priceContainer" *ngIf="title === 'By Direct Debit'">
        <div class="price">
          <p>Initial by card</p>

          <div class="final-price">
            <h2 *ngIf="period.value==='MONTHLY'">£{{ dueToday + joiningFee }}</h2>
            <h2 *ngIf="period.value==='YEARLY'">£{{ prorataPrice + joiningFee }}</h2>
          </div>
        </div>

        <div class="price">
          <p>Recurring by direct debit</p>
          <div class="final-price">
            <h2 *ngIf="period.value==='MONTHLY'">£{{ monthlyPrice}}</h2>
            <h2 *ngIf="period.value==='YEARLY'">TBC</h2>
          </div>
        </div>
      </div>

      <div class="priceContainer" *ngIf="title !== 'By Direct Debit'">
       <div class="price">
        <p>{{ title }}</p>
        <div class="final-price">
          <h2>£{{ prorataPrice + joiningFee }}</h2>
        </div>
       </div>
      </div>
    </div>
  </div>

  <div class="subWrapper">
    <div class="left">


      <div class="card-powered-by-container" *ngIf="title === 'By Card'">
        <img class="card" src="/assets/images/misc/mc-symbol.svg" alt="mastercard" />
        <img class="card" src="/assets/images/misc/visa-symbol.svg" alt="visa" />

        <div class="powered-by">
          <p>{{ footerText(title) }}</p>
        </div>
      </div>

      <div class="powered-by" *ngIf="title !== 'By BACS' && title !== 'By Card'">
        <p>{{ footerText(title) }}</p>
      </div>
    </div>


    <div class="right">
      <div class="bottom">
        <div class="select" *ngIf="title === 'By Direct Debit'">
          <select name="period" id="period" [formControl]="period">
            <option [selected]="period.value === 'MONTHLY'" *ngIf="monthlyPrice" value="MONTHLY">Monthly</option>
            <option [selected]="period.value === 'YEARLY'" *ngIf="yearlyPrice" value="YEARLY">Annually</option>
          </select>
        </div>

        <button (click)="selectPayment(title)" class="btn black">
          {{ title !== "By BACS" ? "Pay Now" : "Select" }}
        </button>
      </div>
    </div>
  </div>
</div>
