import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@shared/services/alert.service';
import { finalize, Observable } from 'rxjs';
import {
  AttemptLoginParams,
  AttemptLoginResponse,
  AuthService,
  LoginProperties,
} from '@sportinsight-services/auth.service';
import { emailRegExp, passwordRegExp } from '@sportinsight/src/app/sub-projects/shared/definitions';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() private fillEmailInputObservable: Observable<string>;

  @Output() private readonly switchToResetPasswordEmitter = new EventEmitter();
  @Output() private readonly loggedInEmitter = new EventEmitter<{
    loginProperties: LoginProperties;
    loginCredentials: AttemptLoginParams;
  }>();
  @Output() private readonly has2FAEmitter = new EventEmitter<AttemptLoginParams>();
  @Output() private readonly isLoadingEmitter = new EventEmitter<boolean>();

  public loginForm: FormGroup;
  private selectedSiteId: number = 0;

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.fillEmailInputObservable.subscribe({
      next: (email) => this.loginForm.get('email').patchValue(email),
    });
  }

  private initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegExp)]],
      password: ['', [Validators.required]],
      rememberMe: false,
    });
  }

  public attemptLogin(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.gaService.event('Login', 'Click', 'login_attempt_made');

    if (!passwordRegExp.test(this.loginForm.get('password').value)) {
      this.weakPasswordReset();

      return;
    }

    this.setLoading(true);

    if (localStorage.getItem('siteId')) {
      this.selectedSiteId = +localStorage.getItem('siteId');
    }

    const params = { ...this.loginForm.value, siteId: !this.selectedSiteId ? null : this.selectedSiteId };

    this.authService.attemptLogin(params).subscribe({
      next: (response: AttemptLoginResponse) => {
        if (response.twoFAEnabled) {
          localStorage.setItem('twoFAEnabled', response.twoFAEnabled.toString());
          this.emitHas2FA(this.loginForm.value);
          this.setLoading(false);
          return;
        }

        // Start jwt refresh so token doesn't run out while site in use
        this.authService.startRefreshJWTJob();

        this.emitLoggedIn(response.data.loginProperties);
      },
      error: (error) => {
        this.alertService.emitErrorAlert(error.error?.message ?? 'Login failed. Please try again');
        this.setLoading(false);
      },
    });
  }

  private weakPasswordReset(): void {
    this.setLoading(true);

    if (localStorage.getItem('siteId')) {
      this.selectedSiteId = +localStorage.getItem('siteId');
    }

    this.authService
      .generateActivationCode(
        this.loginForm.get('email').value,
        this.loginForm.get('password').value,
        this.selectedSiteId.toString()
      )
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: (response) => {
          this.router.navigate([`reset-password/too-weak/${response.data.activationCode}`], {
            relativeTo: this.activatedRoute.parent,
          });
        },
        error: (error) => {
          this.alertService.emitErrorAlert(error.message ?? 'Login failed');
        },
      });
  }

  public switchToResetPassword(): void {
    this.switchToResetPasswordEmitter.emit();
  }

  private emitLoggedIn(loginProperties: LoginProperties): void {
    this.loggedInEmitter.emit({ loginProperties, loginCredentials: this.loginForm.value });
  }

  private emitHas2FA(attemptLoginParams: AttemptLoginParams): void {
    this.has2FAEmitter.emit(attemptLoginParams);
  }

  private setLoading(isLoading: boolean): void {
    this.isLoadingEmitter.emit(isLoading);
  }

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
