<div class="modal-veil" (click)="closeModal()">
  <div id="modalContent" class="modal-container" (click)="$event.stopPropagation()">
    <h2 class="underline">
      <ng-content select="[title]"></ng-content>
    </h2>
    <div class="clear"></div>
    <ng-content select="[content]" class="test"></ng-content>

    <button *ngIf="!loading" class="modal-btn-close" (click)="closeModal()"></button>
  </div>
</div>
