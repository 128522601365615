import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupService } from '@sportinsight/src/app/services/signup.service';
import { AlertService } from '@shared/services/alert.service';
@Component({
  selector: 'app-signup-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss'],
})
export class SignUpSuccessComponent implements OnInit {
  constructor(
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly signupService: SignupService,
    private readonly alertService: AlertService,
  ) {}

  public responseTag: string;
  public paymentMethod: number = 1;
  public loading = true;

  ngOnInit(): void {
    if(!localStorage.getItem('responseTag') || !localStorage.getItem('prevouslyCreatedUserId')) {
      this.alertService.emitErrorAlert('Signup could not be completed. Please try again.');
      this.router.navigate(['/login']);
    }
    this.responseTag = localStorage.getItem('responseTag');
    this.route.queryParams.subscribe((params) => {
      this.paymentMethod = params?.method;
    });

    if(this.paymentMethod){
      if(this.paymentMethod == 1) {
        this.signupService
        .finalizeSignup(
            this.responseTag,
            localStorage.getItem('prevouslyCreatedUserId'),
            this.paymentMethod,
            localStorage.getItem('signupSiteId'),
            localStorage.getItem('selectedMembership'),
          )
        .subscribe({
          next: (response) => {
            this.loading = false;
          },
          error: () => {
            this.loading = false;
            this.router.navigate(['/login']);
            this.alertService.emitErrorAlert('Signup could not be completed. Please try again.');
          }
        });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.router.navigate(['/login']);
      this.alertService.emitErrorAlert('Signup could not be completed. Please try again.');
    }

    localStorage.removeItem('paymentType');
    localStorage.removeItem('responseTag');
    localStorage.removeItem('prevouslyCreatedUserId');
    localStorage.removeItem('signupSiteId');
    localStorage.removeItem('selectedMembership');
    localStorage.removeItem('signupStage');
    localStorage.removeItem('prospectiveMemberId');
    localStorage.removeItem('prevouslyCreatedUser');
  }
}
