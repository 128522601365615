
<ng-template #loadingSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>




<div *ngIf="!loading; else loadingSpinner">
  <div class="headerContainer" [ngSwitch]="paymentMethod">
    <h1 *ngSwitchCase="2">Direct debit payment successfull</h1>
    <h1 *ngSwitchCase="1">You have selected BACS</h1>
    <h1 *ngSwitchCase="3">Card payment successfull</h1>
  </div>
  <div class="contentContainer" [ngSwitch]="paymentMethod">
    <p *ngSwitchCase="1">
      Signup complete! <span *ngIf="responseTag !== 'EXISTING_USER_DIFFERENT_NAME' && responseTag !== 'EXISTING_USER_DIFFERENT_NAME_ARCHIVED' && responseTag !== 'EXISTING_USER_ARCHIVED'">- Check your email for confirmation and next steps.</span>
    </p>
    <p *ngSwitchCase="2">
      Signup complete! <span *ngIf="responseTag !== 'EXISTING_USER_DIFFERENT_NAME' && responseTag !== 'EXISTING_USER_DIFFERENT_NAME_ARCHIVED' && responseTag !== 'EXISTING_USER_ARCHIVED'">- Check your email for confirmation and next steps.</span>
    </p>
    <p *ngSwitchCase="3">
      Signup complete! <span *ngIf="responseTag !== 'EXISTING_USER_DIFFERENT_NAME' && responseTag !== 'EXISTING_USER_DIFFERENT_NAME_ARCHIVED' && responseTag !== 'EXISTING_USER_ARCHIVED'">- Check your email for confirmation and next steps.</span>
    </p>
  </div>
</div>

