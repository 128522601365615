<app-alert [loginScreen]="true"></app-alert>
<header>
  <div class="logo">
    <a href="#" class="logo-container"
      ><img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>
</header>

<main class="login-container">
  <section class="login-form">
    <app-login-template [loading]="loading">
      <div heading>Set up two-factor authentication</div>
      <div content>
        <ng-template #twoFASetupContainer>
          <div class="two-FA-container">
            <ng-template #twoFASetupForm>
              <p class="two-FA-desc auth-app-links-container">
                All you need is a compatible app on your mobile, for example:
                <span class="auth-app-link">- Google Authenticator</span>
                <span class="auth-app-link">- Duo Mobile</span>
                <span class="auth-app-link">- Authy</span>
              </p>
              <div class="two-FA-QR-code-container">
                <p class="small-bottom-margin">Scan below using your app.</p>
                <div class="two-FA-QR-code">
                  <ngx-qrcode [value]="qrCodeUrl" cssClass="QR-code-contents"> </ngx-qrcode>
                </div>
                <div class="cannot-scan-QR-prompt">
                  <a
                    *ngIf="!cannotScanQRPrompt; else cannotScanQRForm"
                    (click)="cannotScanQRPrompt = !cannotScanQRPrompt"
                    class="cannot-scan-QR-prompt-toggle clickable"
                  >
                    Can't scan QR code?
                  </a>
                  <ng-template #cannotScanQRForm>
                    <p class="manual-2FA-setup-desc">Input the following into your app.</p>
                    <p class="manual-2FA-setup-field">
                      Account name:<span class="field-contents">Sport Insight ({{ email }})</span>
                    </p>
                    <p class="manual-2FA-setup-field">
                      Your key/activation code:<span class="field-contents">{{ secretKey }}</span>
                    </p>
                    <p class="manual-2FA-setup-field">
                      Type:<span class="field-contents">Time based</span>
                    </p>
                  </ng-template>
                </div>
              </div>
              <button class="btn" id="btnProceedTo2FA" (click)="display2FAForm = true">
                Check two factor authentication
              </button>
            </ng-template>

            <ng-container *ngIf="displayRecoveryCodes; else twoFASetupForm">
              <h1 class="two-FA-title">Recovery codes</h1>
              <p class="two-FA-desc">
                Recovery codes are backup codes to access your account if you lose you cannot access
                the codes from your two factor authentication app.
              </p>
              <div class="recovery-codes-container">
                <p class="recovery-codes-desc">
                  Please copy these codes and save them somewhere secure. They should be easily
                  accessible, even if you lose your phone.
                </p>
                <ul class="recovery-codes">
                  <li *ngFor="let code of recoveryCodes">{{ code }}</li>
                </ul>
                <button
                  [cdkCopyToClipboard]="recoveryCodes.join('\r\n')"
                  [cdkCopyToClipboardAttempts]="5"
                  class="btn copy-btn"
                >
                  Copy codes
                </button>
              </div>

              <button (click)="continue()" class="btn verify-two-factor-btn">Continue</button>
            </ng-container>
          </div>
        </ng-template>
        <ng-container *ngIf="display2FAForm; else twoFASetupContainer">
          <app-two-factor-authentication
            (activatedEmitter)="setDisplayRecoveryCodes($event)"
            (isLoadingEmitter)="setLoading($event)"
          ></app-two-factor-authentication>
        </ng-container>
      </div>
    </app-login-template>
  </section>
</main>
