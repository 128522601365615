<div class="prompt-wrapper" *ngIf="displayPrompt">
  <div (click)="closePrompt()" class="close-prompt-container">
    <img class="close-prompt" src="/assets/images/icons/icon-close.svg"/>
  </div>

  <img class="app-logo" src="/assets/images/icons/icon-sport-insight-app.png" />
  <div class="text-container">
    <h2 class="app-title">Sport Insight: Book courts or whatever</h2>
    <p class="app-subtitle">Health & Fitness</p>
  </div>
  <a href="sportinsight://">
    <button alt="OPEN">OPEN</button>
  </a>
</div>
