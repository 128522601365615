<app-modal (closeModalEmitter)="closeModal()" (keyup.enter)="onConfirm()">
  <div title>{{ title }}</div>

  <div content>
    <ng-template #spinner>
      <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    </ng-template>

    <ng-container *ngIf="!loading; else spinner">
      <p [innerHTML]="message" (click)="processLinks($event)"></p>
      <div class="button-options-container">
        <button *ngIf="cancelDisplaySubmit" (click)="closeModal()" type="button" class="btn">{{ cancelOption }}</button>
        <button *ngIf="confirmationDisplaySubmit" (click)="onConfirm()" type="button" class="btn black" [ngClass]="{black: useCase === 'CANCEL_TRANSFER'}">
          {{ proceedOption }}
        </button>
      </div>
    </ng-container>
  </div>
</app-modal>
