<div class="login-form-container">
  <form [formGroup]="loginForm" (ngSubmit)="attemptLogin()" (keyup.enter)="attemptLogin()">
    <fieldset>
      <span class="error-message" *ngIf="email.touched && email.invalid"
        >Please enter a valid email</span
      >
      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          class="form-control"
          id="email-login"
          formControlName="email"
          placeholder="Email"
          required
        />
      </div>
      <div class="clear"></div>
      <div class="clear"></div>
      <span class="error-message" *ngIf="password.invalid && password.touched"
        >Password cannot be empty</span
      >
      <div class="form-group">
        <label>Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          formControlName="password"
          placeholder="Password"
          required
        />
        <div class="clear"></div>

        <!-- footer -->
        <div class="login-footer-container">
          <label class="check-selector" for="rememberMeSelector"
            >Remember me
            <input formControlName="rememberMe" type="checkbox" id="rememberMeSelector" />
            <span class="checkmark remember-me-checkbox"></span>
          </label>
          <!-- reset Password prompt -->
          <a class="clickable base-link" (click)="switchToResetPassword()">Forgot your password?</a>
        </div>
      </div>
      <div class="clear"></div>

      <button class="btn black" id="btn_login" type="submit">Login</button>
    </fieldset>
  </form>
</div>
