import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss'],
})
export class LoginTemplateComponent {
  @Input() public readonly loading: boolean = false;
}
