import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse, Site } from '@shared/definitions';
import { environment } from '@shared/environment';
import { HttpParamsService } from '@shared/services/http-params.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    private readonly http: HttpClient,
    private readonly httpParamsService: HttpParamsService
  ) { }

  public getSiteActivity(data: GetSiteActivityParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/site/activity`;
    const params = this.httpParamsService.getHttpParams(data);
    return this.http.get<APIResponse>(url, { params });
  }

  public changeSite(site: Site) {
    localStorage.setItem('siteId', site.siteId.toString());
    window.location.href = `/${site.urlTag}/${site.type.siteTypeLabel.toLowerCase()}`;
  }
}

export type GetSiteActivityParams = {
  fromDate: string;
  toDate: string;
};
