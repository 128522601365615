import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../sub-projects/club-members/src/app/services/booking.service';
import { finalize } from 'rxjs';
@Component({
  selector: 'app-accept-booking-transfer',
  templateUrl: './accept-booking-transfer.component.html',
  styleUrls: ['./accept-booking-transfer.component.scss'],
})
export class AcceptBookingTransferComponent implements OnInit {
  public token: string;
  public error: boolean = true;
  public transferErrorMessage: string = 'The transfer request for this booking is no longer valid. Please contact the booking owner if you wish the request to be made again.';
  public loading = true;
  public choice:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private bookingService: BookingService
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.choice = this.activatedRoute.snapshot.paramMap.get('transfer');

    this.activatedRoute.queryParams.subscribe((params) => {
      if (this.choice === 'ACCEPT') {
        this.acceptBookingTransfer();
      } else {
        this.declineBookingTransfer();
      }
    });
  }

  public acceptBookingTransfer(): void {
    this.bookingService
      .acceptBookingTransfer(this.token)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.error = false;
        },
        error: (error) => {
          this.error = true;
        },
      });
  }

  public declineBookingTransfer(): void {
    this.bookingService
      .declineBookingTransfer(this.token)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.error = false;
        },
        error: (error) => {
          this.error = true;
        },
      });
  }

  public goToSite(): void {
    this.router.navigateByUrl('homepage');
  }
}
