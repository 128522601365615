<header>
  <div class="logo">
    <a href="#" class="logo-container">
      <img src="assets/images/misc/sport-insight-logo.svg" alt="Sport Insight" />
    </a>
  </div>
</header>

<main class="login-container">
  <ng-template #loadingSpinner>
    <div class="content-container">
      <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
      <span class="light-tag">Loading ...</span>
    </div>
  </ng-template>

  <ng-container *ngIf="!loading; else loadingSpinner">
    <ng-template #transferError>
      <app-login-template>
        <div heading>Booking could not be transferred</div>
        <div content>
          <div class="transfer-content-container">
            <h3>Failed to transfer</h3>
            <p>{{ transferErrorMessage }}</p>
            <button class="btn" (click)="goToSite()">Go to Sport Insight</button>
          </div>
        </div>
      </app-login-template>
    </ng-template>

    <app-login-template *ngIf="!error; else transferError">
      <div *ngIf="choice === 'ACCEPT'" heading>Booking Transfer Accepted</div>
      <div *ngIf="choice === 'DECLINE'" heading>Booking Transfer Declined</div>
      <div content>
        <div class="transfer-content-container">
          <h3>Success!</h3>
          <p *ngIf="choice === 'ACCEPT'">The booking has now been transferred to you.</p>
          <p *ngIf="choice === 'DECLINE'">The booking transfer request has now been declined.</p>
          <button class="btn" (click)="goToSite()">Go to Sport Insight</button>
        </div>
      </div>
    </app-login-template>
  </ng-container>
</main>
