<ng-template #loginSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="slider-container">
  <app-slider-toggle (toggleStateEmitter)="toggleTerm($event)"></app-slider-toggle>
</div>

<div class="container" *ngIf="!loading; else loginSpinner">
  <ng-container *ngFor="let option of membershipOptions">
    <div
      class="membership-option-card"
      *ngIf="option.paymentOptions[0] && getPaymentOptionInfo(option, termToggle ? 1 : 2)"
      (click)="selectOption(option.productId)"
      [ngClass]="{ selected: option.productId === selectedMembership }"
    >
      <div class="card-title">
        <h3>{{ option.name }}</h3>
      </div>

      <div class="payment-options" [ngClass]="{ selected: option.productId === selectedMembership }">
        <ng-container *ngIf="!termToggle">
          <p class="price">£{{getPaymentOptionInfo(option, 2).amount}}</p>
          <p>per month</p>
          <p class="joining-fee">+ £{{getPaymentOptionInfo(option, 2).joining_fee}} joining fee</p>
        </ng-container>
        <ng-container *ngIf="termToggle">
          <p class="price">£{{getPaymentOptionInfo(option, 1).amount}}</p>
          <p>per year</p>
          <p class="joining-fee">+ £{{getPaymentOptionInfo(option, 1).joining_fee}} joining fee</p>
        </ng-container>
      </div>


      <div class="descriptorContainer" [ngClass]="{ selected: option.productId === selectedMembership }">
        <div class="descriptor" *ngFor="let descriptor of getDescriptors(option.descriptors)">
          <span></span>
          <p>{{descriptor}}</p>
        </div>

      </div>

      <img *ngIf="option.productId !== selectedMembership" src="/assets/images/icons/icon-check-unfilled.svg" alt="" />
      <img *ngIf="option.productId === selectedMembership" src="/assets/images/icons/icon-check-filled.svg" alt="" />
    </div>
  </ng-container>
</div>

<p class="membership-info">Membership runs from 1st Apr - 31st March so membership fees will be pro-rata'd on the payment page to take you up unti renewal.</p>
