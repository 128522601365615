import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    // read the input text and convert into a readable date
    if (!this.validCheck(value)){
      return this.invalid();
    }
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const str = value.split('/');

      let year: number;
      if (str[2]?.length === 2 && parseInt(str[2]) > 29) {
        year = Number(`19${str[2]}`);
      } else if (str[2]?.length === 2) {
        year = Number(`20${str[2]}`);
      } else {
        // they've entered a 4 digit date
        year = Number(str[2]);
      }

      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);

    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      // format the date text to place in the input box
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${this.to2digit(day)}/${this.to2digit(month)}/${year}`;
    }
    return date.toDateString();
  }

  private to2digit(n: number): string {
    return ('00' + n).slice(-2);
  }

  validCheck(value: any): boolean {
    if (value){
      // checks format is dd/mm/yyyy
      const regex: RegExp = new RegExp('^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((19|20)\\d\\d)$');
      const checkFormat = regex.test(value);
      if (checkFormat){
        // checks for valid date (e.g. won't allow 31/06/2000)
        const bits = value.split('/');
        const d = new Date(bits[2], bits[1] - 1, bits[0]);
        return d.getFullYear() === +bits[2] && d.getMonth() + 1 === +bits[1];
      }
      return false;
    }
    return true;
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'dateWithDay',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
