import { formatDate } from '@angular/common';

export class DateHelper {
  public static monthNamesFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public static monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  public static dayNamesFull = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  public static suffixes = [
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st"
  ];

  private static readonly _days: number[] = [];

  public static getFormattedDateShort(date: Date): string {
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
  }

  public static getFormattedDate(date: string): string {
    const tempDate = new Date(date);

    return `${tempDate.getDate()}${this.suffixes[tempDate.getDate() - 1]} ${tempDate.toLocaleString('en-GB', { month: "long" })}`;
  }

  static get days(): number[] {
    if (this._days.length) {
      return this._days;
    }

    for (let i = 1; i <= 31; ++i) {
      this._days.push(i);
    }

    return this._days;
  }

  public static addLeadingZerosToDate(input: string): string {
    let output = '';

    if (input && input.length) {
      const parts = input.split('/');

      if (parts.length >= 2) {
        const date = new Date(2000, +parts[1] - 1, +parts[0]);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        output = `${day}/${month}`;
      }
    }

    return output;
  }

  public static getMonthNumberByShortName(strMonth: string): number {
    return this.monthNamesShort.indexOf(strMonth) + 1;
  }

  public static getShortFormattedDate(input: string): string {
    if (input && input.length) {
      const date = this.formatDateFromDateMonthString(input);

      if (date) {
        const day = date.getDate();
        const month = this.monthNamesShort[date.getMonth()];
        return `${day} ${month}`;
      }
    }
    return input;
  }

  /**
   * Accepts a string of format dd/mm and converts it to a javascript date
   *
   * @param string input The date string
   */
  public static formatDateFromDateMonthString(input: string, year?: any): Date {
    const parts = input.split('/');

    if (parts.length >= 2) {
      if (!year) {
        year = 2000;
      }
      return new Date(year, +parts[1] - 1, +parts[0]);
    }

    return null;
  }

  public static todaysDate(separator = '/'): string {
    const today = new Date();

    let dd = today.getDate().toString();
    const mm = this.monthNamesShort[today.getMonth()];
    const yyyy: any = today.getFullYear();

    if (+dd < 10) dd = `0${dd}`;

    return `${dd}${separator}${mm}${separator}${yyyy}`;
  }

  public static get tomorrowsDate(): Date {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  public static addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  public static addMonths(date: Date, months: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  }

  public static addYears(date: Date, years: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  }

  public static convertDateObjectToString(input: {
    day: number;
    month: number;
    year: number;
  }): string {
    let day = `${input.day}`;
    let month = `${input.month}`;
    const year = `${input.year}`;

    if (input.day <= 9) {
      day = `0${day}`;
    }
    if (input.month <= 9) {
      month = `0${month}`;
    }

    return `${day}/${month}/${year}`;
  }

  public static getZeroAppendedDateFromString(input: string): string {
    const parts = input.split('/');

    if (+parts[0] < 10 && !parts[0].includes('0')) {
      parts[0] = `0${parts[0]}`;
    }
    if (+parts[1] < 10 && !parts[1].includes('0')) {
      parts[1] = `0${parts[1]}`;
    }

    return `${parts[0]}/${parts[1]}/${parts[2]}`;
  }

  /**
   * @param date Date string in format dd/mm/yyyy
   * @return Date string in format yyyy-mm-dd
   */
  public static slashStringToDB(date: string, padZeroes = true): string {
    if (!date) return '';

    const dateArray = date.split('/');

    if (padZeroes) {
      if (dateArray[0].length === 1) {
        dateArray[0] = `0${dateArray[0]}`;
      }

      if (dateArray[1].length === 1) {
        dateArray[1] = `0${dateArray[1]}`;
      }
    }

    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }

  /**
   * @param date Date string in format yyyy-mm-dd
   * @return Date string in format dd/mm/yyyy
   */
  public static dbToSlashString(date: string): string {
    return date.split(' ')[0].split('-').reverse().join('/');
  }

  /**
   * @param date Date string in format dd/mm/yyyy
   * @return Date object
   */
  public static slashStringToObject(date: string): Date {
    if (date) return new Date(this.slashStringToDB(date));

    return null;
  }

  /**
   * @param date Date object
   * @return Date string in format dd/mm/yyyy
   */
  public static objectToSlashString(date: Date, addPadding = true): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (addPadding) {
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    }

    return `${day}/${month}/${year}`;
  }

  /**
   * @param date Date object
   * @return Date string in format yyyy-mm-dd
   */
  public static objectToDB(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  /**
   * @param date Date object
   * @return Date parts object
   */
  public static objectToParts(date: Date): DateParts {
    if (!date) return null;

    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  public static objectToDaySlashMonth(date: Date): string {
    if (!date) return null;

    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
  }

  /**
   * @param date Date parts object
   * @return Date string in format yyyy-mm-dd
   */
  public static datePartsToDB(date: DateParts): string {
    if (date === undefined) return undefined;

    const { year, month, day }: DateParts = date;
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  /**
   * @param date DateParts object
   * @return Date object
   */
  public static datePartsToObject(date: DateParts): Date {
    return new Date(date.year, date.month, date.day);
  }

  public static dateStringToObject(dateString: string): Date {
    return new Date(dateString);
  }

  public static getWeekDayNameByIndex(i: number): string {
    return this.dayNamesFull[i];
  }

  public static stringToNumber(date: string): number {
    const newDate = new Date(date);
    return newDate.getTime();
  }

  public static todayAsNumber(): number {
    const today = new Date();
    return today.setHours(0, 0, 0, 0);
  }

  public static datesAreOnSameDay(first: Date, second: Date): boolean {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  }

  public static dateToOrdinalString(date: Date): string {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const weekDay = weekDays[date.getDay()];
    const d = `${date.getDate()}${this.nth(date.getDate())}`;
    const m = months[date.getMonth()];
    const y = date.getFullYear();
    const t = formatDate(date, 'hh:mma', 'en-US').toLowerCase();

    return `${weekDay} ${d} ${m} ${y} ${t}`;
  }

  private static nth(d: number): string {
    if (d > 3 && d < 21) return 'th';

    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}

export type DateParts = {
  day: number;
  month: number;
  year: number;
};
