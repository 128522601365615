import { interval, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@sportinsight-services/auth.service';
import { APIResponse } from '@shared/definitions';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@shared/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance-page.html',
  styleUrls: ['./maintenance-page.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  public defaultSiteLogo = `${environment.cloudfrontUrl}/brand/sport-insight-logo.svg`;
  private pollingSubscription: Subscription;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    const source = interval(5000);
    this.pollingSubscription = source.subscribe({
      next: () => {
        this.authService.checkMaintenance().subscribe({
          next: (response: APIResponse) => {
            this.router.navigateByUrl('homepage');
          },
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.pollingSubscription?.unsubscribe();
  }
}
