import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { Subscription } from 'rxjs';
import { AppDateAdapter, APP_DATE_FORMATS } from './datepicker.adapter';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DatepickerComponent,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ],
})
export class DatepickerComponent implements Validator, ControlValueAccessor, OnInit, OnDestroy {
  @Input() public readonly datepickerError: boolean = false;
  @Input() public readonly datepickerWarning: boolean = false;
  @Input() public readonly errorMessage: string = null;
  @Input() private readonly required: boolean = false;
  @Input() public readonly minDate: Date;
  @Input() public readonly maxDate: Date;
  @Input() public readonly dateFilter: (d: Date) => boolean;
  @Input() public placeholder = 'Select date';
  @Input() public readonly canClear: boolean = false;
  @Input() public readonly allowTyping: boolean = false;

  private readonly subscription = new Subscription();

  constructor(@Inject(MAT_DATE_FORMATS) private dateFormats) { }

  public datePickerControl = new FormControl([]);
  public onTouched: () => void = () => {};
  public onValidationChange: any = () => {};

  ngOnInit(): void {
    if (!this.allowTyping) {
      this.dateFormats.display.dateInput = 'dateWithDay';
    } else {
      this.dateFormats.display.dateInput = 'input';
    }
    this.onValidationChange();
    if (this.required) this.datePickerControl.setValidators([Validators.required]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * When the form's value written to. Implemented from the ControlValueAccessor interface
   * @param any obj
   * @return void
   */
  public writeValue(obj: any): void {
    this.datePickerControl.patchValue(obj, { emitEvent: false });
  }

  /**
   * When the form's value is changed. Implemented from the ControlValueAccessor interface
   * @param any fn
   * @return void
   */
  public registerOnChange(fn: any): void {
    this.subscription.add(this.datePickerControl.valueChanges.subscribe({ next: fn }));
  }

  /**
   * When the form is touched. Implemented from the ControlValueAccessor interface
   * @param any fn
   * @return void
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Whether the form is disabled. Implemented from the ControlValueAccessor interface
   *
   * @param boolean isDisabled
   * @return void
   */
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.datePickerControl.disable({ emitEvent: false })
      : this.datePickerControl.enable({ emitEvent: false });
  }

  /**
   * Checks if the form control has a value
   * @return boolean
   */
  public valueExists(): boolean {
    return !!this.datePickerControl.value;
  }

  public clearDate(): void {
    if (!this.canClear) return;
    this.datePickerControl.patchValue(null);
  }

  public validate(): any {
    return this.datePickerControl.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
