import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../sub-projects/shared/environment';
import { HttpParamsService } from '../../sub-projects/shared/services/http-params.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  private readonly httpSkip: HttpClient;

  constructor(
    private readonly http: HttpClient,
    private readonly handler: HttpBackend,
    private readonly httpParamsService: HttpParamsService
  ) {
    // httpSkip provides a HttpClient instance that skips http interceptors - doesn't try to append JWT
    this.httpSkip = new HttpClient(handler);
  }

  ngOnInit(): void {}

  public testAppLogin(): void {
    const params = { email: 'jamesscully@gmail.com', password: 'Test123!' };
    const url = `${environment.apiURL}/app/auth/login`;
    this.httpSkip.post(url, params).subscribe({
      next: (response: { data: { token: string } }) => {
        localStorage.setItem('JWT', response.data.token);
      },
    });
  }

  public testAppSiteSelect(): void {
    localStorage.setItem('siteId', '2');
    const url = `${environment.apiURL}/app/auth/selected-site`;
    this.http.get(url).subscribe();
  }
}
