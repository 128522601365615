import { HttpBackend, HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@shared/environment';
import { Observable } from 'rxjs';
import { AuthService, BYPASS_TOKEN_CHECK } from '../../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ForgottenPasswordService {
  private readonly httpSkip: HttpClient;

  constructor(
    private readonly http: HttpClient,
    private readonly handler: HttpBackend,
    private readonly jwtHelper: JwtHelperService
  ) {
    // httpSkip provides a HttpClient instance that skips http interceptors - doesn't try to append JWT
    this.httpSkip = new HttpClient(handler);
  }

  public sendMemberPasswordResetEmail(params: SendResetPasswordEmailParams): Observable<void> {
    const url = `${environment.apiURL}/user/reset-password-email`;
    return this.http.post<void>(url, params);
  }

  public resendPasswordResetEmail(params): Observable<void> {
    const url = `${environment.apiURL}/user/resend-password-email`;
    return this.httpSkip.post<void>(url, params);
  }

  public sendAdminPasswordResetEmail(params: SendResetPasswordEmailParams): Observable<void> {
    const url = `${environment.apiURL}/admin/reset-password-email`;
    return this.http.post<void>(url, params);
  }

  public forgottenResetPassword(params: ForgottenPasswordParams): Observable<void> {
    const url = `${environment.apiURL}/user/reset-password`;
    return this.http.put<void>(url, {
      params,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public checkIfTokenExpired(params: ResetPasswordTokenCheckParams): Observable<void> {
    const url = `${environment.apiURL}/user/check-password-token`;
    return this.httpSkip.post<void>(url, params);
  }

  public changePassword(params: ChangePasswordParams): Observable<void> {
    const url = `${environment.apiURL}/user/change-password`;
    return this.http.put<void>(url, { params });
  }

  public changePin(params: ChangePinParams): Observable<void> {
    const url = `${environment.apiURL}/user/change-pin`;
    return this.http.put<void>(url, { params });
  }
}

export type ForgottenPasswordParams = {
  password: string;
  token: string;
  newLogin?: boolean;
};

export type ResetPasswordTokenCheckParams = {
  token: string;
};

export type ChangePasswordParams = {
  password: string;
  email: string;
};

export type ChangePinParams = {
  password: string;
  pin: number;
};

export type SendResetPasswordEmailParams = {
  loginId?: number;
  loginEmail?: string;
  userId?: number;
};
