import { HttpBackend, HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GenericAPIResponse, VoidAPIResponse, Address } from '@shared/definitions';
import { environment } from '@shared/environment';
import { Observable } from 'rxjs';
import { HttpParamsService } from '../sub-projects/shared/services/http-params.service';
import { UserService } from '../sub-projects/shared/services/user.service';
@Injectable({
  providedIn: 'root',
})
export class SignupService {
  private readonly httpSkip: HttpClient;
  public refreshJWTRequired = false;

  constructor(
    private readonly http: HttpClient,
    private readonly handler: HttpBackend,
    private readonly jwtHelper: JwtHelperService,
    private readonly httpParamsService: HttpParamsService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    // httpSkip provides a HttpClient instance that skips http interceptors - doesn't try to append JWT
    this.httpSkip = new HttpClient(handler);
  }

  public getStripeKey(siteId: number): Observable<GenericAPIResponse<any>> {
    const params = this.httpParamsService.getHttpParams({ siteId });
    const url = `${environment.apiURL}/payments/stripe/stripe-key`;
    return this.httpSkip.get<GenericAPIResponse<any>>(url, {
      params,
    });
  }

  public finalizeSignup(responseTag, userId, paymentType, siteId, selectedMembership): Observable<GenericAPIResponse<any>> {
    const params = this.httpParamsService.getHttpParams({ responseTag, userId, paymentType, siteId, selectedMembership });
    const url = `${environment.apiURL}/signup/finalize-signup`;
    return this.httpSkip.get<GenericAPIResponse<any>>(url, {
      params,
    });
  }

  public getMembershipOptions(siteId: number): Observable<GenericAPIResponse<MembershipOptions>> {
    const params = this.httpParamsService.getHttpParams({ siteId });
    const url = `${environment.apiURL}/signup/membership-options`;
    return this.httpSkip.get<GenericAPIResponse<MembershipOptions>>(url, {
      params,
    });
  }

  public createProspectiveUser(
    params: ProspectiveUserDetails
  ): Observable<GenericAPIResponse<CreateMemberResponse>> {
    const url = `${environment.apiURL}/signup/prospective-user/create`;
    return this.httpSkip.post<GenericAPIResponse<CreateMemberResponse>>(url, {
      params,
    });
  }

  public confirmCreateProspectiveUser(
    params: ProspectiveUserDetails
  ): Observable<GenericAPIResponse<CreateMemberResponse>> {
    const url = `${environment.apiURL}/signup/prospective-user/confirm-create`;
    return this.httpSkip.post<GenericAPIResponse<CreateMemberResponse>>(url, {
      params,
    });
  }

  public getPreviouslyCreatedProspectiveUser(
    params
  ): Observable<GenericAPIResponse<ProspectiveUserDetails>> {
    const url = `${environment.apiURL}/signup/prospective-user/previous`;
    return this.httpSkip.get<GenericAPIResponse<ProspectiveUserDetails>>(url, {
      params,
    });
  }

  public addAddress(
    entityId: number,
    entityType: string,
    addressForm: Address
  ): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/address/add`;
    const load = { entityId, entityType, addressForm };
    return this.httpSkip.post<VoidAPIResponse>(url, load);
  }

  public getSiteId(URL: string): Observable<GenericAPIResponse<SiteId>> {
    const params = this.httpParamsService.getHttpParams({ URL });
    const url = `${environment.apiURL}/signup/site-id`;
    return this.httpSkip.get<GenericAPIResponse<SiteId>>(url, {
      params,
    });
  }

  public autocompleteAddressLookup(addressSearch: string): Observable<AddressSuggestionReponse> {
    const url = `https://api.getAddress.io/autocomplete/${addressSearch}?api-key=${environment.addressAPIKey}`;
    return this.httpSkip.get<AddressSuggestionReponse>(url, {
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public getFullAddressSuggestion(addressId: string): Observable<FullAddressSuggestion> {
    const url = `https://api.getAddress.io/get/${addressId}?api-key=${environment.addressAPIKey}`;
    return this.httpSkip.get<FullAddressSuggestion>(url, {
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }
}

export const BYPASS_TOKEN_CHECK = new HttpContextToken(() => false);

export enum PaymentTitles {
  DD = 'By Direct Debit',
  CARD = 'By Card',
  BACS = 'By BACS',
}

export type FullAddressSuggestion = {
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  postcode: string;
  country: string;
  town_or_city: string;
};

export type CreateMemberResponse = {
  userId: number;
  tag: string;
};

export type AddressSuggestionReponse = {
  suggestions: AddressSuggestion[];
};

export type AddressSuggestion = {
  address: string;
  id: string;
  url: string;
};

export type MembershipOptions = {
  siteId: number;
  urlTag: string;
  siteLogoURL?: string;
  paymentOptions: MemberOptions[];
};

export type SiteId = {
  siteId: number;
};

export type MemberOptions = {
  name: string;
  amount: string;
  productId: number;
  paymentOptions: PaymentOption[];
  descriptors: string;
};

export type PaymentOption = {
  amount: string;
  id: number;
  joining_fee: number;
  payment_term_id: number;
  product_id: number;
  siteId: number;
  name: {
    name: string;
  };
};

export type ProspectiveUserDetails = {
  dateOfBirth: string;
  workEmail: string;
  firstName: string;
  lastName: string;
  workPhone: string;
  user_addresses: ProspectiveUserAddress[];
  responseTag?: string;
};

export type ProspectiveUserAddress = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  city: string;
  country: string;
  postcode: string;
};

export type BillingDetails = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  postcode: string;
};

export type BankDetails = {
  accountName: string;
  accountNumber: number;
  sortCode: number;
};
