import { Component, Input, OnInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from '@shared/services/payment.service';
import { loadStripe } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { finalize, firstValueFrom } from 'rxjs';
import { SignupService } from '@sportinsight/src/app/services/signup.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() redirectUrl: string;
  @Input() userId: number;
  @Input() productId: number;
  @Input() siteId: number;
  @Input() period: string = 'YEARLY';
  @Input() paymentMethod: number;

  @Output() public readonly paymentCompleteEmitter = new EventEmitter<boolean>();

  public stripe;
  public payment;
  private secret: string;
  public loading: boolean = true;
  private returnUrl: string;
  public stripeKey: string;
  public responseTag: string;

  constructor(
    private readonly paymentService: PaymentService,
    private fb: FormBuilder,
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly signupService: SignupService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit(): Promise<void> {
    this.returnUrl = this.router.url;
    //If person has already made single payment, divert to subscription
    this.getStripeKey();
    this.responseTag = localStorage.getItem('responseTag');
  }

  public async getStripeKey() {
    this.signupService.getStripeKey(this.siteId).subscribe({
      next: (response) => {
        this.initStripe(response.data);
      },
      error: (error) => this.alertService.emitErrorAlert(error.error?.message),
    });
  }

  public async initStripe(key): Promise<void> {
    // Should be coming from the backend
    this.stripe = await loadStripe(key);
    this.createSinglePayment(this.userId, this.productId);
  }

  private createSinglePayment(userId: number, productId: number): void {
    this.paymentService.createPayment(userId, productId, this.siteId.toString(), this.period, this.paymentMethod, this.responseTag)
    .subscribe({
      next: async (response) => {
        this.payment = this.stripe.elements({
          clientSecret: response.data,
        });

        const card = this.payment.create('payment', {
          layout: 'tabs',
        });
        // Add an instance of the card UI component into the `card-element`
        await card.mount('#payment-element');
        this.loading = false;
      },
      error: (error) => this.alertService.emitErrorAlert(error.error?.message),
    });
  }

  public async pay(event): Promise<void> {
    event.preventDefault();
    this.loading = true;

    const { error } = await this.stripe.confirmPayment({
      elements: this.payment,
      redirect: 'if_required',
      confirmParams: {
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        return_url: `${this.document.location.origin}/${this.returnUrl}&pay=success`,
      },
    });

    if (error) {
      this.loading = false;
      this.alertService.emitErrorAlert(error?.message);
    } else {
      if(localStorage.getItem('paymentType') === 'By Card') {
        const site = this.router.url.split('/')[1];
        window.location.href = `${this.document.location.origin}/${site}/member/signup?finalize=success&method=3`;
      }
      else {
        this.paymentCompleteEmitter.emit(true);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { pay: 'success' },
          queryParamsHandling: 'merge',
        });
      }
    }
  }
}
