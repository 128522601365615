import { HttpBackend, HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GenericAPIResponse, Site, VoidAPIResponse } from '@shared/definitions';
import { environment } from '@shared/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { SetupLoginParams } from '../components/setup-login/setup-login.component';
import { HttpParamsService } from '../sub-projects/shared/services/http-params.service';
import { BasicUserDetails, UserService } from '../sub-projects/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly httpSkip: HttpClient;

  private refreshJWTTimeout = null;
  public refreshJWTRequired = false;

  constructor(
    private readonly http: HttpClient,
    private readonly handler: HttpBackend,
    private readonly jwtHelper: JwtHelperService,
    private readonly httpParamsService: HttpParamsService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly gaService: GoogleAnalyticsService
  ) {
    // httpSkip provides a HttpClient instance that skips http interceptors - doesn't try to append JWT
    this.httpSkip = new HttpClient(handler);
  }

  /**
   * Retrieves the JWT token from local storage
   *
   * @return string - the JWT token
   */
  public get jwtToken(): string {
    return localStorage.getItem('JWT');
  }

  /**
   * Checks the current JWT token is valid
   *
   * @return boolean
   */
  public isAuthenticated(): IsAuthenticated {
    const token = this.jwtToken;

    if (token === 'undefined') {
      console.log('Make sure you are on the same branch ;) (and delete local storage)');
    }
    return {
      isAuthenticated: token ? !this.jwtHelper.isTokenExpired(token) : false,
      sessionTimeout: token && this.jwtHelper.isTokenExpired(token),
    };
  }

  public attemptLogin(payload: AttemptLoginParams): Observable<AttemptLoginResponse> {
    const url = `${environment.apiURL}/auth/attempt-login`;

    return this.http.post<AttemptLoginResponse>(url, payload, {
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public generateTwoFASecret(): Observable<{ qrCodeUrl: string }> {
    const url = `${environment.apiURL}/auth/generate-two-FA-secret`;
    return this.http.get<{ qrCodeUrl: string }>(url);
  }

  public activateTwoFA(twoFACode: string): Observable<{ recoveryCodesJson: string }> {
    const url = `${environment.apiURL}/auth/activate-two-FA`;
    return this.http.post<{ recoveryCodesJson: string }>(url, { twoFACode });
  }

  public disableTwoFA(): Observable<void> {
    const url = `${environment.apiURL}/auth/disable-two-FA`;
    return this.http.put<void>(url, {});
  }

  public loginWithTwoFA(params: AttemptLoginParams): Observable<AttemptLoginResponse> {
    const url = `${environment.apiURL}/auth/login-with-twoFA`;
    return this.http.post<AttemptLoginResponse>(url, params, {
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  /**
   * Logs out the current user
   *
   * @return Observable<APIResponse>
   */
  public logout(): void {
    const url = `${environment.apiURL}/auth/logout`;
    this.gaService.event('Logout', 'Click', 'user_logout');
    this.http.get<VoidAPIResponse>(url).subscribe({
      next: () => {
        this.userService.setLoggedInUser(null);
        this.stopRefreshJWTJob();
        const baseUrl = localStorage.getItem('baseUrl');
        localStorage.clear();
        this.router.navigateByUrl(`${baseUrl}/login`);
      },
    });
  }

  public generateActivationCode(
    email: string,
    password: string,
    siteId?: string
  ): Observable<GenericAPIResponse<{ activationCode: string }>> {
    const url = `${environment.apiURL}/auth/generate-activation-code`;
    return this.http.post<GenericAPIResponse<{ activationCode: string }>>(url, {
      email,
      password,
      siteId,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public setupLogin(params: SetupLoginParams): Observable<void> {
    const url = `${environment.apiURL}/auth/setup-login`;
    return this.http.post<void>(url, {
      ...params,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public getBasicUserDetails(token: string): Observable<GenericAPIResponse<BasicUserDetails>> {
    const params = this.httpParamsService.getHttpParams({ token });
    const url = `${environment.apiURL}/auth/basic-user-details`;
    return this.http.get<GenericAPIResponse<BasicUserDetails>>(url, {
      params,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public verifyEmail(token: string): Observable<GenericAPIResponse<{ email: string }>> {
    const url = `${environment.apiURL}/auth/verify-email`;
    return this.http.post<GenericAPIResponse<{ email: string }>>(url, {
      token,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  public checkURLValidity(URL: string): Observable<GenericAPIResponse<PreLoginSiteInfo>> {
    const params = this.httpParamsService.getHttpParams({ URL });
    const url = `${environment.apiURL}/auth/check-url-validity`;
    return this.http.get<GenericAPIResponse<PreLoginSiteInfo>>(url, {
      params,
      context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
    });
  }

  // Start jwt refresh so token doesn't run out while site in use
  public startRefreshJWTJob(): void {
    this.refreshJWTTimeout = setTimeout(() => (this.refreshJWTRequired = true), 1000 * 60 * 10); // 10 mins (ms * sec * min)
  }

  public stopRefreshJWTJob(): void {
    this.refreshJWTRequired = false;
    clearTimeout(this.refreshJWTTimeout);
  }

  public refreshJWT(headers: { Authorization: string; siteId?: string }): Promise<void> {
    const params = this.httpParamsService.getHttpParams({ URL });
    const url = `${environment.apiURL}/auth/refresh-jwt`;

    return new Promise<void>((resolve) => {
      this.http
        .get<GenericAPIResponse<{ JWT: string }>>(url, {
          headers,
          params,
          context: new HttpContext().set(BYPASS_TOKEN_CHECK, true),
        })
        .subscribe({
          next: (response) => {
            localStorage.setItem('JWT', response.data.JWT);

            this.refreshJWTRequired = false;
            this.startRefreshJWTJob();

            resolve();
          },
        });
    });
  }
  /**
   * Check if the app is in maintenance mode
   *
   * @return Observable<APIResponse>
   */
  public checkMaintenance(): Observable<VoidAPIResponse> {
    const url = `${environment.apiURL}/api/maintenance/check`;
    return this.http.get<VoidAPIResponse>(url);
  }
}

export const BYPASS_TOKEN_CHECK = new HttpContextToken(() => false);

export type IsAuthenticated = {
  isAuthenticated: boolean;
  sessionTimeout: boolean;
};

export type LoginProperties = {
  token: string;
  sites: Site[];
  authenticatedSite: Site;
};

export type AttemptLoginParams = {
  email: string;
  password: string;
  rememberMe: boolean;
  twoFACode?: string;
  siteId?: number;
};

export type AttemptLoginResponse = {
  data: {
    sportInsightUser: sportInsightUser
    loginProperties: LoginProperties;
    twoFAEnabled?: boolean;
  }
  twoFAEnabled?: boolean;
};

export type sportInsightUser = {
  firstName: string,
  lastName: string,
};

export type PreLoginSiteInfo = {
  siteId: number;
  urlTag: string;
  siteLogoURL?: string;
  clubName: string;
};
