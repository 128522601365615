<div class="sportinsight-homepage-container">
  <div class="login-options">
    <button (click)="logout()" class="btn login-option-btn">Logout</button>
    <a (click)="manageTwoFA()" class="clickable unselectable two-FA-prompt">
      {{ twoFAEnabled ? "Disable" : "Enable" }} two-factor authentication >
    </a>
  </div>
  <div class="sites-container">
    <div
      *ngFor="let site of sites"
      (click)="selectSite(site)"
      class="site-option clickable unselectable"
    >
      {{ site.siteName }}
    </div>
  </div>
</div>
