<div
  [class.login-page]="loginScreen"
  [class.clickable]="alert.url"
  [class.signup-page]="signupScreen"
  id="globalMessageContainer"
  *ngIf="alert.display"
  style="display: block"
  (click)="navToUrl()"
>
  <span
    class="alert animated fadeIn fadeOut"
    role="alert"
    [ngClass]="{
      success: alert.type === 1,
      error: alert.type === 2
    }"
  >
    {{ alert.message }}
  </span>
</div>
