import { BankDetailsComponent } from './bank-details/bank-details.component';
import { ConfirmDetailsComponent } from './confirm-details/confirm-details.component';
import { BillingDetailsComponent } from './personal-details/billing-details.component';
import { finalize } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { User } from '@sportinsight/src/app/sub-projects/shared/definitions';
import {
  PaymentService,
  SignUpProduct,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingDetails, BankDetails } from '@sportinsight/src/app/services/signup.service';

@Component({
  selector: 'app-custom-go-cardless-form',
  templateUrl: './custom-go-cardless-form.component.html',
  styleUrls: ['./custom-go-cardless-form.component.scss'],
})
export class CustomGoCardlessFormComponent implements OnInit, OnChanges {
  @Input() public siteId: number;
  @Input() public prospectiveMemberId: number;
  @Input() public selectedMembership: number;
  @Output() private readonly paymentCompleteEmitter = new EventEmitter<boolean>();

  @ViewChild(BillingDetailsComponent) billingDetailsComponent: BillingDetailsComponent;
  @ViewChild(BankDetailsComponent) bankDetailsComponent: BankDetailsComponent;
  @ViewChild(ConfirmDetailsComponent) confirmDetailsComponent: ConfirmDetailsComponent;

  public formStage: number = 3;
  public displayErrors = false;
  public nextDisabled: boolean = false;
  public billingDetails: BillingDetails;
  public bankDetails: BankDetails;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  // Handles the next button for all three sub pages
  public nextStage(): void {
    switch (this.formStage) {
      case 1:
        if (!this.billingDetailsComponent.checkFormValid()) {
          this.displayErrors = true;
          return;
        }
        this.billingDetails = this.billingDetailsComponent.form.value;
        break;

      case 2:
        if (!this.bankDetailsComponent.checkFormValid()) {
          this.displayErrors = true;
          return;
        }
        this.bankDetails = this.bankDetailsComponent.form.value;
        break;
    }

    this.displayErrors = false;
    this.formStage++;
  }

  public previousStage(): void {
    this.displayErrors = false;
    this.formStage--;
  }

  public setStage(val): void {
    this.displayErrors = false;
    this.formStage = val;
  }
}
