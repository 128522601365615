<div class="wrapper">
  <ng-template #loadingSpinner>
    <div class="content-container">
      <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
      <span class="light-tag">Loading ...</span>
    </div>
  </ng-template>

  <ng-container *ngIf="!loading; else loadingSpinner">
    <ng-template #errorMessage>
      <div class="headerContainer">
        <h2>Error fetching redirect URL</h2>
      </div>

      <div class="content-container" *ngIf="method !== 2">
        <p>
          There was an error fetching the url for go cardless. You will receive an email with
          instructions how to setup a sport insight account.
        </p>

        <p>Please contact and administrator.</p>
      </div>
    </ng-template>

    <ng-container *ngIf="!error; else errorMessage">
      <div class="headerContainer">
        <h2>Card payment successful</h2>
      </div>

      <div class="content-container" *ngIf="method !== 2">
        <p>You will now be redirected to GoCardless to setup your direct debit.</p>

        <p>If you are not redirected automatically please click the link below.</p>

        <div class="loading-container">
          <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
          <span class="light-tag">Redirecting to GoCardless</span>
        </div>

        <div class="redirect">
          <a href="{{ redirectUrl }}">
            {{ redirectUrl }}
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
