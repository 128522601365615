import { SignUpComponent } from './components/signup/signup.component';
import { SignUpSuccessComponent } from './components/signup/signup-success/signup-success.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/AuthGuard';
import { HomepageComponent } from './components/homepage/homepage.component';
import { TwoFactorAuthenticationSetupComponent } from './components/login/two-factor-authentication/two-factor-authentication-setup/two-factor-authentication-setup.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { SetupLoginComponent } from './components/setup-login/setup-login.component';
import { SiteGuard } from './guards/SiteGuard';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { NonProdGuard } from './guards/non-prod.guard';
import { FormStylingComponent } from './components/style-guide/form-styling/form-styling.component';
import { ButtonStylingComponent } from './components/style-guide/button-styling/button-styling.component';
import { TestComponent } from './components/test/test.component';
import { MaintenanceComponent } from './components/maintenance/maintenance-page.component';
import { ConfirmDirectDebitComponent } from './components/signup/payments/confirm-direct-debit/confirm-direct-debit.component';
import { AcceptBookingTransferComponent } from './components/accept-booking-transfer/accept-booking-transfer.component';

const routes: Routes = [
  { path: ':club-name/member/signup', component: SignUpComponent },
  { path: ':club-name/member/signup-success', component: SignUpSuccessComponent },
  { path: ':club-name/member/signup-success/:method', component: SignUpSuccessComponent },
  {
    path: ':club-name/member/signup/confirming-direct-debit',
    component: ConfirmDirectDebitComponent,
  },
  {
    path: ':club-name/member/signup/confirming-direct-debit/:siteId',
    component: ConfirmDirectDebitComponent,
  },
  { path: 'maintenance-page', component: MaintenanceComponent },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login/:token', component: LoginComponent },
  { path: 'reset-password-request', component: LoginComponent },
  { path: 'reset-password-request/:resetemail', component: LoginComponent },
  {
    path: 'homepage',
    component: HomepageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'setup-two-factor-authentication',
    component: TwoFactorAuthenticationSetupComponent,
    canActivate: [AuthGuard],
  },
  { path: 'reset-password/too-weak/:token', component: ResetPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'new-login/:token', component: ResetPasswordComponent },
  { path: 'setup-login/:token', component: SetupLoginComponent },
  { path: 'accept-booking-transfer/:token/:transfer', component: AcceptBookingTransferComponent },
  { path: 'decline-booking-transfer/:token/:transfer', component: AcceptBookingTransferComponent },
  {
    path: ':club-name/admin',
    loadChildren: () =>
      import('./sub-projects/club/src/app/club-admin.module').then((m) => m.ClubAdminModule),
  },
  {
    path: ':club-name/member',
    loadChildren: () =>
      import('./sub-projects/club-members/src/app/club-members.module').then(
        (m) => m.ClubMembersModule
      ),
  },
  {
    path: 'style-guide',
    component: StyleGuideComponent,
    canActivate: [NonProdGuard],
    canActivateChild: [NonProdGuard],
    children: [
      { path: 'forms', component: FormStylingComponent },
      { path: 'buttons', component: ButtonStylingComponent },
      { path: 'typography', component: FormStylingComponent },
    ],
  },
  {
    path: 'test-area',
    component: TestComponent,
    canActivate: [NonProdGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
