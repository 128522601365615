import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { AuthService } from '@sportinsight-services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {}

  /**
   * Checks the user can activate this route
   *
   * @param next - next guard (if any)
   * @param state - data passed into route (if any)
   *
   * @return boolean
   */
  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authenticated = this.authService.isAuthenticated();
    localStorage.setItem('baseUrl', '');

    if (authenticated.isAuthenticated && !localStorage.getItem('availableSites')) {
      localStorage.clear();
      this.router.navigateByUrl('login');
      return false;
    }

    if (
      authenticated.isAuthenticated &&
      (state.url.includes('homepage') || state.url.includes('setup-two-factor-authentication'))
    ) {
      // Authenticated user trying to access homepage
      return true;
    }

    if (authenticated.isAuthenticated && !localStorage.getItem('siteId')) {
      this.router.navigateByUrl('homepage');
      return false;
    }

    if (authenticated.isAuthenticated && state.url.includes('login')) {
      // Authenticated user trying to access login page
      this.router.navigateByUrl('homepage');
      return false;
    }

    if (state.url.includes('login')) {
      // Unauthenticated user trying to access login page
      localStorage.clear();
      return true;
    }

    // Unauthenticated user trying to access any other route
    if (authenticated.sessionTimeout) {
      localStorage.clear();
    }

    if (authenticated.isAuthenticated) {
      // Authenticated user trying to access any other route
      return true;
    }

    this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
