<div class="two-FA-container">
  <h1 class="two-FA-title">Two-factor authentication</h1>
  <p class="two-FA-desc">Enter the code from the two-factor app on your mobile device.</p>
  <div class="two-FA-code-input-container">
    <label for="twoFACodeInput" class="two-FA-code-input-label">
      Two-factor authentication code input
    </label>
    <input
      [formControl]="twoFACodeInputControl"
      class="two-FA-code-input"
      id="twoFACodeInput"
      type="text"
      placeholder="Two-factor authentication code..."
      autofocus
    />
    <span class="recovery-codes-prompt">
      <a
        *ngIf="!lostDevicePrompt"
        (click)="lostDevicePrompt = !lostDevicePrompt"
        class="recovery-codes-prompt-toggle clickable"
      >
        Lost device?
      </a>
      {{
        lostDevicePrompt
          ? "If you've lost your device, you may enter one of your recovery codes. This will also reset your two factor authentication."
          : null
      }}
    </span>
  </div>
  <button (click)="onSubmitTwoFACode()" class="btn verify-two-factor-btn">Submit</button>
</div>
