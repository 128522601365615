<ng-template #loginSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="container" *ngIf="!loading; else loginSpinner">
  <form *ngIf="form" [formGroup]="form">
    <fieldset>
      <div class="form-group">
        <!-- First Name -->
        <label>First name:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="firstName"
            placeholder="Your first name"
            [class.error]="displayErrors && form.get('firstName').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('firstName').invalid">
            A first name is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Last Name -->
        <label>Last name:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="lastName"
            placeholder="Your last name"
            [class.error]="displayErrors && form.get('lastName').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('lastName').invalid">
            A last name is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Email address -->
        <label>Email address:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="email"
            placeholder="Your email address"
            [class.error]="displayErrors && form.get('email').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('email').invalid">
            An email address is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Billing address line 1 -->
        <label>Billing address line 1:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="addressLine1"
            placeholder="Address line 1"
            [class.error]="displayErrors && form.get('addressLine1').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('addressLine1').invalid">
            An address is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Billing address line 2 -->
        <label>Billing address line 2:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="addressLine2"
            placeholder="Address line 2"
          />
          <div class="clear"></div>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Billing address town or city -->
        <label>Billing address town or city:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="city"
            placeholder="Town or city"
            [class.error]="displayErrors && form.get('city').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('city').invalid">
            A town or city is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Billing address postcode -->
        <label>Billing address postcode:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="postcode"
            placeholder="Postcode"
            [class.error]="displayErrors && form.get('postcode').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('postcode').invalid">
            A postcode is required
          </span>
        </div>
      </div>
      <div class="clear"></div>
    </fieldset>
  </form>
</div>
