import { finalize } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { User } from '@sportinsight/src/app/sub-projects/shared/definitions';
import {
  PaymentService,
  SignUpProduct,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupService, ProspectiveUserAddress } from '../../../services/signup.service';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnChanges {
  @Input() public prospectiveMemberId: number;
  @Input() public selectedMembership: number;
  @Output() public readonly paymentCompleteEmitter = new EventEmitter<boolean>();
  @Output() private readonly stageEmitter = new EventEmitter<number>();
  @Input() selectedMonthly: boolean;

  public paymentMethodsHidden: boolean = false;
  public paymentStage: string = 'choose-payment';
  public selectedPaymentMethod: string;
  public loading = false;
  public user;
  public userAddress: ProspectiveUserAddress;
  public userDetails;
  public siteId;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly signupService: SignupService,
    private readonly alertService: AlertService
  ) { }

  ngOnInit(): void {
    if (!localStorage.getItem('prevouslyCreatedUserId')) {
      this.stageEmitter.emit(1);
    }
    this.siteId = localStorage.getItem('signupSiteId');
    this.route.queryParams.subscribe((params) => {
      if (params?.pay === 'success') {
        this.paymentMethodsHidden = true;
        this.paymentCompleteEmitter.emit(true);
        this.paymentStage = 'payment-success';
      }
      if (params?.signup === 'confirming') {
        this.paymentStage = 'signup-confirming';
      }
    });

    this.getPreviouslyCreatedMember();
  }

  ngOnChanges(changes: SimpleChanges): void { }

  public hidePaymentMethod(val: boolean): void {
    this.paymentMethodsHidden = val;
  }

  // Get a previously created user (for when user has gone back in form, or returned to incomplete form)
  public getPreviouslyCreatedMember(): void {
    this.loading = true;
    const params = { userId: localStorage.getItem('prevouslyCreatedUserId'), siteId: this.siteId };

    this.signupService
      .getPreviouslyCreatedProspectiveUser(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.userAddress = response.data?.user_addresses[0];
          this.userDetails = response.data;
        },
        error: (error) => {
          this.stageEmitter.emit(1)
        },
      });
  }
}
