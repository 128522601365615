import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() public readonly title: string = 'Are you sure?';
  @Input() public readonly message: string;
  @Input() public readonly cancelOption: string = 'No';
  @Input() public readonly proceedOption: string = 'Yes';
  @Input() public readonly useCase: string;
  @Input() public readonly loading: boolean = false;
  @Input() public readonly confirmationDisplaySubmit: boolean = true;
  @Input() public readonly cancelDisplaySubmit: boolean = true;

  @Output() private readonly closeModalEmitter = new EventEmitter();
  @Output() private readonly confirmEmitter = new EventEmitter<string>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public onConfirm(): void {
    this.confirmEmitter.emit(this.useCase);
  }

  public closeModal(): void {
    this.closeModalEmitter.emit();
  }

  public processLinks(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      e.preventDefault();
      const link = element.getAttribute('href');
      if(link)this.router.navigate([link]);
    }
  }
}
