<div class="container">
  <!-- Membership indicators -->
  <img *ngIf="signupStage === 'membership-options'; else finished" class="1 stageIndicator clickable" (click)="onStageIndicatorClick(0)" src="assets/images/icons/icon_forward_circle_blue.svg" alt="Sport Insight" />
  <ng-template #finished>
    <img class="1 stageIndicator clickable" (click)="onStageIndicatorClick(0)" src="assets/images/icons/icon_forward_circle_filled.svg" alt="Sport Insight" />
  </ng-template>

  <div class="stageTitleWrapper">
    <h3 [ngClass]="{ selected: signupStage === 'membership-options'}">Your membership</h3>
  </div>

  <!-- Membership seperator -->
  <div [ngClass]="{ activeConnector: signupStage === 'about-you' || signupStage === 'payments'}" class="stageConnector"></div>

  <!-- About you indicators  -->
  <!-- Completed -->
  <img *ngIf="signupStage === 'payments'; else active" class="2 stageIndicator clickable" (click)="onStageIndicatorClick(1)" src="assets/images/icons/icon_forward_circle_filled.svg" alt="Sport Insight" />
  <ng-template #active>
    <!-- Active -->
    <img *ngIf="signupStage === 'about-you'; else inactive" class="2 stageIndicator clickable" (click)="onStageIndicatorClick(1)" src="assets/images/icons/icon_forward_circle_blue.svg" alt="Sport Insight" />

    <!-- Inactive -->
    <ng-template #inactive><img class="2 stageIndicator clickable" (click)="onStageIndicatorClick(1)" src="assets/images/icons/icon_forward_circle.svg" alt="Sport Insight" /></ng-template>
  </ng-template>

  <div class="stageTitleWrapper">
    <h3 [ngClass]="{ selected: signupStage === 'about-you'}">About you</h3>
  </div>

  <!-- About you seperator -->
  <div [ngClass]="{ activeConnector: signupStage === 'payments'}" class="stageConnector"></div>

  <!-- Payment indicator -->
  <!-- Active -->
  <img *ngIf="signupStage === 'payments'; else inactive" class="3 stageIndicator clickable" (click)="onStageIndicatorClick(2)" src="assets/images/icons/icon_forward_circle_blue.svg" alt="Sport Insight" />

  <!-- Inactive -->
  <ng-template #inactive><img class="3 stageIndicator clickable" (click)="onStageIndicatorClick(2)" src="assets/images/icons/icon_forward_circle.svg" alt="Sport Insight" /></ng-template>

  <div class="stageTitleWrapper">
    <h3 [ngClass]="{ selected: signupStage === 'payments'}">Payment</h3>
  </div>
</div>
