<div class="reset-password-prompt-container">
  <p>
    To reset your password please enter your email address in the box below and click the Reset
    Password button.
  </p>
  <form [formGroup]="resetPasswordForm">
    <fieldset>
      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          class="form-control"
          id="email-reset"
          formControlName="email"
          placeholder="Email"
          required
          [class.error]="displayError && resetPasswordForm.get('email').invalid"
        />
        <div class="clear"></div>
        <span class="error-msg on" *ngIf="displayError && resetPasswordForm.get('email').invalid">
          Please enter a valid e-mail address for "Email address"
        </span>
      </div>
      <div class="clear"></div>
      <button class="btn" id="btn_reset" (click)="sendPasswordResetEmail()">Reset Password</button>
    </fieldset>
  </form>
</div>
