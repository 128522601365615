import { finalize } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { User } from '@sportinsight/src/app/sub-projects/shared/definitions';
import {
  PaymentService,
  SignUpProduct,
} from '@sportinsight/src/app/sub-projects/shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingDetails, BankDetails } from '@sportinsight/src/app/services/signup.service';

@Component({
  selector: 'app-confirm-details',
  templateUrl: './confirm-details.component.html',
  styleUrls: ['./confirm-details.component.scss'],
})
export class ConfirmDetailsComponent implements OnInit, OnChanges {
  @Input() public siteId: number;
  @Input() public prospectiveMemberId: number;
  @Input() public selectedMembership: number;
  @Input() public bankDetails: BankDetails;
  @Input() public billingDetails: BillingDetails;
  @Output() private readonly setStageEmitter = new EventEmitter<boolean>();

  public formStage: number = 0;
  public loading: boolean = false;

  constructor(
    private readonly userService: UserService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  public setStage(val): void {
    this.setStageEmitter.emit(val);
  }
}
