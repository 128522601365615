<ng-template #loginSpinner>
  <div class="content-container">
    <img class="loader" src="/assets/images/icons/icon-loader.svg" alt="loader" />
    <span class="light-tag">Loading ...</span>
  </div>
</ng-template>

<div class="container" *ngIf="!loading; else loginSpinner">
  <form *ngIf="form" [formGroup]="form">
    <fieldset>
      <div class="form-group">
        <!-- First Name -->
        <label>Account holder name:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="accountName"
            placeholder="Account holder name"
            [class.error]="displayErrors && form.get('accountName').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('accountName').invalid">
            The account holder name is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Sort code -->
        <label>Sort code:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="sortCode"
            placeholder="Sort code"
            [class.error]="displayErrors && form.get('sortCode').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('sortCode').invalid">
            Your sort code is required
          </span>
        </div>
      </div>
      <div class="clear"></div>

      <div class="form-group">
        <!-- Account number -->
        <label>Account number:</label>
        <div class="input-container">
          <input
            type="text"
            class="form-control input"
            formControlName="accountNumber"
            placeholder="Your account number"
            [class.error]="displayErrors && form.get('accountNumber').invalid"
          />
          <div class="clear"></div>
          <span class="error-msg on" *ngIf="displayErrors && form.get('accountNumber').invalid">
            Your account number is required
          </span>
        </div>
      </div>
      <div class="clear"></div>
    </fieldset>
  </form>
</div>
