<div class="wrapper">
  <div class="headerContainer">
    <h2 *ngIf="formStage === 1">Personal details</h2>
    <h2 *ngIf="formStage === 2">Bank details</h2>
    <h2 *ngIf="formStage === 3">Please confirm your details</h2>
    <img
      src="/assets/images/icons/icon-arrow-right.svg"
      class="back-icon"
      (click)="previousStage()"
      *ngIf="formStage !== 1"
    />
  </div>

  <div class="content-container">
    <app-billing-details
      [displayErrors]="displayErrors"
      *ngIf="formStage === 1"
    ></app-billing-details>
    <app-bank-details [displayErrors]="displayErrors" *ngIf="formStage === 2"></app-bank-details>
    <app-confirm-details
      [billingDetails]="billingDetails"
      [bankDetails]="bankDetails"
      *ngIf="formStage === 3"
      (setStageEmitter)="setStage($event)"
    ></app-confirm-details>

    <div class="button-container">
      <button *ngIf="formStage !== 1" class="btn black back" (click)="previousStage()">Back</button>
      <button
        *ngIf="formStage !== 3"
        class="btn black next"
        (click)="nextStage()"
        [disabled]="nextDisabled"
        [ngClass]="{ disabled: nextDisabled }"
      >
        Next
      </button>
    </div>
  </div>
</div>
